import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as CommentActions from "./comment.actions";
import { IComment } from "./comment.model";

export const commentsFeatureKey = "comments";

export type State = EntityState<IComment>;
export const adapter: EntityAdapter<IComment> = createEntityAdapter<IComment>({
    selectId: (comment: IComment) => comment._id,
    sortComparer: (a: IComment, b: IComment) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const commentReducer = createReducer(
    initialState,
    on(CommentActions.addComment, (state, action) =>
        adapter.addOne(action.comment, state)
    ),
    on(CommentActions.upsertComment, (state, action) =>
        adapter.upsertOne(action.comment, state)
    ),
    on(CommentActions.addComments, (state, action) =>
        adapter.addMany(action.comments, state)
    ),
    on(CommentActions.upsertComments, (state, action) =>
        adapter.upsertMany(action.comments, state)
    ),
    on(CommentActions.updateComment, (state, action) =>
        adapter.updateOne(action.comment, state)
    ),
    on(CommentActions.updateComments, (state, action) =>
        adapter.updateMany(action.comments, state)
    ),
    on(CommentActions.deleteCommentSuccess, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(CommentActions.deleteComments, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(CommentActions.loadCommentsSuccess, (state, action) =>
        adapter.upsertMany(action.comments, state)
    ),
    on(CommentActions.clearComments, AuthActions.logout, state =>
        adapter.removeAll(state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return commentReducer(state, action);
}
