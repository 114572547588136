import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSerializer } from "@angular/router";
import { NotFoundComponent } from "./admin/pages/errors/not-found/not-found.component";
import { AuthGuard } from "./core/guards/auth-guard/auth.guard";
import { VerifyEmailGuard } from "./core/guards/auth-guard/verify-email.guard";
import { LoginGuard } from "./core/guards/login-guard/login.guard";

const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "/login" },
    {
        path: ":cardId",
        canActivate: [AuthGuard],
        loadChildren: () =>
            import("./card/card.module").then(c => c.CardModule),
        outlet: "card",
        data: { breadcrumb: "Card" },
    },
    {
        path: "boards",
        canActivate: [AuthGuard],
        loadChildren: () =>
            import("./main-board-shell/main-board-shell.module").then(
                m => m.MainBoardShellModule
            ),
        data: { breadcrumb: "Boards" },
    },
    {
        path: "admin",
        canLoad: [AuthGuard],
        loadChildren: () =>
            import("./admin/admin.module").then(m => m.AdminModule),
        data: { breadcrumb: "Admin" },
    },
    {
        path: "",
        canActivate: [LoginGuard],
        loadChildren: () =>
            import("./auth/auth.module").then(m => m.AuthModule),
        data: { breadcrumb: "Login" },
    },
    {
        path: "info",
        loadChildren: () =>
            import("./landing/landing.module").then(m => m.LandingModule),
    },
    {
        path: "**",
        canActivate: [VerifyEmailGuard],
        component: NotFoundComponent,
        data: { breadcrumb: "Error" },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: "enabled",
            relativeLinkResolution: "corrected",
            malformedUriErrorHandler: (
                _error: URIError,
                urlSerializer: UrlSerializer,
                _url: string
            ) => urlSerializer.parse("/page-not-found"),
        }),
    ],
    exports: [RouterModule],
    providers: [LoginGuard, AuthGuard],
})
export class AppRoutingModule {}
