import { createReducer, on, Action } from "@ngrx/store";
import { TourState, TourStep } from "./tour.model";
import * as TourActions from "./tour.actions";

export interface State {
    steps: TourStep[];
    currentStep: number;
    isActive: boolean;
}

const initialState: State = {
    currentStep: 0,
    isActive: false,
    steps: [],
};

const tourReducer = createReducer(
    initialState,
    on(TourActions.startTour, (state, { steps }) => ({
        ...state,
        currentStep: 1,
        isActive: true,
        steps,
    })),
    on(TourActions.nextStep, state => ({
        ...state,
        currentStep: state.currentStep + 1,
    })),
    on(TourActions.previousStep, state => ({
        ...state,
        currentStep: state.currentStep - 1,
    })),
    on(TourActions.endTour, state => ({
        ...state,
        isActive: false,
    }))
);

export function reducer(state: TourState | undefined, action: Action) {
    return tourReducer(state, action);
}

export const getIsActive = (state: State) => state.isActive;
export const getCurrentStep = (state: State) =>
    state.steps[state.currentStep - 1];
export const getSteps = (state: State) => state.steps;
export const getStepStatus = (state: State) =>
    `${state.currentStep}/${state.steps.length}`;
