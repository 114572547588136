import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as ChecklistActions from "./checklist.actions";
import { IChecklist as Checklist } from "./checklist.model";

export interface State extends EntityState<Checklist> {
    // additional entities state properties
    selectedChecklistId: null | string;
}

export const adapter: EntityAdapter<Checklist> = createEntityAdapter<Checklist>(
    {
        selectId: (checklist: Checklist) => checklist._id,
        sortComparer: false,
    }
);

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    selectedChecklistId: null,
});

const checklistReducer = createReducer(
    initialState,
    on(
        ChecklistActions.addChecklist,
        SockActions.addChecklist,
        (state, action) => adapter.addOne(action.checklist, state)
    ),
    on(ChecklistActions.upsertChecklist, (state, action) =>
        adapter.upsertOne(action.checklist, state)
    ),
    on(ChecklistActions.addChecklists, (state, action) =>
        adapter.addMany(action.checklists, state)
    ),
    on(ChecklistActions.upsertChecklists, (state, action) =>
        adapter.upsertMany(action.checklists, state)
    ),
    on(
        ChecklistActions.updateChecklist,
        SockActions.updateChecklist,
        (state, action) => adapter.updateOne(action.checklist, state)
    ),
    on(ChecklistActions.updateChecklists, (state, action) =>
        adapter.updateMany(action.checklists, state)
    ),
    on(
        ChecklistActions.deleteChecklist,
        SockActions.deleteChecklist,
        (state, action) => adapter.removeOne(action.id, state)
    ),
    on(ChecklistActions.deleteChecklists, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(ChecklistActions.loadChecklistsSuccess, (state, action) =>
        adapter.upsertMany(action.checklists, state)
    ),
    on(ChecklistActions.clearChecklists, state => adapter.removeAll(state)),
    on(ChecklistActions.selectChecklist, (state, { id }) => ({
        ...state,
        selectedChecklistId: id,
    })),
    on(ChecklistActions.deSelectChecklist, state => ({
        ...state,
        selectedChecklistId: null,
    })),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return checklistReducer(state, action);
}

export const getSelectedChecklist = (state: State) =>
    state.selectedChecklistId
        ? state.entities[state.selectedChecklistId]
        : undefined;
