import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IColumn as Column } from "./column.model";

export const loadColumns = createAction(
    "[Column/API] Load Columns",
    props<{ columns: Column[] }>()
);

export const getColumnsOfBoard = createAction(
    "[Column/API] Get Columns Of Board",
    props<{ boardId: string }>()
);

export const createColumn = createAction(
    "[Column/API] Create Column",
    props<{ column: Partial<Column> }>()
);

export const addColumn = createAction(
    "[Column/API] Add Column",
    props<{ column: Column }>()
);

export const upsertColumns = createAction(
    "[Column/API] Upsert Columns",
    props<{ columns: Column[] }>()
);

export const updateColumn = createAction(
    "[Column/API] Update Column",
    props<{ column: Update<Column> }>()
);

export const updateColumns = createAction(
    "[Column/API] Update Columns",
    props<{ columns: Update<Column>[] }>()
);

export const deleteColumn = createAction(
    "[Column/API] Delete Column",
    props<{ id: string }>()
);

export const clearColumns = createAction("[Column/API] Clear Columns");
