import { createAction, props } from "@ngrx/store";
import { TourStep } from "./tour.model";

export const startTour = createAction(
    "[Tour] Start Tour",
    props<{ steps: TourStep[] }>()
);
export const route = createAction("[Tour] Route");
export const endTour = createAction("[Tour] End Tour");
export const nextStep = createAction("[Tour] Next Step");
export const previousStep = createAction("[Tour] Previous Step");
