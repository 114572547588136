import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { INotification } from "./notification.model";

export const loadUnreadNotifications = createAction(
    "[Notification] Load Unread Notifications",
    props<{ page?: number; limit?: number }>()
);

export const loadUnreadNotificationsSuccess = createAction(
    "[Notification/API] Load Unread Notifications Success",
    props<{ notifications: INotification[]; total: number }>()
);
export const loadUnreadNotificationsFailure = createAction(
    "[Notification] Load Notifications Failure",
    props<{ error: any }>()
);

export const addNotification = createAction(
    "[Notification/API] Add Notification",
    props<{ notification: INotification }>()
);

export const upsertNotification = createAction(
    "[Notification/API] Upsert Notification",
    props<{ notification: INotification }>()
);

export const addNotifications = createAction(
    "[Notification/API] Add Notifications",
    props<{ notifications: INotification[] }>()
);

export const upsertNotifications = createAction(
    "[Notification/API] Upsert Notifications",
    props<{ notifications: INotification[] }>()
);

export const updateNotification = createAction(
    "[Notification/API] Update Notification",
    props<{ notification: Update<INotification> }>()
);

export const updateNotifications = createAction(
    "[Notification/API] Update Notifications",
    props<{ notifications: Update<INotification>[] }>()
);

export const deleteNotification = createAction(
    "[Notification/API] Delete Notification",
    props<{ id: string }>()
);

export const deleteNotifications = createAction(
    "[Notification/API] Delete Notifications",
    props<{ ids: string[] }>()
);

export const clearNotifications = createAction(
    "[Notification/API] Clear Notifications"
);

export const markAsRead = createAction(
    "[Notification] Updated Card Status",
    props<{ _id: string }>()
);

export const loadAllNotifications = createAction(
    "[Notification] Load All Notifications",
    props<{ page?: number; limit?: number }>()
);
export const loadAllNotificationsSuccess = createAction(
    "[Notification] Load All Notifications Success",
    props<{ notifications: INotification[]; total: number }>()
);
export const loadAllNotificationsFailure = createAction(
    "[Notification] Load All Notifications Failure",
    props<{ error: string }>()
);
export const markAllAsRead = createAction(
    "[Notification] Mark all as read",
    props<{ ids: string[] }>()
);
export const markAllAsUnread = createAction(
    "[Notification] Mark all as UnRead"
);
export const markAllAsUnreadSuccess = createAction(
    "[Notification] Mark all as Unread Success",
    props<{ notifications: INotification[]; total: number }>()
);
