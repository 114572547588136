import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable()
export class LogUpdateService {
    constructor(updates: SwUpdate) {
        console.log("log update service constructing");
        updates.available.subscribe(event => {
            console.log("current version is", event.current);
            console.log("available version is", event.available);
        });
        updates.activated.subscribe(event => {
            console.log("old version was", event.previous);
            console.log("new version is", event.current);
        });
    }
}
