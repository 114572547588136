import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, map, concatMap, tap } from "rxjs/operators";
import * as DepActions from "./department.actions";
import { IDepartment } from "./department.model";
import { SocketService } from "src/app/core/services/socket.service";

@Injectable()
export class DepartmentEffects {
    LoadDepartments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepActions.loadDepartments),
            switchMap(() =>
                this.http
                    .get<IDepartment[]>(`${environment.api}/dep/all`)
                    .pipe(
                        map(departments =>
                            DepActions.loadDepartmentsSuccess({ departments })
                        )
                    )
            )
        )
    );

    onUpdateDepartment$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DepActions.updateDepartment),
                concatMap(({ department }) =>
                    this.http
                        .put(`${environment.api}/dep/${department.id}`, {
                            ...department.changes,
                            _id: department.id,
                        })
                        .pipe(
                            tap(() =>
                                this.socket.onDepartmentUpdatedSuccess({
                                    department,
                                })
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private socket: SocketService
    ) {}
}
