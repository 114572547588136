import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "phone" })
export class FormatPhoneNumberPipe implements PipeTransform {
    transform(value: string) {
        value = value.split("-").join("");
        let country;
        let city;
        let number;
        const regExp = new RegExp(/\D/g);
        const tel = value.replace(regExp, "");
        switch (value.length) {
            case 10:
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11:
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12:
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }
        if (country === 1) {
            country = "";
        }
        number = `${number.slice(0, 3)}-${number.slice(3)}`;
        const newValue = `${country} (${city}) ${number}`.trim();
        return newValue;
    }
}
