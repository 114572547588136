import { LayoutModule } from "@angular/cdk/layout";
import { OverlayContainer } from "@angular/cdk/overlay";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { JwtModule } from "@auth0/angular-jwt";
import { EffectsModule } from "@ngrx/effects";
import {
    NavigationActionTiming,
    RouterStateSerializer,
    StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { ToastrModule } from "ngx-toastr";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule } from "./app-routing.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { CustomOverlayContainer } from "./admin/theme/utils/custom-overlay-container";
import { AppComponent } from "./app.component";
import { AuthEffects } from "./auth/store/auth.effects";
import { TodoEffects } from "src/app/core/store/todo/todo.effects";
import { UserEffects } from "src/app/core/store/user/user.effects";
import { CardEffects } from "src/app/core/store/card/card.effects";
import { BoardEffects } from "src/app/core/store/board/board.effects";
import { InsurerEffects } from "src/app/core/store/insurer/insurer.effects";
import { VehicleEffects } from "src/app/core/store/vehicle/vehicle.effects";
import { CustomerEffects } from "src/app/core/store/customer/customer.effects";
import { ChecklistEffects } from "src/app/core/store/checklist/checklist.effects";
import { ActionEffects } from "src/app/core/services/action-services/action.effects";
import { DepartmentEffects } from "src/app/core/store/department/department.effects";
import { CardTemplateEffects } from "src/app/core/store/cardTemplate/card-template.effect";
import { NotificationEffects } from "src/app/core/store/notification/notification.effects";
import { VerifyEmailGuard } from "./core/guards/auth-guard/verify-email.guard";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { EnsureHttpsInterceptor } from "./core/interceptors/ensure-https.interceptor";
import { CompanyValidator } from "./core/services/admin-services/company/company.validator";
import { DashboardService } from "./core/services/dashboard-services/dashboard.service";
import { LogUpdateService } from "./core/services/misc-services/log-update.service";
import { TodoService } from "./core/services/misc-services/todo.service";
import { NotificationService } from "./core/services/notification-services/notification.service";
import { ThemeService } from "./core/services/theme-services/theme.service";
import { ToastrEventService } from "./core/services/toastr-services/toastr-event.service";
import { UserService } from "./core/services/user-services/user.service";
import { SocketService } from "src/app/core/services/socket.service";
import { RouterEffects } from "./core/store/router/router.effects";
import { UniversalModule } from "./core/universal.module";
import { metaReducers, ROOT_REDUCERS } from "./reducers";
import { RsRouterSerializer } from "./router.serializer";
import { ReactiveFormsModule } from "@angular/forms";
import { SupplementEffects } from "./core/store/supplement/supplement.effects";
import { SocketIoModule } from "ngx-socket-io";
import { RprSageToastrComponent } from "./core/shared/notifications/rpr-sage-toastr/rpr-sage-toastr.component";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    declarations: [AppComponent, RprSageToastrComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        UniversalModule,
        MatButtonModule,
        ReactiveFormsModule,
        LayoutModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: function tokenGetter() {
                    return localStorage.getItem("AuthToken");
                },
                allowedDomains: [`${environment.apiProxy}`],
                disallowedRoutes: [`${environment.api}/authenticate`],
            },
        }),
        ToastrModule.forRoot({
            timeOut: environment.production ? 3900 : 10000,
            positionClass: "toast-bottom-right",
            closeButton: true,

            preventDuplicates: true,
            progressBar: true,
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            registrationStrategy: "registerWithDelay:0",
            enabled: environment.production,
        }),
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionWithinNgZone: true,
                strictActionTypeUniqueness: true,
            },
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: RsRouterSerializer,
            navigationActionTiming: NavigationActionTiming.PreActivation,
        }),
        SocketIoModule,
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  name: "Repairsage",
                  maxAge: 25,
              })
            : [],
        EffectsModule.forRoot([
            RouterEffects,
            AuthEffects,
            CardEffects,
            UserEffects,
            TodoEffects,
            BoardEffects,
            ActionEffects,
            VehicleEffects,
            InsurerEffects,
            CustomerEffects,
            ChecklistEffects,
            DepartmentEffects,
            CardTemplateEffects,
            NotificationEffects,
            SupplementEffects,
        ]),
        AppRoutingModule,
    ],
    providers: [
        {
            provide: RouterStateSerializer,
            useClass: RsRouterSerializer,
        },
        {
            provide: OverlayContainer,
            useClass: CustomOverlayContainer,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnsureHttpsInterceptor,
            multi: true,
        },
        UserService,
        TodoService,
        ThemeService,
        CompanyValidator,
        LogUpdateService,
        DashboardService,
        ToastrEventService,
        VerifyEmailGuard,
        NotificationService,
        SocketService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline" },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
