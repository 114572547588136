import { Pipe, PipeTransform } from "@angular/core";
import { Utils } from "src/app/core/utils/helper-functions";

@Pipe({ name: "targetDate" })
export class TargetDatePipe implements PipeTransform {
    transform(value: Date | undefined, color = false): string {
        if (!value) {
            if (color) return "primary-text";
            return "None";
        }
        const diff = Utils.daysUntil(value);
        if (diff === 0) {
            if (color) return "pulse-warn-text";
            return "Today";
        } else if (diff === 1) {
            if (color) return "warn-text";
            return "1 day";
        } else if (diff > 1) {
            if (color) {
                if (diff > 10) return "green-text";
                if (diff > 5) return "accent-text";
                return "warn-text";
            }
            return `${diff} days`;
        } else if (diff < -1) {
            if (color) return "warn-text";
            return `${Math.abs(diff)} days overdue`;
        } else if (diff === -1) {
            if (color) return "warn-text";
            return "1 day ago";
        }
        return "";
    }
}
