import { Injectable } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import faker from "faker";
import { Colors } from "../../models/types";
import { IUser } from "../../store/user/user.model";
import { UserValidator } from "../../validations/existing-email/existing-email-validator";
import { MustMatch } from "../../validations/password-validator/password.validator.service";

@Injectable()
export class UserFormService {
    colors = Colors;

    constructor(
        private fb: FormBuilder,
        private validationService: UserValidator
    ) {}

    private defaultAvatar(firstName?: string, lastName?: string) {
        const fName = firstName || "F";
        const lName = lastName || "L";
        const letters = "0123456789ABCDEF";
        let bgc = "";

        for (let i = 0; i < 6; i++)
            bgc += letters[Math.floor(Math.random() * 16)];

        return `https://ui-avatars.com/api/?name=${fName}+${lName}&background=${bgc}&color=fff`;
    }

    private get randomColor() {
        return `${
            this.colors[Math.floor(Math.random() * this.colors.length)].value
        }`;
    }

    DevUserForm(): FormGroup {
        const fName = faker.name.firstName();
        const lName = faker.name.lastName();
        return this.fb.group({
            firstName: [fName, Validators.required],
            lastName: [lName, Validators.required],
            roles: [["Admin"], Validators.required],
            email: new FormControl(faker.internet.email(fName, lName), {
                validators: [Validators.required, Validators.email],
                asyncValidators: [
                    this.validationService.validate.bind(
                        this.validationService
                    ),
                ],
                updateOn: "blur",
            }),
            phone: [
                faker.phone.phoneNumber("##########"),
                [
                    Validators.required,
                    Validators.pattern(
                        /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g
                    ),
                ],
            ],
            idBoards: [[]],
            starred: [[]],
            password: ["test", Validators.required],
            confirm: ["test", Validators.required],
            prefTheme: ["green-dark"],
            isActive: [true],
            jobTitle: ["CEO"],

            bgColor: [this.randomColor],
            avatar: [this.defaultAvatar(fName, lName)],
            birthday: [faker.date.past(27)],
            prefs: this.fb.group({
                email: [true],
                push: [true],
                sms: [true],
            }),
        });
    }

    UserForm2(user?: IUser) {
        const form = this.fb.group(
            {
                ...user,
                _id: [user ? user._id : { value: null, disabled: true }],
                firstName: [user ? user.firstName : "", Validators.required],
                lastName: [user ? user.lastName : "", Validators.required],
                roles: [user ? user.roles : ["Admin"], Validators.required],
                email: new FormControl(user?.email, {
                    validators: [Validators.required, Validators.email],
                    asyncValidators: [
                        this.validationService.validate.bind(
                            this.validationService
                        ),
                    ],
                    updateOn: "blur",
                }),
                phone: [
                    user ? user.phone : "",
                    [
                        Validators.required,
                        Validators.pattern(
                            /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g
                        ),
                    ],
                ],
                idBoards: [user ? user.idBoards : []],
                starred: [user && user.starred ? user.starred : []],
                password: [
                    { value: "", disabled: !!user },
                    Validators.required,
                ],
                confirm: [{ value: "", disabled: !!user }, Validators.required],
                prefTheme: [user ? user.prefTheme : "green-dark"],
                isActive: [true],
                jobTitle: [user ? user.jobTitle : ""],

                bgColor: [user ? user.bgColor : this.randomColor],
                avatar: [user ? user.avatar : this.defaultAvatar()],
                birthday: [user ? user.birthday : null],
                prefs: this.fb.group({
                    email: [user?.prefs.email || true],
                    push: [user?.prefs.push || true],
                    sms: [user?.prefs.sms || true],
                }),
            },
            {
                validators: MustMatch("password", "confirm"),
            }
        );
        return form;
    }

    DetectChanges(userForm: FormGroup, user: IUser) {
        return Object.keys(userForm.controls)
            .filter(key => {
                if (key === "prefs") {
                    return (
                        userForm.get(key)?.value.email !== user.prefs.email ||
                        userForm.get(key)?.value.push !== user.prefs.push ||
                        userForm.get(key)?.value.sms !== user.prefs.sms
                    );
                }
                return (
                    key in user &&
                    key !== "password" &&
                    userForm.get(key)?.value !== user[key as keyof IUser]
                );
            })
            .reduce(
                (prev, key) => ({ ...prev, [key]: userForm.get(key)?.value }),
                {}
            );
    }

    EditCurrentUserForm(user: IUser): FormGroup {
        return this.fb.group(
            {
                _id: [user._id],
                firstName: [user.firstName, Validators.required],
                lastName: [user.lastName, Validators.required],
                idBoards: [user.idBoards],
                starred: [user && user.starred ? user.starred : []],
                password: [{ value: "", disabled: true }, Validators.required],
                confirm: [{ value: "", disabled: true }, Validators.required],
                prefTheme: [user.prefTheme],
                roles: [user.roles],
                isActive: [true],
                jobTitle: [user.jobTitle],
                bgColor: [user.bgColor],
                avatar: [user.avatar],
                birthday: [user.birthday],
                email: new FormControl(user.email, {
                    validators: [Validators.required, Validators.email],
                    asyncValidators: [
                        this.validationService.validate.bind(
                            this.validationService
                        ),
                    ],
                    updateOn: "blur",
                }),
                phone: [
                    user.phone,
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(
                            /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g
                        ),
                    ]),
                ],
                prefs: this.fb.group({
                    email: [user.prefs.email],
                    push: [user.prefs.push],
                    sms: [user.prefs.sms],
                }),
            },
            { validators: MustMatch("password", "confirm") }
        );
    }
}
