import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Toast, ToastrTypes } from "../../models/toast";

@Injectable({ providedIn: "root" })
export class ToastrEventService {
    private toast$: Subject<Toast> = new Subject();

    public get Toasts(): Observable<Toast> {
        return this.toast$.asObservable();
    }

    constructor() {}

    toast(
        message: string,
        title: string = "Success",
        type: "info" | "warning" | "success" | "error" = "info"
    ) {
        this.toast$.next(new Toast(message, title, type));
    }

    onFileUploadStarted(): void {
        this.toast$.next(
            new Toast("File upload started", "Upload Started", "info")
        );
    }

    onSocketToast(msg: string, type: ToastrTypes) {
        this.toast$.next(new Toast(msg, "", type));
    }

    onFileUploadFinished(): void {
        this.toast$.next(
            new Toast("File upload Finished", "Success", "success")
        );
    }

    onFileUploadError(err: Error): void {
        this.toast$.next(
            new Toast(`File upload error\n${err.message}`, "Error", "error")
        );
    }

    onSupplementSubmitted(): void {
        this.toast$.next(
            new Toast(
                "Supplement successfully submitted.",
                "Success",
                "success"
            )
        );
    }

    onProcessedSupplement(): void {
        this.toast$.next(
            new Toast(
                "Supplement successfully processed.",
                "Processed",
                "success"
            )
        );
    }

    onCardCreateSuccess(): void {
        this.toast$.next(
            new Toast("Card successfully created", "Created", "success")
        );
    }

    onCardArchived(): void {
        this.toast$.next(
            new Toast("Card successfully archived", "Archived", "info")
        );
    }

    onUpdateSuccess(): void {
        this.toast$.next(new Toast("Update successful", "Update", "success"));
    }

    onError(errorMessage: string) {
        this.toast$.next(new Toast(errorMessage, "Error", "error"));
    }
}
