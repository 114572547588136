import { Component, HostListener, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-pdf-dialog",
    templateUrl: "./pdf-dialog.component.html",
    styleUrls: ["./pdf-dialog.component.scss"],
})
export class PDFDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<PDFDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {}

    @HostListener("keydown.escape") e() {
        this.dialogRef.close();
    }
}
