import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class DashboardService {
    constructor(private http: HttpClient) {}

    sendFeedback(data: { type: string; body: string }, cb: () => void) {
        this.http
            .post(`${environment.api}/feedback`, data)
            .subscribe(() => cb());
    }
}
