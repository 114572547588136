import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PipesModule } from "./pipes.module";
import { RprSageCommonModule } from "./material/RprSageCommon.module";
import { ErrorComponent } from "../admin/pages/errors/error/error.component";
import { BreadcrumbComponent } from "../admin/shared/breadcrumb/breadcrumb.component";
import { NotFoundComponent } from "../admin/pages/errors/not-found/not-found.component";
import { UserPreferencesComponent } from "./shared/user-preferences/user-preferences.component";
import { ContentHeaderComponent } from "../admin/shared/content-header/content-header.component";
import { WarningDialogComponent } from "../universal-dialogs/warning-dialog/warning-dialog.component";

@NgModule({
    imports: [RouterModule, RprSageCommonModule, PipesModule],
    declarations: [
        ErrorComponent,
        NotFoundComponent,
        BreadcrumbComponent,
        WarningDialogComponent,
        ContentHeaderComponent,
        UserPreferencesComponent,
    ],
    exports: [
        ErrorComponent,
        NotFoundComponent,
        BreadcrumbComponent,
        WarningDialogComponent,
        ContentHeaderComponent,
        UserPreferencesComponent,
    ],
    entryComponents: [WarningDialogComponent, UserPreferencesComponent],
})
export class UniversalModule {}
