import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IChecklist as Checklist } from "./checklist.model";

export const loadChecklists = createAction("[Checklist/API] Load Checklists");
export const loadChecklistsSuccess = createAction(
    "[Checklist/API] Load Checklists Success",
    props<{ checklists: Checklist[] }>()
);

export const addChecklist = createAction(
    "[Checklist/API] Add Checklist",
    props<{ checklist: Checklist }>()
);

export const upsertChecklist = createAction(
    "[Checklist/API] Upsert Checklist",
    props<{ checklist: Checklist }>()
);

export const addChecklists = createAction(
    "[Checklist/API] Add Checklists",
    props<{ checklists: Checklist[] }>()
);

export const upsertChecklists = createAction(
    "[Checklist/API] Upsert Checklists",
    props<{ checklists: Checklist[] }>()
);

export const updateChecklist = createAction(
    "[Checklist/API] Update Checklist",
    props<{ checklist: Update<Checklist> }>()
);

export const updateChecklists = createAction(
    "[Checklist/API] Update Checklists",
    props<{ checklists: Update<Checklist>[] }>()
);

export const deleteChecklist = createAction(
    "[Checklist/API] Delete Checklist",
    props<{ id: string }>()
);

export const deleteChecklists = createAction(
    "[Checklist/API] Delete Checklists",
    props<{ ids: string[] }>()
);

export const clearChecklists = createAction("[Checklist/API] Clear Checklists");
export const selectChecklist = createAction(
    "[Checklist] Select Checklist",
    props<{ id: string }>()
);
export const deSelectChecklist = createAction("[Checklist] DeSelect Checklist");
export const createChecklist = createAction(
    "[Checklist/API] Create Checklist",
    props<{ payload: Partial<Checklist> }>()
);
