import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatMap, exhaustMap, map, switchMap } from "rxjs/operators";
import { WarningDialogComponent } from "src/app/universal-dialogs/warning-dialog/warning-dialog.component";
import { environment } from "src/environments/environment";
import * as InsActions from "./insurer.actions";
import { Insurer } from "./insurer.model";

@Injectable()
export class InsurerEffects {
    LoadInsurers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InsActions.loadInsurers),
            switchMap(() =>
                this.http
                    .get<Insurer[]>(`${environment.api}/ins/all`)
                    .pipe(
                        map(insurers =>
                            InsActions.loadInsurersSuccess({ insurers })
                        )
                    )
            )
        )
    );

    onUpdateInsurer$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(InsActions.updateInsurer),
                concatMap(({ insurer }) =>
                    this.http
                        .put(`${environment.api}/ins/update`, insurer)
                        .pipe()
                )
            ),
        { dispatch: false }
    );

    onCreateInsurer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InsActions.createNewInsurer),
            concatMap(action =>
                this.http
                    .post<Insurer>(`${environment.api}/ins/new`, action.ins)
                    .pipe(
                        map(newIns =>
                            InsActions.addInsurer({ insurer: newIns })
                        )
                    )
            )
        )
    );

    onDeleteInsurerConfirmation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InsActions.confirmDeleteInsurer),
            map(action => action.id),
            exhaustMap(id => {
                const buttonText = "No";
                const optionalButtonText = "Yes";
                const message = "Are you sure you want to delete this insurer?";
                const dialogRef = this.dialog.open(WarningDialogComponent, {
                    data: { buttonText, message, optionalButtonText },
                });
                return dialogRef
                    .afterClosed()
                    .pipe(map(result => (result ? id : result)));
            }),
            map(id =>
                id
                    ? InsActions.deleteInsurer({ id })
                    : InsActions.confirmDeleteInsurerDismiss()
            )
        )
    );

    onDeleteInsurer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InsActions.deleteInsurer),
            map(action => action.id),
            concatMap(id =>
                this.http.delete(`${environment.api}/ins/${id}`).pipe(
                    map(() => {
                        this.dialog.closeAll();
                        return InsActions.deleteInsurerSuccess({ id });
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private dialog: MatDialog
    ) {}
}
