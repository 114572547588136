import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NavigationExtras, Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import {
    map,
    tap,
    switchMap,
    concatMap,
    exhaustMap,
    catchError,
    withLatestFrom,
} from "rxjs/operators";
import { EMPTY, Observable, of } from "rxjs";

import { Update } from "@ngrx/entity";
import { Action, select, Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { environment } from "src/environments/environment";
import { SocketService } from "src/app/core/services/socket.service";
import { ActionEffects } from "src/app/core/services/action-services/action.effects";
import { PDFDialogComponent } from "src/app/dialogs/pdf-dialog/pdf-dialog.component";

import * as fromRoot from "src/app/reducers";
import * as fromCard from ".";
import * as fromUser from "../user";
import * as fromColumn from "../column";
import * as fromChecklists from "../checklist";

import { ToastrTypes } from "../../models/toast";

import type { ICard } from "./card.model";
import type { IUser } from "../user/user.model";
import type { ICompFile } from "../files/compFile";
import type { IColumn } from "../column/column.model";
import type { IAction } from "../action/action.model";
import type { IComment } from "../comment/comment.model";
import type { IVehicle } from "../vehicle/vehicle.model";
import type { ICustomer } from "../customer/customer.types";
import type { ISupplement } from "../supplement/supplement.model";

import * as CardActions from "./card.actions";
import * as UserActions from "../user/user.actions";
import * as ActionActions from "../action/action.actions";
import * as ColumnActions from "../column/column.actions";
import * as FileActions from "../files/comp-file.actions";
import * as LayoutActions from "../layout/layout.actions";
import * as RouterActions from "../router/router.actions";
import * as CommentActions from "../comment/comment.actions";
import * as VehicleActions from "../vehicle/vehicle.actions";
import * as CustomerActions from "../customer/customer.actions";
import * as SupplementActions from "../supplement/supplement.actions";
import { ImportDialogComponent } from "src/app/admin/pages/ems/import-dialog/import-dialog.component";

@Injectable()
export class CardEffects {
    GetCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.getFullCard),
            switchMap(({ cardId }) =>
                this.http
                    .get<{
                        card: ICard;
                        columns: IColumn[];
                        actions: IAction[];
                        customer: ICustomer;
                        comments: IComment[];
                        supplements: ISupplement[];
                        attachments: ICompFile[];
                        users: IUser[];
                    }>(`${environment.api}/cards/${cardId}`)
                    .pipe(
                        concatMap(
                            ({
                                customer,
                                actions,
                                comments,
                                attachments,
                                card,
                                supplements,
                                columns,
                                users,
                            }) => [
                                SupplementActions.upsertSupplements({
                                    supplements,
                                }),
                                ColumnActions.upsertColumns({ columns }),
                                CustomerActions.upsertCustomer({ customer }),
                                FileActions.loadCompFilesSuccess({
                                    compFiles: attachments,
                                }),
                                CommentActions.loadCommentsSuccess({
                                    comments,
                                }),
                                ActionActions.loadActionsSuccess({ actions }),
                                CardActions.upsertCard({ card }),
                                UserActions.upsertUsers({ users }),
                                CardActions.loadComplete(),
                            ]
                        ),
                        catchError(() =>
                            of(
                                RouterActions.Go({
                                    payload: {
                                        path: [
                                            "../",
                                            { outlets: { card: null } },
                                        ],
                                    },
                                })
                            )
                        )
                    )
            )
        )
    );

    DeselectFullCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.deselectFullCard),
            concatMap(() => [
                FileActions.clearCompFiles(),
                ActionActions.clearActions(),
                CommentActions.clearComments(),
            ])
        )
    );

    filesUploadStart$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.uploadFilesStart),
            exhaustMap(({ payload }) => {
                const form = new FormData();
                form.set("category", payload.category);
                payload.files.forEach(file =>
                    form.append("files", file, file.name)
                );
                return this.http
                    .post<{ files: ICompFile[] }>(
                        `${environment.api}/s3/uploads/${payload.cardId}`,
                        form
                    )
                    .pipe(
                        map(({ files }) => {
                            this.socket.onUploadFilesSuccess(files);
                            return CardActions.uploadFilesSuccess({ files });
                        }),
                        catchError(error =>
                            of(CardActions.uploadFilesFailed({ error }))
                        )
                    );
            })
        )
    );

    UpdateCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.updateCardEntity),
            concatMap(({ card }) =>
                this.http
                    .patch(`${environment.api}/cards/${card.id}`, card.changes)
                    .pipe(
                        tap(() => this.socket.onCardUpdate({ card })),
                        map(() =>
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Update successful",
                                    title: "",
                                    type: "success",
                                },
                            })
                        )
                    )
            )
        )
    );

    onInitialHours$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.addInitialCardHours),
            map(action => {
                return action.card;
            }),
            concatMap(data => {
                if (data) {
                    return this.http
                        .put<ICard>(
                            `${environment.api}/cards/updateHours/${data.id}`,
                            data
                        )
                        .pipe(
                            concatMap(() => {
                                this.socket.onCardUpdate({
                                    card: data,
                                });
                                return [
                                    CardActions.updateCardWithoutAPI({
                                        card: data,
                                    }),
                                    LayoutActions.showToastr({
                                        payload: {
                                            body: "Successfully added initial hours",
                                            title: "",
                                            type: "success",
                                        },
                                    }),
                                ];
                            })
                        );
                } else {
                    return EMPTY;
                }
            })
        )
    );

    OnUpdateFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FileActions.updateCompFile),
            concatMap(({ compFile }) =>
                this.http.put(`${environment.api}/files`, compFile).pipe(
                    map(() =>
                        LayoutActions.showToastr({
                            payload: {
                                body: "File Updated Successfully",
                                title: "",
                                type: ToastrTypes.SUCCESS,
                            },
                        })
                    ),
                    catchError(error =>
                        of(
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Failed to update file: " + error,
                                    title: "",
                                    type: ToastrTypes.ERROR,
                                },
                            })
                        )
                    )
                )
            )
        )
    );

    customerUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CustomerActions.updateCustomer),
                concatMap(({ customer }) =>
                    this.http
                        .patch(`${environment.api}/cust/${customer.id}`, {
                            ...customer.changes,
                        })
                        .pipe(
                            tap(() =>
                                this.socket.onCustomerUpdatedSuccess({
                                    customer,
                                })
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    createCard$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.createCard),
            withLatestFrom(
                this.store.select(fromChecklists.selectChecklistEntities),
                this.store.select(fromUser.selectLoggedInUser)
            ),
            map(([{ payload }, checklists, user]) => {
                if (checklists) {
                    return {
                        card: {
                            ...payload.card,
                            checklists: payload.checklists.map(c => ({
                                title: checklists[c]?.title,
                                fields: checklists[c]?.fields.map(
                                    ({ _id, ...field }) => field
                                ),
                            })),
                        },
                        customer: {
                            ...payload.customer,
                            idCompany: payload.card.idCompany,
                        },
                        vehicle: {
                            ...payload.vehicle,
                            idCompany: payload.card.idCompany,
                        },
                        user,
                    };
                } else {
                    return EMPTY;
                }
            }),
            concatMap(data =>
                this.http
                    .post<{
                        card: ICard;
                        customer: ICustomer;
                        vehicle: IVehicle;
                    }>(`${environment.api}/cards/new`, data)
                    .pipe(
                        concatMap(newStuff => {
                            this.socket.createCard(newStuff);
                            const extras: NavigationExtras = {
                                queryParams: {
                                    cardId: newStuff.card._id,
                                },
                            };
                            this.dialog.closeAll();
                            return [
                                CardActions.addCard({ card: newStuff.card }),
                                CustomerActions.addCustomer({
                                    customer: newStuff.customer,
                                }),
                                VehicleActions.addVehicle({
                                    vehicle: newStuff.vehicle,
                                }),
                                RouterActions.Go({
                                    payload: {
                                        path: [
                                            {
                                                outlets: {
                                                    card: [newStuff.card.ro],
                                                },
                                            },
                                        ],
                                        extras,
                                    },
                                }),
                            ];
                        }),
                        catchError(err => [
                            LayoutActions.showToastr({
                                payload: {
                                    body: err.toString(),
                                    title: "",
                                    type: "error",
                                },
                            }),
                            CardActions.updateCardStatus({ status: "idle" }),
                        ])
                    )
            )
        )
    );

    onNavigateToCard$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CardActions.navigateToCard),
                tap(({ payload }) => {
                    const navExtras: NavigationExtras = {
                        queryParams: {
                            cardId: payload.cardId,
                        },
                    };
                    this.router.navigate(
                        [{ outlets: { card: [payload.cardRo] } }],
                        navExtras
                    );
                })
            ),
        { dispatch: false }
    );

    newComment$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.createComment),
            concatMap(({ comment }) =>
                this.http
                    .post<IComment>(
                        `${environment.api}/cards/cmnt/new`,
                        comment
                    )
                    .pipe(
                        tap(result => this.socket.onComment(result)),
                        map(result =>
                            CommentActions.addComment({ comment: result })
                        )
                    )
            )
        )
    );

    uploadWorkOrder$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.uploadWorkOderStart),
            concatMap(({ payload }) => {
                const formData = new FormData();
                formData.append("file", payload.file);
                formData.set("category", "WORK_ORDER");
                return this.http
                    .put<ICompFile>(
                        `${environment.api}/s3/upload/${payload.cardId}/wo`,
                        formData
                    )
                    .pipe(
                        tap(newFile => {
                            this.actionService.onUpdateWorkOrder(
                                payload.cardId
                            );
                            this.socket.onFileUploadedSuccess(newFile);
                            this.socket.onCardUpdate({
                                card: {
                                    changes: {
                                        workOrder: newFile.url,
                                    },
                                    id: payload.cardId,
                                },
                            });
                        }),
                        switchMap(compFile => [
                            FileActions.addCompFile({ compFile }),
                            CardActions.updateCardEntity({
                                card: {
                                    changes: { workOrder: compFile.url },
                                    id: payload.cardId,
                                },
                            }),
                        ])
                    );
            })
        )
    );

    deleteComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.deleteComment),
            switchMap(({ id }) =>
                this.http
                    .delete(`${environment.api}/cards/cmnt/rmv/${id}`)
                    .pipe(
                        map(() => CommentActions.deleteCommentSuccess({ id }))
                    )
            )
        )
    );

    addSupplement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.createSupplement),
            concatMap(({ payload }) => {
                const formData = new FormData();
                formData.set("serviceWriter", payload.serviceWriterId);
                formData.set("file", payload.image);
                formData.set("idCard", payload.cardId);
                return this.http
                    .post<ISupplement>(
                        `${environment.api}/supplements/new`,
                        formData
                    )
                    .pipe(
                        tap(newSupp => {
                            this.socket.onSupplementCreatedSuccess(newSupp);
                        }),
                        concatMap(result => [
                            CardActions.createSupplementSuccess({
                                supplement: result,
                            }),
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Supplement submitted",
                                    title: "",
                                    type: "info",
                                },
                            }),
                        ]),
                        catchError(err =>
                            of(
                                CardActions.createSupplementFailed({
                                    error: err,
                                })
                            )
                        )
                    );
            })
        )
    );

    ArchiveCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.archiveCard),
            withLatestFrom(
                this.store.pipe(select(fromColumn.selectColumns)),
                this.store.pipe(select(fromCard.selectSelectedCard))
            ),
            concatMap(([data, columns, card]) =>
                this.http
                    .put(`${environment.api}/cards/archive`, {
                        cardId: data.cardId,
                    })
                    .pipe(
                        concatMap(() => {
                            if (!card) return [];
                            const col = columns.find(c =>
                                c.cards.includes(card._id)
                            );
                            if (col) {
                                const changes: Update<IColumn> = {
                                    changes: {
                                        cards: col.cards.filter(
                                            c => c !== data.cardId
                                        ),
                                    },
                                    id: col._id,
                                };
                                return [
                                    CardActions.archiveCardSuccess({ ...data }),
                                    ColumnActions.updateColumn({
                                        column: changes,
                                    }),
                                ];
                            } else {
                                return [];
                            }
                        }),
                        catchError(err =>
                            of(CardActions.archiveCardFailed({ error: err }))
                        )
                    )
            )
        )
    );

    OpenUnArchiveDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.unArchiveCardDialog),
            concatMap(({ cardId }) =>
                this.dialog
                    .open(ImportDialogComponent)
                    .afterClosed()
                    .pipe(
                        map((values?: { columnId: string; boardId: string }) =>
                            values
                                ? CardActions.unArchiveCard({
                                      boardId: values.boardId,
                                      columnId: values.columnId,
                                      cardId,
                                  })
                                : CardActions.unArchiveCardDismiss()
                        )
                    )
            )
        )
    );
    UnArchive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.unArchiveCard),
            concatMap(({ cardId, boardId, columnId }) =>
                this.http
                    .put<{ card: ICard; column: IColumn }>(
                        `${environment.api}/cards/unarchive/${cardId}`,
                        {
                            boardId,
                            columnId,
                        }
                    )
                    .pipe(
                        concatMap(({ card, column }) => {
                            const cardChanges = {
                                changes: {
                                    idBoard: card.idBoard,
                                    idColumn: card.idColumn,
                                    archived: card.archived,
                                },
                                id: card._id,
                            };
                            const columnChanges = {
                                changes: { cards: column.cards },
                                id: column._id,
                            };
                            this.socket.updateColumnSuccess({
                                column: columnChanges,
                            });
                            this.socket.onCardUpdate({ card: cardChanges });
                            return [
                                ColumnActions.updateColumn({
                                    column: columnChanges,
                                }),
                                CardActions.upsertCard({ card }),
                            ];
                        })
                    )
            )
        )
    );

    deleteFile$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FileActions.deleteCompFile),
                concatMap(({ id }) =>
                    this.http
                        .put(`${environment.api}/s3/delete`, {
                            file: { _id: id },
                        })
                        .pipe(
                            catchError(err =>
                                of(CardActions.deleteFileFailed({ error: err }))
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    OpenPdfDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CardActions.openPdfDialog),
                map(({ url }) => ({ url })),
                withLatestFrom(
                    this.breakpointObserver.observe(Breakpoints.Handset)
                ),
                tap(([{ url }, breakpointState]) => {
                    const options: MatDialogConfig = {
                        data: url,
                        height: "80%",
                        width: "95%",
                        maxHeight: "90vh",
                        panelClass: "pdf-viewer-dialog",
                        position: {
                            top: "20px",
                            bottom: "20px",
                        },
                    };

                    if (breakpointState.matches) {
                        options.position.top = "0";
                        options.position.bottom = "0";
                        options.position.left = "0";
                        options.position.right = "0";
                    }
                    this.dialog.open(PDFDialogComponent, options);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private router: Router,
        private http: HttpClient,
        private actions$: Actions,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private store: Store<fromRoot.State>,
        private actionService: ActionEffects,
        private socket: SocketService
    ) {}
}
