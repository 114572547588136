import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "cardStatusText" })
export class CardStatusTextPipe implements PipeTransform {
    transform(status: string): string {
        switch (status) {
            case "On Hold":
                return "OH";
            case "Green Light":
                return "GL";
            case "Scheduled In":
                return "SI";
            case "Waiting on Parts":
                return "WOP";
            case "Other":
                return "O";
            default:
                return "";
        }
    }
}
