import {
    CanActivate,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { TokenResponse } from "../../models/tokens";
import { environment } from "src/environments/environment";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { TokenService } from "../../services/misc-services/token.storage";

import * as fromRoot from "src/app/reducers";
import * as fromUser from "src/app/core/store/user";

import * as UserActions from "src/app/core/store/user/user.actions";
import * as RouterActions from "src/app/core/store/router/router.actions";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<fromRoot.State>,
        private tokenService: TokenService,
        private http: HttpClient
    ) {}

    checkToken(
        returnUrl: string,
        checkAdminStatus = false
    ): Observable<boolean> {
        return this.http
            .get<TokenResponse>(`${environment.api}/users/auto-auth`)
            .pipe(
                map(({ tokens, user }) => {
                    this.tokenService.saveToken(tokens.accessToken);
                    this.store.dispatch(
                        UserActions.setLoggedInUser({
                            id: user._id,
                            user,
                        })
                    );
                    if (checkAdminStatus) {
                        return user.roles.includes("Admin");
                    }
                    return true;
                }),
                catchError(() => {
                    this.store.dispatch(
                        RouterActions.Go({
                            payload: {
                                path: ["/login"],
                                extras: {
                                    queryParams: { returnUrl },
                                },
                            },
                        })
                    );
                    return of(false);
                }),
                take(1)
            );
    }

    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(fromUser.selectIsLoggedIn),
            switchMap(isLoggedIn => {
                if (!isLoggedIn) {
                    return this.checkToken(state.url);
                } else {
                    return of(true);
                }
            }),
            take(1)
        );
    }

    canLoad(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.checkToken(state.url, true);
    }
}
