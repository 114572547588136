import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as CardTemplateActions from "./card-template.actions";
import { ICardTemplate as CardTemplate } from "./card-template.model";

export const cardTemplatesFeatureKey = "cardTemplates";

export interface State extends EntityState<CardTemplate> {
    selectedTemplateId: string | null;
}

export const adapter: EntityAdapter<CardTemplate> = createEntityAdapter<CardTemplate>(
    {
        selectId: (template: CardTemplate) => template._id,
        sortComparer: false,
    }
);

export const initialState: State = adapter.getInitialState({
    selectedTemplateId: null,
    // additional entity state properties
});

const cardTemplateReducer = createReducer(
    initialState,
    on(
        CardTemplateActions.addCardTemplate,
        SockActions.addCardTemplate,
        (state, action) => adapter.addOne(action.cardTemplate, state)
    ),
    on(CardTemplateActions.upsertCardTemplate, (state, action) =>
        adapter.upsertOne(action.cardTemplate, state)
    ),
    on(CardTemplateActions.addCardTemplates, (state, action) =>
        adapter.addMany(action.cardTemplates, state)
    ),
    on(CardTemplateActions.upsertCardTemplates, (state, action) =>
        adapter.upsertMany(action.cardTemplates, state)
    ),
    on(
        CardTemplateActions.updateCardTemplate,
        SockActions.updateCardTemplate,
        (state, action) => adapter.updateOne(action.cardTemplate, state)
    ),
    on(CardTemplateActions.updateCardTemplates, (state, action) =>
        adapter.updateMany(action.cardTemplates, state)
    ),
    on(
        CardTemplateActions.deleteCardTemplate,
        SockActions.deleteCardTemplate,
        (state, action) => adapter.removeOne(action.id, state)
    ),
    on(CardTemplateActions.deleteCardTemplates, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(CardTemplateActions.loadCardTemplatesSuccess, (state, action) =>
        adapter.upsertMany(action.cardTemplates, state)
    ),
    on(CardTemplateActions.selectTemplate, (state, action) => ({
        ...state,
        selectedTemplateId: action.id,
    })),
    on(CardTemplateActions.clearCardTemplates, state =>
        adapter.removeAll(state)
    ),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return cardTemplateReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getSelectedTemplate = (state: State) =>
    state.selectedTemplateId
        ? state.entities[state.selectedTemplateId]
        : undefined;
