import { Pipe, PipeTransform } from "@angular/core";
import { ICard } from "../../store/card/card.model";

@Pipe({ name: "HoursLeft" })
export class HoursLeftPipe implements PipeTransform {
    transform(hours: ICard["hours"], hasInitial: boolean): string {
        if (!hasInitial) {
            return "No Initial Hours";
        }
        if (hours) {
            let totalValue = 0;
            let remainingHours = 0;
            Object.values(hours).forEach(h => {
                totalValue += h.totalValue;

                remainingHours += h.totalValue - h.completion;
            });
            const remaining =
                Math.trunc(totalValue) - Math.trunc(remainingHours);
            const outOf = Math.trunc(totalValue);
            if (outOf === 0) {
                return "No Initial Hours";
            }
            return `${remaining}/${outOf} hours complete`;
        }
        return "No Initial Hours";
    }
}
