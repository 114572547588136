import { Dictionary } from "@ngrx/entity";
import { Pipe, PipeTransform } from "@angular/core";
import { ISupplement } from "../../store/supplement/supplement.model";

@Pipe({ name: "supplements" })
export class SupplementsPipe implements PipeTransform {
    transform(
        hoursInDepartment: {
            totalValue: number;
            completion: number;
            department: string;
        },
        cardSupplements: string[],
        supplements: Dictionary<ISupplement>,
        departmentName: string
    ): string {
        if (supplements && cardSupplements && hoursInDepartment) {
            const newHours = {
                totalValue: hoursInDepartment.totalValue,
                remaining:
                    hoursInDepartment.totalValue - hoursInDepartment.completion,
                // old hoursInDepartment.totalValue * hoursInDepartment.completion,
                supplementHours: 0,
            };

            cardSupplements.forEach(suppId => {
                const supplement = supplements[suppId];
                if (supplement && supplement.hours) {
                    if (supplement.status === "processed" && supplement.hours) {
                        newHours.supplementHours +=
                            supplement.hours[
                                departmentName as keyof ISupplement["hours"]
                            ].totalValue;
                    }
                }
            });
            newHours.remaining = Math.trunc(
                hoursInDepartment.completion * hoursInDepartment.totalValue
            );
            return `${newHours.remaining}&nbsp;/&nbsp;${newHours.totalValue}`;
        } else {
            return `0&nbsp;/&nbsp;0`;
        }
    }
}
