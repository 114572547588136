export class Menu {
    constructor(
        public id: number,
        public title: string,
        public routerLink: string,
        public href: string,
        public icon: string | null,
        public target: string,
        public hasSubMenu: string | false,
        public parentId: number | false,
        public Iicon: string | null = null
    ) {}
}
