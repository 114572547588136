import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { map, tap } from "rxjs/operators";
import { RouterStateUrl } from "src/app/router.serializer";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATION, RouterNavigatedAction } from "@ngrx/router-store";

import * as RouterActions from "./router.actions";

@Injectable()
export class RouterEffects {
    Go$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.Go),
                map(action => action.payload),
                tap(({ path, query: queryParams, extras }) => {
                    this.router.navigate(path, { queryParams, ...extras });
                })
            ),
        { dispatch: false }
    );

    Back$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.Back),
                tap(() => this.location.back())
            ),
        { dispatch: false }
    );

    Forward$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.Back),
                tap(() => this.location.forward())
            ),
        { dispatch: false }
    );
    Navigation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATION),
                map(
                    (r: RouterNavigatedAction<RouterStateUrl>) =>
                        r.payload.routerState
                ),
                tap(({ breadcrumb }) => {
                    if (breadcrumb) {
                        this.titleService.setTitle(breadcrumb || "Repairsage");
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        private router: Router,
        private actions$: Actions,
        private location: Location,
        private titleService: Title
    ) {}
}
