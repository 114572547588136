import { Dictionary } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";
import { selectColumnsState } from "src/app/reducers";

import { IColumn } from "./column.model";
import { ICard } from "../card/card.model";
import { IDepartment } from "../department/department.model";

import * as fromCard from "../card";
import * as fromBoard from "../board";
import * as fromColumn from "./column.reducer";
import * as fromDepartment from "../department";

export type DepCalcdValue = {
    M: {
        value: number;
        efficiency: number | false;
    };
    P: {
        value: number;
        efficiency: number | false;
    };
    B: {
        value: number;
        efficiency: number | false;
    };
    F: {
        value: number;
        efficiency: number | false;
    };
    S: {
        value: number;
        efficiency: number | false;
    };
    D: {
        value: number;
        efficiency: number | false;
    };
};
export type DepInitial = keyof DepCalcdValue;

const { selectEntities, selectAll } = fromColumn.adapter.getSelectors(
    selectColumnsState
);

export const selectColumns = selectAll;

export const selectColumnEntities = selectEntities;

export const selectColumnsOfBoardOrder = createSelector(
    selectColumnEntities,
    fromBoard.selectSelectedBoard,
    (columnEntities, board) => {
        if (!board) {
            return [];
        } else {
            return board.columns.map(c => columnEntities[c]);
        }
    }
);

export const selectColumnLoadData = createSelector(
    selectEntities,
    fromCard.selectCardEntities,
    fromDepartment.selectDepartmentEntities,
    (
        columns: Dictionary<IColumn>,
        cards: Dictionary<ICard>,
        departments: Dictionary<IDepartment>,
        props: { columnId: string }
    ) => {
        const column = columns[props.columnId];
        // departmentsPerName["Mechanical"].
        if (column && column.isBound) {
            const totalValues: DepCalcdValue = {
                M: {
                    value: 0,
                    efficiency: false,
                },
                P: {
                    value: 0,
                    efficiency: false,
                },
                B: {
                    value: 0,
                    efficiency: false,
                },
                F: {
                    value: 0,
                    efficiency: false,
                },
                S: {
                    value: 0,
                    efficiency: false,
                },
                D: {
                    value: 0,
                    efficiency: false,
                },
            };
            Object.values(departments).forEach(dep => {
                if (dep?.employees) {
                    const emp = dep.employees.find(
                        e => e.emp === column.extendedInfo.idUser
                    );
                    if (!!emp) {
                        totalValues[
                            dep.name[0].toUpperCase() as DepInitial
                        ].efficiency = emp.efficiency;
                    }
                }
            });
            column.cards.forEach(cardId => {
                const card = cards[cardId];
                if (card && card.hours) {
                    const {
                        structural,
                        mechanical,
                        detail,
                        paint,
                        frame,
                        body,
                    } = card.hours;
                    if (body) {
                        totalValues.B.value +=
                            body.totalValue - body.completion;
                    }
                    if (detail) {
                        totalValues.D.value +=
                            detail.totalValue - detail.completion;
                    }
                    if (frame) {
                        totalValues.F.value +=
                            frame.totalValue - frame.completion;
                    }
                    if (mechanical) {
                        totalValues.M.value +=
                            mechanical.totalValue - mechanical.completion;
                    }
                    if (paint) {
                        totalValues.P.value +=
                            paint.totalValue - paint.completion;
                    }
                    if (structural) {
                        totalValues.S.value +=
                            structural.totalValue - structural.completion;
                    }
                }
            });
            return totalValues;
        } else {
            return undefined;
        }
    }
);
