import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { IUser } from "../../store/user/user.model";

import * as fromRoot from "src/app/reducers";
import * as fromUser from "src/app/core/store/user";

@Injectable({ providedIn: "root" })
export class NotificationService {
    user: IUser | undefined;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {
        this.store
            .pipe(select(fromUser.selectLoggedInUser))
            .subscribe(user => (this.user = user));
    }

    addPushSubscriber(subscription: PushSubscription) {
        this.http.post(`${environment.api}/sub/new`, subscription).subscribe();
    }

    sendToPushSubscribers() {
        const msg = { message: "Sending a test notification!" };
        this.http
            .post(`${environment.api}/sub/send`, msg)
            .pipe(
                catchError(err => {
                    return of(err);
                })
            )
            .subscribe();
    }
}
