// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/plugins/zone-error";

export const environment = {
    production: false,
    VAPID_PUBLIC:
        "BN3Sy1xLoTIYAQeI7QFVo29bjuXa4kCsh6ZTmQVFdiuSAkEP9eo2N2Q7AeYLtb5m8TDnG9h7K53dFrpe0oaHIs8",
    api: "/api",
    apiProxy: "https://localhost:8000",
    socketConfig: {
        url: "http://localhost:8000",
        opts: {
            transports: ["websocket"],
        },
    },
}; // Included with Angular CLI.
