import { Pipe, PipeTransform } from "@angular/core";
import { IPhone } from "../../store/communication/communications.types";
@Pipe({ name: "preferredPhone" })
export class PreferredPhonePipe implements PipeTransform {
    transform(phones: IPhone[]): any {
        if (phones.length) {
            const index = phones.findIndex(p => p.preferred);
            if (index === -1) {
                if (phones.length > 0) {
                    return phones[0].number;
                }
                return "";
            }
            return phones[0].number;
        }
    }
}
