import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as CustomerActions from "./customer.actions";
import { ICustomer as Customer } from "./customer.types";

export const customersFeatureKey = "customers";

export type State = EntityState<Customer>;

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
    selectId: (customer: Customer) => customer._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const customerReducer = createReducer(
    initialState,
    on(CustomerActions.addCustomer, SockActions.addCustomer, (state, action) =>
        adapter.addOne(action.customer, state)
    ),
    on(CustomerActions.upsertCustomer, (state, action) =>
        adapter.upsertOne(action.customer, state)
    ),
    on(CustomerActions.addCustomers, (state, action) =>
        adapter.addMany(action.customers, state)
    ),
    on(CustomerActions.upsertCustomers, (state, action) =>
        adapter.upsertMany(action.customers, state)
    ),
    on(
        CustomerActions.updateCustomer,
        SockActions.updateCustomer,
        (state, action) => adapter.updateOne(action.customer, state)
    ),
    on(CustomerActions.updateCustomers, (state, action) =>
        adapter.updateMany(action.customers, state)
    ),
    on(CustomerActions.deleteCustomer, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(CustomerActions.deleteCustomers, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(CustomerActions.loadCustomersSuccess, (state, action) =>
        adapter.upsertMany(action.customers, state)
    ),
    on(CustomerActions.clearCustomers, state => adapter.removeAll(state)),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return customerReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
