<div
        id="app"
        class="app"
        [ngClass]="theme$ | async"
        [class.is-mobile]="(isMobile$ | async).matches"
>
    <ng-template *ngIf="isLoggedIn$ | async"></ng-template>
    <!-- <div class="unsupported-browser" *ngIf="warningMessage">
        {{ warningMessage }}
    </div> -->

    <router-outlet name="card"></router-outlet>

    <router-outlet></router-outlet>
    <div [@inOutAnimation] id="app-spinner" *ngIf="loadingSpinner$ | async">
        <img
                src="assets/icons/logo-72x72.png"
                class="animated-logo"
                alt="logo"
        />
        <h4>loading...</h4>
    </div>
    <button
            *ngIf="!isProduction"
            style="position: absolute; bottom: 20px; left: 20px"
            mat-stroked-button
            color="warn"
            (click)="toggleWebSockets()"
    >
        {{ debuggingSocketDisableBtnText }}
    </button>
</div>
