import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { ISupplement, ISupplement as Supplement } from "./supplement.model";

export const loadPendingSupplements = createAction(
    "[Supplement/API] Load Pending Supplements"
);

export const loadPendingSupplementsFailed = createAction(
    "[Supplement/API] Load Pending Supplements Failed",
    props<{ error: any }>()
);

export const loadPendingSupplementsSuccess = createAction(
    "[Supplement/API] Load Pending Supplements Success",
    props<{ supplements: ISupplement[] }>()
);

export const loadAllSupplements = createAction(
    "[Supplement/API] Load All Supplements"
);

export const loadAllSupplementsFailed = createAction(
    "[Supplement/API] Load All Supplements Failed",
    props<{ error: any }>()
);

export const loadAllSupplementsSuccess = createAction(
    "[Supplement/API] Load All Supplements Success",
    props<{ supplements: ISupplement[] }>()
);

export const addSupplement = createAction(
    "[Supplement/API] Add Supplement",
    props<{ supplement: Supplement }>()
);

export const upsertSupplement = createAction(
    "[Supplement/API] Upsert Supplement",
    props<{ supplement: Supplement }>()
);

export const addSupplements = createAction(
    "[Supplement/API] Add Supplements",
    props<{ supplements: Supplement[] }>()
);

export const upsertSupplements = createAction(
    "[Supplement/API] Upsert Supplements",
    props<{ supplements: Supplement[] }>()
);

export const updateSupplementSuccess = createAction(
    "[Supplement/API] Update Supplement Success",
    props<{ supplement: Supplement }>()
);

export const updateSupplement = createAction(
    "[Supplement/API] Update Supplement",
    props<{ supplement: Update<Supplement> }>()
);

export const updateSupplementFailure = createAction(
    "[Supplement/API] Update Supplement Failure",
    props<{ error: string }>()
);

export const updateSupplements = createAction(
    "[Supplement/API] Update Supplements",
    props<{ supplements: Update<Supplement>[] }>()
);

export const deleteSupplement = createAction(
    "[Supplement/API] Delete Supplement",
    props<{ id: string }>()
);

export const deleteSupplements = createAction(
    "[Supplement/API] Delete Supplements",
    props<{ ids: string[] }>()
);

export const clearSupplements = createAction(
    "[Supplement/API] Clear Supplements"
);
export const selectSupplements = createAction(
    "[Supplement] Select Supplements",
    props<{ id: string }>()
);

export const deSelectSupplements = createAction(
    "[Supplement] De Select Supplements"
);

export const clearError = createAction("[Supplement] Clear Error");
