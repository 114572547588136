import { createAction, props } from "@ngrx/store";
import { IToast } from "../../models/toast";

export const startSpinner = createAction("[Layout] Start Spinner");
export const endSpinner = createAction("[Layout] End Spinner");
export const openSidenav = createAction("[Layout] OPEN Sidenav");
export const closeSidenav = createAction("[Layout] CLOSE Sidenav");
export const changeTheme = createAction(
    "[Layout] Change Theme",
    props<{ theme: string }>()
);
export const showToastr = createAction(
    "[Layout] New Toast",
    props<{
        payload: IToast;
    }>()
);

export const changeSocketConnectionStatus = createAction(
    "[Layout] Change Socket Connection Status",
    props<{ isConnected: boolean }>()
);
