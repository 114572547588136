import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ITodo } from "../../store/todo/todo.model";
import { IUser } from "../../store/user/user.model";

@Injectable()
export class TodoService {
    constructor(private http: HttpClient) {}

    getTodos() {
        return this.http.get<ITodo[]>(`${environment.api}/todos`);
    }

    createTodo(todo: Partial<ITodo>): Observable<ITodo> {
        return this.http.post<ITodo>(`${environment.api}/todos/new`, todo);
    }

    deleteTodo(todoId: string) {
        return this.http.delete(`${environment.api}/todos/rmv/${todoId}`);
    }

    changedCompletedStatus(todo: ITodo) {
        return this.http.patch(
            `${environment.api}/todos/cmplt/${todo._id}`,
            todo
        );
    }

    updateText(todo: ITodo) {
        return this.http.patch(`${environment.api}/todos/modify_text`, todo);
    }

    updateTodo(todoId: string, todo: Partial<ITodo>) {
        return this.http.put(`${environment.api}/todos/${todoId}`, todo);
    }

    removeMember(user: IUser, todoId: string) {
        return this.http.patch(
            `${environment.api}/todos/rmv_member/${todoId}`,
            user
        );
    }

    addMember(user: IUser, todoId: string) {
        return this.http.patch(
            `${environment.api}/todos/add_member/${todoId}`,
            user
        );
    }
}
