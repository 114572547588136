import {
    createFeatureSelector,
    createSelector,
    Action,
    createReducer,
    on,
} from "@ngrx/store";

import { Toast } from "../../models/toast";
import * as LayoutActions from "./layout.actions";

export const layoutFeatureKey = "settings";

export interface State {
    toast: Toast | null;
    theme: string;
    isSocketConnected: boolean;
    loadingSpinner: boolean;
    sidenavIsOpened: boolean;
}

export const initialState: State = {
    toast: null,
    theme: "green-dark",
    isSocketConnected: false,
    loadingSpinner: true,
    sidenavIsOpened: false,
};

const layoutReducer = createReducer(
    initialState,
    on(LayoutActions.openSidenav, (state: State) => ({
        ...state,
        sidenavIsOpened: true,
    })),
    on(LayoutActions.endSpinner, (state: State) => ({
        ...state,
        loadingSpinner: false,
    })),
    on(LayoutActions.closeSidenav, (state: State) => ({
        ...state,
        sidenavIsOpened: false,
    })),
    on(LayoutActions.startSpinner, (state: State) => ({
        ...state,
        loadingSpinner: true,
    })),
    on(LayoutActions.showToastr, (state, { payload }) => ({
        ...state,
        toast: new Toast(
            payload.body,
            payload.title,
            payload.type,
            payload.meta,
            payload.override
        ),
    })),
    on(LayoutActions.changeTheme, (state, action) => ({
        ...state,
        theme: action.theme,
    })),
    on(LayoutActions.changeSocketConnectionStatus, (state, action) => ({
        ...state,
        isSocketConnected: action.isConnected,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return layoutReducer(state, action);
}

export const getSettingsState = createFeatureSelector<State>("settings");
export const getSidenavIsOpen = createSelector(
    getSettingsState,
    state => state.sidenavIsOpened
);
export const getIsLoading = createSelector(
    getSettingsState,
    state => state.loadingSpinner
);

export const selectToast = createSelector(
    getSettingsState,
    state => state.toast
);
export const selectTheme = createSelector(
    getSettingsState,
    state => state.theme
);
export const selectSocketConnectionStatus = createSelector(
    getSettingsState,
    state => state.isSocketConnected
);
