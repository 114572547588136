<div fxLayout="row" class="breadcrumbA p-2">
    <a
        routerLink="/boards"
        class="breadcrumb-item"
        fxLayout="row"
        fxLayoutAlign="start center"
        (click)="closeSubMenus()"
    >
        <mat-icon>home</mat-icon>
        <span class="breadcrumb-title">Home</span>
    </a>
    <div
        *ngFor="let breadcrumb of breadcrumbs; let i = index"
        class="breadcrumb-item"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <a
            [hidden]="i == breadcrumbs.length - 1"
            [routerLink]="[breadcrumb.url]"
            >{{ breadcrumb.name }}</a
        >
        <span
            [hidden]="i != breadcrumbs.length - 1"
            class="breadcrumb-title active"
            >{{ breadcrumb.name }}</span
        >
    </div>
</div>
