import {
    AsyncValidatorFn,
    AbstractControl,
    ValidationErrors,
} from "@angular/forms";
import { Observable, NEVER } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { clone, uniq } from "lodash";
export class Utils {
    public static moveCardInSameColumn(
        cardContainer: string[],
        prevIndex: number,
        targetIndex: number
    ) {
        const cards = clone(cardContainer);
        const [removed] = cards.splice(prevIndex, 1);
        cards.splice(targetIndex, 0, removed);
        return cards;
    }
    public static moveCardBtwnColumns({
        prevCards,
        prevIndex,
        targCards,
        targIndex,
    }: {
        prevCards: string[];
        targCards: string[];
        prevIndex: number;
        targIndex: number;
    }): { prevCardsAltered: string[]; targetCardsAltered: string[] } {
        const prevCardsAltered = clone(prevCards);
        const [cardId] = prevCardsAltered.splice(prevIndex, 1);

        const targetCardsAltered = clone([
            ...targCards.slice(0, targIndex),
            cardId,
            ...targCards.slice(targIndex),
        ]);
        return { prevCardsAltered, targetCardsAltered };
    }

    public static hasDuplicateCardIds(cards: string[]): boolean {
        return uniq(cards).length !== cards.length;
    }

    public static validateRoIsTaken(http: HttpClient): AsyncValidatorFn {
        return (
            ctrl: AbstractControl
        ):
            | Promise<ValidationErrors | null>
            | Observable<ValidationErrors | null> =>
            http
                .get<{ isTaken: boolean }>(
                    `${environment.api}/cards/check-ro/${ctrl.value}`
                )
                .pipe(catchError(() => NEVER));
    }

    public static calcDistanceFromCenter(
        elem: HTMLElement,
        mouseX: number,
        mouseY: number
    ) {
        const distanceSquared =
            Math.pow(mouseX - (elem.offsetLeft + elem.clientWidth / 2), 2) +
            Math.pow(mouseY - (elem.offsetTop + elem.clientHeight / 2), 2);

        return Math.floor(Math.sqrt(distanceSquared));
    }

    private static _getPadding(el: HTMLElement) {
        return {
            left: parseInt(el.style.paddingLeft, 10),
            top: parseInt(el.style.paddingTop, 10),
            right: parseInt(el.style.paddingRight, 10),
            bottom: parseInt(el.style.paddingBottom, 10),
        };
    }

    public static frameInCoordinateSpace(
        childEl: HTMLElement,
        parentEl: HTMLElement
    ) {
        const parentPadding = this._getPadding(parentEl);
        const childFrame = {
            top: childEl.offsetTop - (parentEl.offsetTop + parentPadding.top),
            left:
                childEl.offsetLeft - (parentEl.offsetLeft + parentPadding.left),
            bottom: 0,
            right: 0,
        };
        childFrame.bottom = childFrame.top + childEl.clientHeight;
        childFrame.right = childFrame.left + childEl.clientWidth;
        return childFrame;
    }

    public static getBounds(el: HTMLElement) {
        const bounds = {
            left: el.scrollLeft,
            top: el.scrollTop,
            width: el.clientWidth,
            height: el.clientHeight,
            bottom: 0,
            right: 0,
        };
        bounds.bottom = bounds.top + bounds.height;
        bounds.right = bounds.left + bounds.width;
        return bounds;
    }

    public static scrollElementIntoView(
        parentEl: HTMLElement,
        childEl: HTMLElement,
        param: ScrollParams
    ) {
        const { animated, horizontal, vertical } = param;
        const parentBounds = this.getBounds(parentEl);
        const parentPadding = this._getPadding(parentEl);
        const childFrame = this.frameInCoordinateSpace(childEl, parentEl);
        const frameWeWantVisible = {
            top: childFrame.top - parentPadding.top,
            bottom: childFrame.bottom + parentPadding.bottom,
            left: childFrame.left - parentPadding.left,
            right: childFrame.right - parentPadding.right,
        };

        const set = (() => {
            if (animated) {
                return function (prop: CssProp, val: any) {
                    parentEl.style.transition = "all 150ms";

                    parentEl.style[prop as any] = val;
                    return parent;
                };
            } else {
                return (prop: CssProp, val: number) => (parentEl[prop] = val);
            }
        })();

        if (vertical) {
            const scrollVertical = (top: number) =>
                set("scrollTop", top + parentPadding.top);
            if (frameWeWantVisible.bottom > parentBounds.bottom) {
                scrollVertical(frameWeWantVisible.bottom - parentBounds.height);
            } else if (frameWeWantVisible.top < parentBounds.top) {
                scrollVertical(frameWeWantVisible.top);
            }
        }
        if (horizontal) {
            const scrollHorizontal = (left: number) =>
                set("scrollLeft", left + parentPadding.left);
            if (frameWeWantVisible.right > parentBounds.right) {
                scrollHorizontal(frameWeWantVisible.right - parentBounds.width);
            } else if (frameWeWantVisible.left < parentBounds.left) {
                scrollHorizontal(frameWeWantVisible.left);
            }
        }
    }
    public static date_diff_indays(eventDate: Date, date2?: Date) {
        const dt1 = new Date(date2 || Date.now());
        const dt2 = new Date(eventDate);
        return Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                (1000 * 60 * 60 * 24)
        );
    }
    public static daysUntil(date: Date) {
        const difference =
            new Date(date).getTime() - new Date(Date.now()).getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
    }
    public static getTime(): number {
        if (
            (typeof performance !== "undefined" && performance !== null
                ? performance.now
                : undefined) != null
        ) {
            return performance.now();
        } else {
            return +new Date();
        }
    }
    public static getMs({
        days,
        hours,
        minutes,
        seconds,
        ms,
        years,
        months,
        weeks,
    }: UtilTime): number {
        if (days == null) {
            days = 0;
        }
        if (hours == null) {
            hours = 0;
        }
        if (minutes == null) {
            minutes = 0;
        }
        if (seconds == null) {
            seconds = 0;
        }
        if (ms == null) {
            ms = 0;
        }

        if (years) {
            days += years * 365;
        }
        if (months) {
            days += months * 30;
        }
        if (weeks) {
            days += weeks * 7;
        }

        hours += days * 24;
        minutes += hours * 60;
        seconds += minutes * 60;

        ms += seconds * 1000;
        return ms;
    }
    public static getSeconds(args: UtilTime): number {
        return Utils.getMs(args) / 1000;
    }
    public static idToDate(id: string): Date {
        return new Date(
            1000 * parseInt(id != null ? id.substr(0, 8) : undefined, 16)
        );
    }
    public static randomWait(wait: number, spread: number) {
        return Math.floor(wait + Math.random() * spread);
    }
}
type ScrollParams = {
    horizontal: boolean;
    vertical: boolean;
    animated: boolean;
};
type CssProp = "scrollTop" | "scrollLeft";
type UtilTime = {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    ms?: number;
    years?: number;
    months?: number;
    weeks?: number;
};
