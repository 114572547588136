import { Pipe, PipeTransform } from "@angular/core";
import { IDepartment } from "src/app/core/store/department/department.model";

@Pipe({ name: "loadColor" })
export class LoadColorPipe implements PipeTransform {
    transform(totalValue: number, dep: IDepartment, idUser: string): string {
        const emp = dep.employees?.find(e => e.emp === idUser);
        if (emp && emp.efficiency && emp.emp) {
            const hoursPerWeek = 40;
            const result = totalValue * emp.efficiency;
            const hoursRemaining = hoursPerWeek - result;
            const fiftyPercent = hoursPerWeek * 0.5;
            const twentyFivePercent = hoursPerWeek * 0.25;
            if (hoursRemaining < 0) return "text-red";
            if (hoursRemaining <= twentyFivePercent) return "text-orange";
            if (hoursRemaining <= fiftyPercent) return "text-yellow";
            if (hoursRemaining > fiftyPercent) return "text-green";
        }
        return "";
    }
}
