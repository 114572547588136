import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-warning-dialog",
    templateUrl: "./warning-dialog.component.html",
    styleUrls: ["./warning-dialog.component.scss"],
})
export class WarningDialogComponent {
    invertButtons = false;

    constructor(
        private dialogRef: MatDialogRef<WarningDialogComponent>,
        // tslint:disable-next-line: prefer-inline-decorator
        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: string;
            buttonText: string;
            invertButtons?: boolean;
            optionalButtonText?: string;
        }
    ) {
        // added an invert buttons variable to invert the buttons
        // because I didn't want to screw with any other files
        // that are using this dialog
        this.invertButtons = !!this.data.invertButtons;
    }

    close(confirmed: boolean) {
        this.dialogRef.close(confirmed);
    }
}
