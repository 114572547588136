import { Pipe, PipeTransform } from "@angular/core";
import { Dictionary } from "@ngrx/entity";
import { ICard } from "../../store/card/card.model";
import { SortBy } from "../../store/column/column.model";

@Pipe({ name: "sortCards" })
export class SortCardsPipe implements PipeTransform {
    transform(
        columnCards: string[],
        cards: Dictionary<ICard>,
        sortBy: SortBy,
        isDragging: boolean
    ): string[] {
        if (isDragging) return columnCards;
        if (!sortBy) return columnCards;
        const cardsMapped = columnCards.map(c => cards[c]);
        cardsMapped.sort(
            (a, b) =>
                new Date(a[sortBy]).getTime() - new Date(b[sortBy]).getTime()
        );
        return cardsMapped.map(c => c._id);
    }
}
