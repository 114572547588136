import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Injectable({ providedIn: "root" })
export class ThemeService {
    private theme: BehaviorSubject<string> = new BehaviorSubject("green-dark");

    private device$: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(breakpointObserver: BreakpointObserver) {
        breakpointObserver
            .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
            .subscribe(result => {
                if (
                    result.breakpoints[Breakpoints.WebPortrait] ||
                    result.breakpoints[Breakpoints.WebLandscape]
                ) {
                    this.changeDevice("desktop");
                } else if (
                    result.breakpoints[Breakpoints.TabletPortrait] ||
                    result.breakpoints[Breakpoints.TabletLandscape]
                ) {
                    this.changeDevice("tablet");
                } else if (
                    result.breakpoints[Breakpoints.HandsetPortrait] ||
                    result.breakpoints[Breakpoints.HandsetLandscape]
                ) {
                    this.changeDevice("phone");
                }
            });
    }

    get currentTheme(): Observable<string> {
        return this.theme.asObservable();
    }

    set CurrentTheme(theme: string) {
        this.theme.next(theme);
    }

    get whichDevice() {
        return this.device$.asObservable();
    }

    changeTheme(themeString: string) {
        this.theme.next(themeString);
    }

    changeDevice(deviceString: string) {
        this.device$.next(deviceString);
    }
}
