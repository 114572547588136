import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

import { Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
    map,
    filter,
    mergeMap,
    concatMap,
    catchError,
    exhaustMap,
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { ISupplement } from "./supplement.model";

import * as fromUser from "../user";
import * as fromRoot from "src/app/reducers";
import * as LayoutActions from "../layout/layout.actions";
import * as SupplementActions from "./supplement.actions";

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class SupplementEffects {
    LoadPendingSupplements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SupplementActions.loadPendingSupplements),
            mergeMap(() => this.store.select(fromUser.selectLoggedInUser)),
            filter(user =>
                Boolean(
                    user && user.roles && user.roles.includes("Service Writer")
                )
            ),
            exhaustMap(user =>
                this.http
                    .get<{ supplements: ISupplement[] }>(
                        `/api/supplements/pending/${user._id}`
                    )
                    .pipe(
                        map(supplements =>
                            SupplementActions.loadPendingSupplementsSuccess(
                                supplements
                            )
                        ),
                        catchError(error => {
                            console.error(
                                "Couldn't fetch the supplements.",
                                error
                            );
                            return of(
                                SupplementActions.loadPendingSupplementsFailed({
                                    error,
                                })
                            );
                        })
                    )
            )
        )
    );

    LoadAllSupplements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SupplementActions.loadAllSupplements),
            mergeMap(() => this.store.select(fromUser.selectLoggedInUser)),
            concatMap(user => {
                if (user && user.roles.includes("Service Writer")) {
                    return this.http
                        .get<ISupplement[]>(
                            `${environment.api}/supplements/sw/${user._id}`
                        )
                        .pipe(
                            map(supplements =>
                                SupplementActions.loadAllSupplementsSuccess({
                                    supplements,
                                })
                            )
                        );
                } else {
                    return EMPTY;
                }
            }),
            catchError(error =>
                of(SupplementActions.loadAllSupplementsFailed({ error }))
            )
        )
    );

    UpdateSupplement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SupplementActions.updateSupplement),
            map(action => action.supplement),
            concatMap(supplement =>
                this.http
                    .put<ISupplement>(
                        `${environment.api}/supplements/${supplement.id}`,
                        supplement.changes
                    )
                    .pipe(
                        concatMap(newSupplement => [
                            SupplementActions.updateSupplementSuccess({
                                supplement: newSupplement,
                            }),
                            SupplementActions.deSelectSupplements(),
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Supplement update successful",
                                    title: "",
                                    type: "success",
                                },
                            }),
                        ])
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {}
}
