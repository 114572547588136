import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { Move } from "../../models/types";
import { IBoard } from "../board/board.model";
import { ICard } from "../card/card.model";
import { ICardTemplate, IChecklist } from "../cardTemplate/card-template.model";
import { IColumn } from "../column/column.model";
import { ICustomer } from "../customer/customer.types";
import { IDepartment } from "../department/department.model";
import { IUser } from "../user/user.model";
import { IVehicle } from "../vehicle/vehicle.model";

export const updateBoardSuccess = createAction(
    "[Socket] Update Board",
    props<{ board: Update<IBoard> }>()
);

export const addColumn = createAction(
    "[Socket] Create Column",
    props<{ column: IColumn }>()
);
export const updateColumn = createAction(
    "[Socket] Update Column",
    props<{ column: Update<IColumn> }>()
);

export const updateColumns = createAction(
    "[Socket] Update Columns",
    props<{ columns: Update<IColumn>[] }>()
);
export const updateCards = createAction(
    "[Socket] Update Cards",
    props<{ cards: Update<ICard>[] }>()
);
export const deleteColumn = createAction(
    "[Socket] Delete Column",
    props<{ id: string }>()
);

export const updateCard = createAction(
    "[Socket] Card Updated",
    props<{ card: Update<ICard> }>()
);
export const addCard = createAction(
    "[Socket] Card Added",
    props<{ card: ICard }>()
);

export const updateUser = createAction(
    "[Socket] User Updated",
    props<{ user: Update<IUser> }>()
);
export const addUser = createAction(
    "[Socket] User Created",
    props<{ user: IUser }>()
);

export const updateChecklist = createAction(
    "[Socket] Checklist Updated",
    props<{ checklist: Update<IChecklist> }>()
);
export const addChecklist = createAction(
    "[Socket] Checklist Created",
    props<{ checklist: IChecklist }>()
);
export const deleteChecklist = createAction(
    "[Socket] Checklist deleted",
    props<{ id: string }>()
);

export const updateDepartment = createAction(
    "[Socket] Department Updated",
    props<{ department: Update<IDepartment> }>()
);
export const addCardTemplate = createAction(
    "[Socket] Add CardTemplate",
    props<{ cardTemplate: ICardTemplate }>()
);

export const updateCardTemplate = createAction(
    "[Socket] Update CardTemplate",
    props<{ cardTemplate: Update<ICardTemplate> }>()
);
export const deleteCardTemplate = createAction(
    "[Socket] Delete CardTemplate",
    props<{ id: string }>()
);
export const addCustomer = createAction(
    "[Socket] Add Customer",
    props<{ customer: ICustomer }>()
);

export const updateCustomer = createAction(
    "[Socket] Update Customer",
    props<{ customer: Update<ICustomer> }>()
);
export const addVehicle = createAction(
    "[Socket] Add Vehicle",
    props<{ vehicle: IVehicle }>()
);

export const updateVehicle = createAction(
    "[Socket] Update Vehicle",
    props<{ vehicle: Update<IVehicle> }>()
);

export const onCardMove = createAction("[Socket] Card Moved", props<Move>());

// export const VehicleUpdated = createAction("VehicleUpdated", props<{payload: any}>());
// export const VehicleCreated = createAction("VehicleCreated", props<{payload: any}>());
// export const CustomerCreated = createAction("CustomerCreated", props<{payload: any}>());
// export const CustomerUpdated = createAction(
//   "[Socket] CustomerUpdated",
//   props<{ payload: ICustomer }>()
// );
// export const SupplementUpdated = createAction(
//   "[Socket] SupplementUpdated",
//   props<{ payload: ISupplement }>()
// );
// export const SupplementCreated = createAction(
//   "[Socket] SupplementCreated",
//   props<{ payload: ISupplement }>()
// );
// export const SupplementDELETED = createAction(
//   "[Socket] SupplementDELETED",
//   props<{ payload: ISupplement }>()
// );

// export const Toastr = createAction("Toastr", props<{toast: Toastr}>());
// export const Notification = createAction("Notification", props<{}>());
// export const BoardUpdate = createAction("BoardUpdate", props<{}>());
// export const FilesUploaded = createAction(
//   "[Socket] FilesUploaded",
//   props<{ payload: ICompFile[] }>()
// );
// export const FileUploaded = createAction(
//   "[Socket] FileUploaded",
//   props<{ payload: ICompFile }>()
// );
// export const FilesRemoved = createAction(
//   "[Socket] FilesRemoved",
//   props<{ payload: ICompFile[] }>()
// );
