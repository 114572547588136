import { createReducer, on } from "@ngrx/store";
import * as AuthActions from "./auth.actions";

type LoginStatus = "authenticated" | "not authenticated" | "loading";

export interface State {
    status: LoginStatus;
    error: string | null;
}

export const initialAuthState: State = {
    error: null,
    status: "not authenticated",
};

export const authReducer = createReducer<State>(
    initialAuthState,
    on(AuthActions.loginStarted, state => ({
        ...state,
        error: null,
        status: "loading",
    })),
    on(AuthActions.loginSuccess, AuthActions.loadDashboard, _state => ({
        error: null,
        status: "authenticated" as LoginStatus,
    })),
    on(AuthActions.autoAuthFailed, state => ({
        ...state,
        status: "not authenticated",
    })),
    on(AuthActions.registerCompany, state => ({ ...state, status: "loading" })),
    on(AuthActions.registerCompanyFailed, (_state, { error }) => ({
        status: "not authenticated",
        error,
    })),
    on(AuthActions.registerCompanySuccess, _state => ({
        error: null,
        status: "authenticated",
    })),
    on(AuthActions.loginFailed, () => ({
        error: "Incorrect username or password",
        status: "not authenticated" as LoginStatus,
    })),
    on(AuthActions.logout, state => ({
        ...state,
        status: "not authenticated",
    })),
    on(AuthActions.clearError, state => ({ ...state, error: null }))
);
export const getAuthError = (state: State) => state.error;
export const getAuthStatus = (state: State) => state.status;
