import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, concatMap, switchMap } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { ICardTemplate } from "./card-template.model";

import * as CardTemplateActions from "./card-template.actions";

@Injectable()
export class CardTemplateEffects {
    LoadTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardTemplateActions.loadCardTemplates),
            switchMap(() =>
                this.http
                    .get<ICardTemplate[]>(`${environment.api}/temp/all`)
                    .pipe(
                        map(cardTemplates =>
                            CardTemplateActions.loadCardTemplatesSuccess({
                                cardTemplates,
                            })
                        )
                    )
            )
        )
    );

    DeleteTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CardTemplateActions.deleteCardTemplate),
                concatMap(({ id }) =>
                    this.http.delete(`${environment.api}/temp/${id}`)
                )
            ),
        { dispatch: false }
    );

    UpdateTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CardTemplateActions.updateCardTemplate),
                concatMap(({ cardTemplate: { changes, id } }) =>
                    this.http.put<ICardTemplate>(
                        `${environment.api}/temp/${id}`,
                        {
                            _id: id,
                            ...changes,
                        }
                    )
                )
            ),
        { dispatch: false }
    );

    CreateTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardTemplateActions.createTemplate),
            concatMap(({ template }) =>
                this.http
                    .post<ICardTemplate>(
                        `${environment.api}/temp/new`,
                        template
                    )
                    .pipe(
                        //   tap(newTemplate =>
                        //     this.socket.onTemplateCreatedSuccess(newTemplate)
                        //   ),
                        map(newData =>
                            CardTemplateActions.addCardTemplate({
                                cardTemplate: newData,
                            })
                        )
                    )
            )
        )
    );

    constructor(private actions$: Actions, private http: HttpClient) {}
}
