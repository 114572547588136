import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { IUser } from "src/app/core/store/user/user.model";
import { environment } from "src/environments/environment";

import * as fromRoot from "src/app/reducers";
import * as fromUser from "src/app/core/store/user";

@Injectable()
export class UserService {
    user$: Observable<IUser | undefined> = this.store.pipe(
        select(fromUser.selectLoggedInUser)
    );

    isLoggedIn$: Observable<boolean> = this.store.select(
        fromUser.selectIsLoggedIn
    );

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {}

    checkExistingUsersEmail(
        userEmail: string,
        userId?: string
    ): Observable<{ isTaken: boolean }> {
        return this.http.post<{ isTaken: boolean }>(
            `${environment.api}/users/check-email`,
            {
                userEmail,
                id: userId,
            }
        );
    }
}
