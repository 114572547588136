import { ICompFile } from "./compFile";
import { Action, createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import * as CardActions from "../card/card.actions";
import * as CompFileActions from "./comp-file.actions";
import * as AuthActions from "src/app/auth/store/auth.actions";

export interface State extends EntityState<ICompFile> {
    selectedCategory: string | null;
    isUploadingCategory: string | null;
    categories: string[];
}

export const adapter: EntityAdapter<ICompFile> = createEntityAdapter<ICompFile>(
    {
        selectId: (file: ICompFile) => file._id,
        sortComparer: false,
    }
);

export const initialState: State = adapter.getInitialState({
    categories: [],
    selectedCategory: null,
    isUploadingCategory: null,
});

const compFileReducer = createReducer(
    initialState,
    on(CompFileActions.addCompFile, (state, action) =>
        adapter.addOne(action.compFile, state)
    ),
    on(CompFileActions.upsertCompFile, (state, action) =>
        adapter.upsertOne(action.compFile, state)
    ),
    // on(CompFileActions.addCompFiles, (state, action) =>
    //     adapter.addMany(action.compFiles, {
    //         ...state,
    //         isUploadingCategory: null,
    //     })
    // ),
    on(CompFileActions.upsertCompFiles, (state, action) =>
        adapter.upsertMany(action.compFiles, state)
    ),
    on(CompFileActions.updateCompFile, (state, action) =>
        adapter.updateOne(action.compFile, state)
    ),
    on(CompFileActions.updateCompFiles, (state, action) =>
        adapter.updateMany(action.compFiles, state)
    ),
    on(CompFileActions.deleteCompFile, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(CompFileActions.deleteCompFiles, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(CompFileActions.loadCompFilesSuccess, (state, action) =>
        adapter.upsertMany(action.compFiles, state)
    ),
    on(CompFileActions.clearCompFiles, AuthActions.logout, state =>
        adapter.removeAll(state)
    ),
    on(CardActions.getFullCardSuccess, (state, action) =>
        adapter.upsertMany(action.payload, state)
    ),
    on(CompFileActions.selectFileCategory, (state, { category }) => ({
        ...state,
        selectedCategory: category,
    })),
    on(CompFileActions.deSelectFileCategory, state => ({
        ...state,
        selectedCategory: null,
    })),
    on(CardActions.uploadFilesStart, (state, { payload: { category } }) => ({
        ...state,
        isUploadingCategory: category,
        selectedCategory: null,
    })),
    on(CardActions.uploadFilesFailed, state => ({
        ...state,
        isUploadingCategory: null,
        selectedCategory: null,
    })),
    on(CardActions.uploadFilesSuccess, (state, { files }) =>
        adapter.addMany(files, { ...state, isUploadingCategory: null })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return compFileReducer(state, action);
}

export const getSelectedCategory = (state: State) => state.selectedCategory;
export const getIsUploadingCategory = (state: State) =>
    state.isUploadingCategory;
