import {
    OnInit,
    Component,
    OnDestroy,
    ChangeDetectionStrategy,
} from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Payers } from "src/app/core/models/types";
import { MatDialogRef } from "@angular/material/dialog";
import { IBoard } from "src/app/core/store/board/board.model";
import { IColumn } from "src/app/core/store/column/column.model";
import { MatOptionSelectionChange } from "@angular/material/core";

import * as fromRoot from "src/app/reducers";
import * as fromBoard from "src/app/core/store/board";
import * as fromColumn from "src/app/core/store/column";

import * as ColumnActions from "src/app/core/store/column/column.actions";

@Component({
    selector: "app-import-dialog",
    templateUrl: "./import-dialog.component.html",
    styleUrls: ["./import-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDialogComponent implements OnInit, OnDestroy {
    boards$: Observable<IBoard[]> = this.store.pipe(
        select(fromBoard.selectBoards)
    );
    columns$: Observable<IColumn[]> | undefined;

    hasServiceWriter = false;

    selectedColumn: IColumn | undefined;

    insuranceForms = false;

    intakeLabel: string | undefined;
    columnId: string | undefined;
    boardId: string | undefined;
    form: FormGroup = new FormGroup({
        boardId: new FormControl("", Validators.required),
        columnId: new FormControl("", Validators.required),
    });
    payers = Payers;
    sub: Subscription;

    constructor(
        private dialogRef: MatDialogRef<ImportDialogComponent>,
        private store: Store<fromRoot.State>
    ) {}

    ngOnInit() {
        this.sub = this.form.get("boardId").valueChanges.subscribe(value => {
            if (value) {
                this.boardId = value;
                this.store.dispatch(
                    ColumnActions.getColumnsOfBoard({ boardId: value })
                );
                if (this.boardId) {
                    this.columns$ = this.store.pipe(
                        select(fromColumn.selectColumns),
                        map(cols =>
                            cols.filter(c => c.idBoard === this.boardId)
                        )
                    );
                }
            }
        });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    selectColumns(boardId: string) {
        this.store.dispatch(ColumnActions.getColumnsOfBoard({ boardId }));
    }

    selectColumn(event: MatOptionSelectionChange) {
        this.columnId = event.source.value._id;
        this.selectedColumn = event.source.value;
    }

    import() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
