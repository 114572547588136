import { Dictionary } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { selectBoardsState, State as RootState } from "src/app/reducers";
import * as fromRouter from "../router";
import { IUser } from "../user/user.model";
import * as fromUser from "../user/user.reducer";
import { IBoard } from "./board.model";
import * as fromBoard from "./board.reducer";

const { selectEntities, selectAll } = fromBoard.adapter.getSelectors(
    selectBoardsState
);

export const selectBoards = selectAll;
export const selectBoardEntities = selectEntities;
export const selectSelectedBoard = createSelector(
    selectBoardsState,
    fromBoard.getSelectedBoard
);

export const selectBoardById = createSelector(
    selectBoardEntities,
    (boards: Dictionary<IBoard>, props: { id: string }) => boards[props.id]
);

export const selectSelectedBoardFromRouter = createSelector(
    selectBoardsState,
    fromRouter.selectRouteParam("boardId"),
    fromBoard.getSelectedBoardFromRouter
);
export const selectIsLoading = createSelector(
    selectBoardsState,
    fromBoard.getIsLoading
);

export const selectDashboardHasLoaded = createSelector(
    selectBoardsState,
    fromBoard.getHasLoaded
);

export const getSelectedBoardDetails = createSelector(
    selectBoardsState,
    fromBoard.getSelectedBoardDetails
);

export const selectSelectedBoardIsLoading = createSelector(
    selectBoardsState,
    fromBoard.getSelectedBoardIsLoading
);

export const selectBoardHasLoaded = createSelector(
    selectBoardsState,
    fromBoard.getSelectedBoardHasLoaded
);

const userState = createFeatureSelector<fromUser.State>("users");
const selectLoggedInUser = createSelector<
    RootState,
    RootState["users"],
    IUser | undefined
>(userState, fromUser.getLoggedInUser);

export const selectBoardsOfUser = createSelector<
    RootState,
    Dictionary<IBoard>,
    IUser | undefined,
    IBoard[]
>(selectBoardEntities, selectLoggedInUser, (entities, user) =>
    user
        ? ((user.idBoards || [])
              .map(id => entities[id])
              .filter(b => !!b) as IBoard[])
        : ([] as IBoard[])
);
