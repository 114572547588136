import { HttpClient } from "@angular/common/http";
import { SwUpdate } from "@angular/service-worker";
import { Injectable, Optional } from "@angular/core";

import { environment } from "src/environments/environment";
import { TokenResponse } from "src/app/core/models/tokens";
import { TokenService } from "src/app/core/services/misc-services/token.storage";

import { Action, Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SocketService } from "src/app/core/services/socket.service";

import {
    tap,
    map,
    switchMap,
    concatMap,
    catchError,
    exhaustMap,
    withLatestFrom,
} from "rxjs/operators";
import { Observable, of } from "rxjs";

import * as fromAuth from "./auth.reducer";
import * as fromRoot from "src/app/reducers";

import * as AuthActions from "./auth.actions";
import * as UserActions from "src/app/core/store/user/user.actions";
import * as LayoutActions from "src/app/core/store/layout/layout.actions";
import * as RouterActions from "src/app/core/store/router/router.actions";
import * as InsurerActions from "src/app/core/store/insurer/insurer.actions";
import * as VehicleActions from "src/app/core/store/vehicle/vehicle.actions";
import * as DepActions from "src/app/core/store/department/department.actions";
import * as CustomerActions from "src/app/core/store/customer/customer.actions";
import * as ChecklistActions from "src/app/core/store/checklist/checklist.actions";
import * as NoteActions from "src/app/core/store/notification/notification.actions";
import * as SupplementActions from "src/app/core/store/supplement/supplement.actions";
import * as TemplateActions from "src/app/core/store/cardTemplate/card-template.actions";

@Injectable()
export class AuthEffects {
    OnAutoAuthFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.autoAuthFailed),
            map(() => RouterActions.Go({ payload: { path: ["login"] } }))
        )
    );

    OnSubmitRegistration: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.registerCompany),
            map(action => action.payload),
            exhaustMap(registration =>
                this.http
                    .post<TokenResponse>(
                        `${environment.api}/company/new`,
                        registration
                    )
                    .pipe(
                        map(({ user, tokens: { accessToken } }) => {
                            this.tokenService.saveToken(accessToken);
                            return user;
                        }),
                        concatMap(user => [
                            UserActions.setLoggedInUser({
                                id: user._id,
                                user,
                            }),
                            AuthActions.registerCompanySuccess(),
                        ]),
                        catchError(error =>
                            of(AuthActions.registerCompanyFailed({ error }))
                        )
                    )
            )
        )
    );

    OnAuthSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.loginSuccess,
                AuthActions.registerCompanySuccess
            ),
            withLatestFrom(this.store.select(s => s.router)),
            map(() => RouterActions.Go({ payload: { path: ["boards"] } }))
        )
    );

    OnSubmitLogin$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginStarted),
            exhaustMap(credentials =>
                this.http
                    .post<TokenResponse>(
                        `${environment.api}/users/authenticate`,
                        credentials
                    )
                    .pipe(
                        map(({ user, tokens: { accessToken } }) => {
                            this.tokenService.saveToken(accessToken);
                            return user;
                        }),
                        concatMap(user => [
                            UserActions.setLoggedInUser({
                                id: user._id,
                                user,
                            }),
                            LayoutActions.changeTheme({
                                theme: user.prefTheme || "green-dark",
                            }),
                            RouterActions.Go({ payload: { path: ["boards"] } }),
                        ]),
                        catchError(error =>
                            of(AuthActions.loginFailed({ error }))
                        )
                    )
            )
        )
    );

    CheckForUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.checkForUpdate),
                exhaustMap(() =>
                    this.pwa.available.pipe(map(() => window.location.reload()))
                )
            ),
        { dispatch: false }
    );

    OnLoadDashboard = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadDashboard),
            concatMap(({ redirectUrl }) => [
                RouterActions.Go({ payload: { path: [redirectUrl] } }),
            ])
        )
    );

    OnLoadEverything = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.setLoggedInUser),
            concatMap(() => [
                UserActions.loadUsers(),
                DepActions.loadDepartments(),
                VehicleActions.loadVehicles(),
                CustomerActions.loadCustomers(),
                InsurerActions.loadInsurers(),
                ChecklistActions.loadChecklists(),
                TemplateActions.loadCardTemplates(),
                SupplementActions.loadPendingSupplements(),
                NoteActions.loadUnreadNotifications({}),
            ])
        )
    );

    RedirectToLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            tap(() => this.tokenService.signOut()),
            switchMap(() => this.http.get(`${environment.api}/users/logout`)),
            concatMap(() => {
                this.socket.disconnect();
                return [
                    RouterActions.Go({
                        payload: {
                            path: ["login"],
                        },
                    }),
                    LayoutActions.changeTheme({ theme: "green-dark" }),
                ];
            })
        )
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        @Optional() private pwa: SwUpdate,
        private socket: SocketService,
        private store: Store<fromRoot.State & fromAuth.State>,
        private tokenService: TokenService
    ) {}
}
