import { InjectionToken } from "@angular/core";
import {
    Action,
    MetaReducer,
    ActionReducerMap,
    createSelector,
    ActionReducer,
} from "@ngrx/store";
import * as fromRouter from "@ngrx/router-store";
import { RouterStateUrl } from "../router.serializer";
import { environment } from "../../environments/environment";
import { storeFreeze } from "ngrx-store-freeze";

import * as fromLayout from "../core/store/layout";
import * as fromAuth from "../auth/store/auth.reducer";
import * as fromMain from "../main-board-shell/reducers";
import * as fromUser from "../core/store/user/user.reducer";

import * as AuthActions from "../auth/store/auth.actions";

export interface State {
    router: fromRouter.RouterReducerState<RouterStateUrl>;
    auth: fromAuth.State;
    users: fromUser.State;
    settings: fromLayout.State;
    app: fromMain.State;
}

function clearState<State extends {}>(
    reducer: ActionReducer<State>
): ActionReducer<State> {
    return function (state: State, action: Action) {
        if (action.type === AuthActions.logout.type) {
            state = {} as State;
        }
        return reducer(state, action);
    };
}

export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>("Root reducers token", {
    factory: () => ({
        router: fromRouter.routerReducer,
        users: fromUser.reducer,
        auth: fromAuth.authReducer,
        settings: fromLayout.reducer,
        app: fromMain.reducers,
    }),
});

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [storeFreeze, clearState]
    : [clearState];

export const selectTodosState = createSelector(
    fromMain.selectMainBoardState,
    state => state.todos
);
export const selectSupplementsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.supplements
);
export const selectCardsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.cards
);
export const selectFilesState = createSelector(
    fromMain.selectMainBoardState,
    state => state.files
);
export const selectBoardsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.boards
);
export const selectColumnsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.columns
);
export const selectActionsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.actions
);
export const selectDepartmentsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.departments
);
export const selectInsurersState = createSelector(
    fromMain.selectMainBoardState,
    state => state.insurers
);
export const selectVehiclesState = createSelector(
    fromMain.selectMainBoardState,
    state => state.vehicles
);
export const selectCommentsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.comments
);
export const selectNotificationsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.notifications
);
export const selectCustomersState = createSelector(
    fromMain.selectMainBoardState,
    state => state.customers
);
export const selectTemplatesState = createSelector(
    fromMain.selectMainBoardState,
    state => state.templates
);
export const selectChecklistsState = createSelector(
    fromMain.selectMainBoardState,
    state => state.checklists
);
