import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TokenService } from "../services/misc-services/token.storage";

interface ApiError {
    message: string;
    statusCode: number;
    status: string;
}
interface UIError {
    error: {
        message: string;
        stack: string;
    };
}

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
    isRefreshing = false;
    constructor(private tokenService: TokenService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const isApiUrl = req.url.startsWith("/api");
        req = req.clone({ withCredentials: true });
        if (this.tokenService.AccessToken && isApiUrl) {
            req = this.addToken(req, this.tokenService.AccessToken);
        }

        return next
            .handle(req)
            .pipe(
                catchError((err: HttpErrorResponse) => this.handleError(err))
            );
    }

    private handleError(response: HttpErrorResponse) {
        if (typeof response.error === "string") {
            return throwError(response.error);
        }
        const error: ApiError | UIError = response.error;
        if ("error" in error) {
            return throwError(error.error.message);
        } else if ("message" in error) {
            return throwError(error.message);
        }

        let errMsg: string;
        if (response && response.error && response.error.message)
            errMsg = response.error.message;
        else if (response.statusText)
            errMsg = `${response.statusText || "Network error"}`;
        else if (response.message) errMsg = response.message;
        else errMsg = response.error;
        return throwError(errMsg);
    }

    private addToken(req: HttpRequest<any>, token: string) {
        return req.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
        });
    }
}
