import { createSelector } from "@ngrx/store";
import * as fromDepartment from "./department.reducer";
import { selectDepartmentsState } from "src/app/reducers";

export const selectDepartments = createSelector(
    selectDepartmentsState,
    fromDepartment.selectAll
);

export const selectDepartmentEntities = createSelector(
    selectDepartmentsState,
    fromDepartment.selectEntities
);
export const selectSelectedDepartment = createSelector(
    selectDepartmentsState,
    fromDepartment.getSelectedDepartment
);
