import { NgModule } from "@angular/core";
import { TruncatePipe } from "./pipes/truncate/truncate.pipe";
import { UserSearchPipe } from "./pipes/search/user-search.pipe";
import { HoursLeftPipe } from "./pipes/card-pipes/hours-left.pipe";
import { CompletionPipe } from "./pipes/card-pipes/completion.pipe";
import { PaginationPipe } from "./pipes/pagination/pagination.pipe";
import { InsurerSearchPipe } from "./pipes/search/insurer-search.pipe";
import { SupplementsPipe } from "./pipes/supplements/supplements.pipe";
import { SplitStringPipe } from "./pipes/vehicle-pipes/split-key.pipe";
import { ReversePipe } from "./pipes/reverse-array/reverse-array.pipe";
import { BytesToSizePipe } from "./pipes/file-pipes/bytes-to-size.pipe";
import { CategoryNamePipe } from "./pipes/file-pipes/category-name.pipe";
import { SanitizeHtmlPipe } from "./pipes/sanitize-pipe/sanitize-html.pipe";
import { StatusColorPipe } from "./pipes/status-color-pipe/status-color.pipe";
import { CardStatusTextPipe } from "./pipes/card-pipes/card-status-text.pipe";
import { CardStatusColorPipe } from "./pipes/card-pipes/card-status-color.pipe";
import { ProfilePicturePipe } from "./pipes/profilePicture/profilePicture.pipe";
import { PreferredPhonePipe } from "./pipes/insurer-pipes/preferred-phone.pipe";
import { PreferredEmailPipe } from "./pipes/insurer-pipes/preferred-email.pipe";
import { ChecklistProgressPipe } from "./pipes/card-pipes/checklist-progress.pipe";
import { RemainingPipe } from "./pipes/column-pipes/remaining-hours/remaining.pipe";
import { LoadColorPipe } from "./pipes/column-pipes/load-color-pipe/load-color.pipe";
import { FormatPhoneNumberPipe } from "./pipes/format-phone/format-phone-number.pipe";
import { CalculateLoadPipe } from "./pipes/column-pipes/calculate-load/calculate-load.pipe";
import { SupplementStatusColorPipe } from "./pipes/supplements/supplement-status-color.pipe";
import { TotalChecklistProgressPipe } from "./pipes/card-pipes/total-checklist-progress.pipe";
import { TargetDatePipe } from "./pipes/card-pipes/target-date.pipe";
import { SortCardsPipe } from "./pipes/column-pipes/sort-cards.pipe";

const PIPES = [
    ReversePipe,
    TruncatePipe,
    HoursLeftPipe,
    LoadColorPipe,
    RemainingPipe,
    PaginationPipe,
    CompletionPipe,
    UserSearchPipe,
    BytesToSizePipe,
    SplitStringPipe,
    SupplementsPipe,
    StatusColorPipe,
    CategoryNamePipe,
    SanitizeHtmlPipe,
    CalculateLoadPipe,
    InsurerSearchPipe,
    CardStatusTextPipe,
    PreferredPhonePipe,
    ProfilePicturePipe,
    PreferredEmailPipe,
    CardStatusColorPipe,
    FormatPhoneNumberPipe,
    ChecklistProgressPipe,
    SupplementStatusColorPipe,
    TotalChecklistProgressPipe,
];

@NgModule({
    declarations: [...PIPES, TargetDatePipe, SortCardsPipe],
    exports: [...PIPES, TargetDatePipe, SortCardsPipe],
})
export class PipesModule {}
