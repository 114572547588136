import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as LayoutActions from "src/app/core/store/layout/layout.actions";
import * as fromRoot from "src/app/reducers";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
})
export class NotFoundComponent implements AfterViewInit {
    constructor(public router: Router, private store: Store<fromRoot.State>) {}

    public goHome(): void {
        this.router.navigate(["/boards"]);
    }

    ngAfterViewInit() {
        this.store.dispatch(LayoutActions.endSpinner());
    }
}
