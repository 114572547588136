import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as BoardActions from "../board/board.actions";
import * as CardActions from "../card/card.actions";
import * as SockActions from "../company/socket.actions";
import * as ColumnActions from "./column.actions";
import { IColumn as Column } from "./column.model";

export type State = EntityState<Column>;

export const adapter: EntityAdapter<Column> = createEntityAdapter<Column>({
    selectId: (column: Column) => column._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const columnReducer = createReducer(
    initialState,
    on(ColumnActions.addColumn, SockActions.addColumn, (state, action) =>
        adapter.addOne(action.column, state)
    ),
    on(ColumnActions.upsertColumns, (state, action) =>
        adapter.upsertMany(action.columns, state)
    ),
    on(
        CardActions.addCard,
        SockActions.addCard,
        (state, { card: { _id, idColumn } }) => {
            const col = state.entities[idColumn];
            if (col && col.cards) {
                return adapter.updateOne(
                    {
                        changes: {
                            cards: [...col.cards, _id],
                        },
                        id: idColumn,
                    },
                    state
                );
            } else {
                return state;
            }
        }
    ),

    on(ColumnActions.updateColumn, SockActions.updateColumn, (state, action) =>
        adapter.updateOne(action.column, state)
    ),
    on(
        ColumnActions.updateColumns,
        SockActions.updateColumns,
        (state, action) => adapter.updateMany(action.columns, state)
    ),
    on(ColumnActions.deleteColumn, SockActions.deleteColumn, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(ColumnActions.loadColumns, (state, action) =>
        adapter.upsertMany(action.columns, state)
    ),
    on(ColumnActions.clearColumns, state => adapter.removeAll(state)),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    ),
    on(BoardActions.moveCard, SockActions.onCardMove, (state, { columns }) =>
        adapter.updateMany(columns, state)
    ),
    on(BoardActions.moveCardFailed, (state, { oldState: { columns } }) =>
        adapter.updateMany(columns, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return columnReducer(state, action);
}
