import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "@angular/cdk/layout";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatChipsModule } from "@angular/material/chips";
import { MatBadgeModule } from "@angular/material/badge";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSliderModule } from "@angular/material/slider";
import { MatButtonModule } from "@angular/material/button";
import { MatStepperModule } from "@angular/material/stepper";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
    exports: [
        FormsModule,
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,

        LayoutModule,
        MatCardModule,
        MatListModule,
        MatIconModule,
        MatSortModule,
        MatTabsModule,
        MatMenuModule,
        DragDropModule,
        MatInputModule,
        MatBadgeModule,
        MatChipsModule,
        MatRadioModule,
        MatTableModule,
        ScrollingModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatRippleModule,
        MatSliderModule,
        MatStepperModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDividerModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatGridListModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
    ],
})
export class RprSageCommonModule {}
