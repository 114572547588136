import { ICard } from "./card.model";
import { Update } from "@ngrx/entity";
import { ICompFile } from "../files/compFile";
import { createAction, props } from "@ngrx/store";
import { IVehicle } from "../vehicle/vehicle.model";
import { ICustomer } from "../customer/customer.types";
import { FileCategory } from "src/app/core/models/types";
import { ISupplement } from "../supplement/supplement.model";

export const archiveCard = createAction(
    "[Card/API] Archive Card",
    props<{ cardId: string; columnId: string }>()
);
export const archiveCardSuccess = createAction(
    "[Card/API] Archive Card Success",
    props<{ columnId: string; cardId: string }>()
);
export const archiveCardFailed = createAction(
    "[Card/API] Archive Card Failed",
    props<{ error: string }>()
);

export const unArchiveCardDialog = createAction(
    "[Card] Open UnArchive Card Dialog",
    props<{ cardId: string }>()
);
export const unArchiveCardDismiss = createAction(
    "[Card] Dismiss UnArchive Card Dialog"
);

export const unArchiveCard = createAction(
    "[Card/API] UnArchive Card",
    props<{ cardId: string; columnId: string; boardId: string }>()
);

export const unArchiveCardSuccess = createAction(
    "[Card/API] UnArchive Card Success",
    props<{ columnId: string; cardId: string; boardId: string }>()
);

export const unArchiveCardFailed = createAction(
    "[Card/API] UnArchive Card Failed",
    props<{ error: string }>()
);

export const getFullCard = createAction(
    "[Card/API] Get the full card started",
    props<{ cardId: string }>()
);
export const getFullCardSuccess = createAction(
    "[Card/API] Get full card Successful",
    props<{ payload: any }>()
);

export const createCard = createAction(
    "[Card/API] Creating a new Card",
    props<{
        payload: {
            customer: ICustomer;
            card: ICard;
            checklists: string[];
            vehicle: IVehicle;
        };
    }>()
);
export const createCardSuccess = createAction(
    "[Card/API] Created a new Card",
    props<{
        payload: {
            card: ICard;
            vehicle: IVehicle;
            customer: ICustomer;
        };
    }>()
);

export const uploadWorkOderStart = createAction(
    "[Card/API] Upload work order Started",
    props<{
        payload: {
            file: File;
            cardId: string;
        };
    }>()
);

export const uploadFilesStart = createAction(
    "[Card/API] Upload files started",
    props<{
        payload: {
            files: File[];
            cardId: string;
            category: FileCategory;
        };
    }>()
);

export const uploadFilesSuccess = createAction(
    "[Card/API] Uploaded Files Successfully",
    props<{ files: ICompFile[] }>()
);
export const uploadFilesFailed = createAction(
    "[Card/API] Uploaded Files Failed!",
    props<{ error: string }>()
);

export const deleteFileFailed = createAction(
    "[File/API] Delete Failure",
    props<{ error: string }>()
);

export const addInitialCardHours = createAction(
    "[Card/API] Add initialHours",
    props<{ card: Update<ICard> }>()
);
export const updateCardWithoutAPI = createAction(
    "[Card] Update Card Without API",
    props<{ card: Update<ICard> }>()
);
export const createSupplement = createAction(
    "[SUPPLEMENT_ACTION]",
    props<{
        payload: {
            serviceWriterId: string;
            image: File;
            cardId: string;
        };
    }>()
);

export const createSupplementSuccess = createAction(
    "[Supplement/API] Supplement Created with Success",
    props<{ supplement: ISupplement }>()
);
export const createSupplementFailed = createAction(
    "[Supplement/API] Supplement Failure",
    props<{ error: string }>()
);

export const deselectFullCard = createAction("[Card] DeSelect");
export const navigateToCard = createAction(
    "[Card] Navigate To Card",
    props<{
        payload: {
            cardId: string;
            cardRo: string;
        };
    }>()
);

export const loadCardsSuccess = createAction(
    "[Card/API] Load Cards Success",
    props<{ cards: ICard[] }>()
);

export const addCard = createAction(
    "[Card/API] Add Card",
    props<{ card: ICard }>()
);

export const upsertCard = createAction(
    "[Card/API] Upsert Card",
    props<{ card: ICard }>()
);

export const addCards = createAction(
    "[Card/API] Add Cards",
    props<{ cards: ICard[] }>()
);

export const upsertCards = createAction(
    "[Card/API] Upsert Cards",
    props<{ cards: ICard[] }>()
);

export const updateCardEntity = createAction(
    "[Card/API] Update Card",
    props<{ card: Update<ICard> }>()
);

export const updateCards = createAction(
    "[Card/API] Update Cards",
    props<{ cards: Update<ICard>[] }>()
);

export const deleteCardEntity = createAction(
    "[Card/API] Delete Card",
    props<{ id: string }>()
);

export const deleteCards = createAction(
    "[Card/API] Delete Cards",
    props<{ ids: string[] }>()
);

export const updateCardStatus = createAction(
    "[Card] Update Status",
    props<{ status: "idle" | "loading" | "loaded" }>()
);

export const clearCards = createAction("[Card/API] Clear Cards");
export const loadComplete = createAction("[Card/API] Load Full Complete");
export const openPdfDialog = createAction(
    "[Card] Open Pdf Dialog",
    props<{ url: string }>()
);

export const normalizeCardHours = createAction(
    "[Card/API] Normalize Card Hours"
);
