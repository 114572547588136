import { Subscription } from "rxjs";
import { select, Store } from "@ngrx/store";
import { ToastrMsgs } from "../../models/toast";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { concatMap, filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { IUser } from "../../store/user/user.model";
import { IAction } from "../../store/action/action.model";

import * as fromRoot from "src/app/reducers";
import * as fromUser from "src/app/core/store/user";

import * as CardActions from "src/app/core/store/card/card.actions";
import * as ActionActions from "src/app/core/store/action/action.actions";
import * as LayoutActions from "src/app/core/store/layout/layout.actions";

@Injectable({ providedIn: "root" })
export class ActionEffects implements OnDestroy {
    user: IUser | undefined;
    sub$: Subscription;
    onCreateCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.createCardSuccess),
            filter(() => !!this.user),
            concatMap(({ payload }) =>
                this.http
                    .post<IAction>(`${environment.api}/actions/new`, {
                        content: "Created this card",
                        idCard: payload.card._id,
                        idCompany: payload.card.idCompany,
                        idUser: this.user?._id,
                    })
                    .pipe(
                        map(() =>
                            LayoutActions.showToastr({
                                payload: {
                                    body: ToastrMsgs.CREATE_CARD(
                                        payload.card.ro
                                    ),
                                    title: "",
                                    type: "success",
                                },
                            })
                        )
                    )
            )
        )
    );

    onFilesUploaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CardActions.uploadFilesSuccess),
            filter(() => !!this.user),
            concatMap(({ files }) => {
                return this.http
                    .post<IAction>(`${environment.api}/actions/new`, {
                        content: `Added ${files.length} ${
                            files.length > 1 ? "files" : "file"
                        }.`,
                        idCard: files[0].idCard,
                        idUser: this.user?._id,
                    })
                    .pipe(
                        map(() =>
                            LayoutActions.showToastr({
                                payload: {
                                    body: ToastrMsgs.ADD_ATTACHMENT(
                                        files.length
                                    ),
                                    title: "",
                                    type: "success",
                                },
                            })
                        )
                    );
            })
        )
    );

    onUpdateWorkOrder(cardId: string): void {
        if (this.user) {
            const newAction: Partial<IAction> = {
                idCard: cardId,
                idUser: this.user._id,
                content: "Updated the work order.",
            };
            this.http
                .post<IAction>(`${environment.api}/actions/new`, newAction)
                .subscribe(action => {
                    this.store.dispatch(ActionActions.addAction({ action })),
                        this.store.dispatch(
                            LayoutActions.showToastr({
                                payload: {
                                    body: ToastrMsgs.UPDATED_WORK_ORDER(),
                                    title: "",
                                    type: "success",
                                },
                            })
                        );
                });
        }
    }

    constructor(
        private store: Store<fromRoot.State>,
        private http: HttpClient,
        private actions$: Actions
    ) {
        this.sub$ = this.store
            .pipe(
                select(fromUser.selectLoggedInUser),
                map(user => (this.user = user))
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.sub$.unsubscribe();
    }
}
