import { Pipe, PipeTransform } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { IUser } from "../../store/user/user.model";

@Pipe({ name: "pagination" })
export class PaginationPipe implements PipeTransform {
    transform(data: IUser[], args?: PageEvent): Array<IUser> {
        if (!args) {
            args = {
                pageIndex: 0,
                pageSize: 6,
                length: data.length,
            };
        }
        return this.paginate(data, args.pageSize, args.pageIndex);
    }

    paginate(array: IUser[], page_size: number, page_number: number): IUser[] {
        return array.slice(
            page_number * page_size,
            (page_number + 1) * page_size
        );
    }
}
