import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    OnDestroy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import * as fromUser from "src/app/core/store/user";
import * as UserActions from "src/app/core/store/user/user.actions";
import * as fromRoot from "src/app/reducers";
import { WarningDialogComponent } from "src/app/universal-dialogs/warning-dialog/warning-dialog.component";
import { UserFormService } from "../../services/user-services/user-form.service";
import { IUser } from "../../store/user/user.model";

@Component({
    selector: "app-user-preferences",
    providers: [UserFormService],
    templateUrl: "./user-preferences.component.html",
    styleUrls: ["./user-preferences.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferencesComponent implements OnDestroy {
    submitButtonText = "Close";

    changePassword = false;

    user: FormGroup | undefined;

    changesSub$: Subscription | undefined;

    loggedInUser: IUser | undefined;

    user$: Observable<IUser | undefined> = this.store.pipe(
        select(fromUser.selectLoggedInUser),
        tap(user => {
            if (user) {
                this.loggedInUser = user;
                this.user = this.userFormService.EditCurrentUserForm(user);
                this.changesSub$ = this.user.valueChanges.subscribe(() => {
                    if (this.user) {
                        if (!this.user.pristine) {
                            this.dialogRef.disableClose = true;
                            this.unsubscribe();
                        }
                    }
                });
                this.sub = this.dialogRef
                    .backdropClick()
                    .subscribe(() => this.backdropClicked());
            }
        })
    );

    sub: Subscription | undefined;

    hide = true;

    constructor(
        public dialogRef: MatDialogRef<UserPreferencesComponent>,
        private userFormService: UserFormService,
        private store: Store<fromRoot.State>,
        private dialog: MatDialog
    ) {}

    ngOnDestroy() {
        if (this.changesSub$) {
            if (!this.changesSub$.closed) this.changesSub$.unsubscribe();
        }
        if (this.sub) {
            if (!this.sub.closed) this.sub.unsubscribe();
        }
    }

    @HostListener("window:keydown.escape", ["$event"]) keyEvent(
        event: KeyboardEvent
    ) {
        if (this.user && !this.user.pristine) event.stopPropagation();
    }

    togglePasswordForm() {
        if (this.password && this.confirm) {
            if (this.changePassword) {
                this.password.disable();
                this.confirm.disable();
            } else {
                this.password.enable();
                this.confirm.enable();
            }
            this.changePassword = !this.changePassword;
        }
    }

    compareWithFn(a: any, b: any) {
        return a && b ? a === b : a._id === b._id;
    }

    unsubscribe() {
        if (this.changesSub$) {
            this.changesSub$.unsubscribe();
        }
    }

    backdropClicked() {
        if (this.user && !this.user.pristine) {
            this.dialogRef.disableClose = true;
            const buttonText = "No";
            const optionalButtonText = "Yes";
            const message = "All changes will be lost!";
            this.dialog
                .open(WarningDialogComponent, {
                    data: {
                        buttonText,
                        message,
                        optionalButtonText,
                    },
                })
                .afterClosed()
                .subscribe(confirmed => confirmed && this.dialog.closeAll());
        }
    }

    get avatar() {
        return this.user?.get("avatar")?.value;
    }

    get phone() {
        return this.user?.get("phone");
    }

    get email() {
        return this.user?.get("email");
    }

    get password() {
        return this.user?.get("password");
    }

    get confirm() {
        return this.user?.get("confirm");
    }

    get emailNotes() {
        return this.user?.get("prefs")?.get("email");
    }

    get pushNotes() {
        return this.user?.get("prefs")?.get("push");
    }

    get u() {
        return this.user?.controls;
    }

    save() {
        if (
            this.user &&
            this.user.valid &&
            this.user.dirty &&
            this.loggedInUser
        ) {
            const user: IUser = this.user.value;
            const id = user._id;
            if (this.changePassword && this.password) {
                const password = this.password.value;
                this.store.dispatch(
                    UserActions.changePassword({
                        password,
                        id,
                    })
                );
                delete user.password;
            }
            const changes = this.userFormService.DetectChanges(
                this.user,
                this.loggedInUser
            );
            if (Object.keys(changes).length) {
                this.store.dispatch(
                    UserActions.updateUser({
                        user: {
                            changes,
                            id,
                        },
                    })
                );
            }
            this.dialogRef.close();
        } else if (this.user && this.user.valid && !this.user.dirty) {
            this.dialogRef.close();
        }
    }

    reSendEmailVerification(e: Event) {
        e.preventDefault();
        this.store.dispatch(UserActions.resendEmailVerification());
    }

    reSendPhoneVerification(e: Event) {
        e.preventDefault();
        this.store.dispatch(UserActions.resendPhoneVerification());
    }
}
