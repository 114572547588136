import { Pipe, PipeTransform } from "@angular/core";
import { IEmail } from "../../store/communication/communications.types";

@Pipe({
    name: "preferredEmail",
})
export class PreferredEmailPipe implements PipeTransform {
    transform(emails: IEmail[]): any {
        if (emails.length) {
            const index = emails.findIndex(e => e.preferred);
            return index !== -1 ? emails[index].email : emails[0].email;
        }
    }
}
