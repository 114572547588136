import { Injectable } from "@angular/core";
import { switchMap, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as CustomerActions from "./customer.actions";
import { ICustomer } from "./customer.types";

@Injectable()
export class CustomerEffects {
    LoadCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerActions.loadCustomers),
            switchMap(() =>
                this.http
                    .get<ICustomer[]>(`${environment.api}/cust/`)
                    .pipe(
                        map(customers =>
                            CustomerActions.loadCustomersSuccess({ customers })
                        )
                    )
            )
        )
    );

    constructor(private actions$: Actions, private http: HttpClient) {}
}
