import { Injectable } from "@angular/core";
import { switchMap, map } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TodoActions from "./todo.actions";
import { TodoService } from "../../services/misc-services/todo.service";

@Injectable()
export class TodoEffects {
    LoadTodos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TodoActions.loadTodos),
            switchMap(() =>
                this.todoService
                    .getTodos()
                    .pipe(map(todos => TodoActions.loadTodosSuccess({ todos })))
            )
        )
    );

    NewTodo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TodoActions.createTodo),
            switchMap(({ todo }) =>
                this.todoService
                    .createTodo(todo)
                    .pipe(
                        map(newTodo => TodoActions.addTodo({ todo: newTodo }))
                    )
            )
        )
    );

    DeleteTodo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TodoActions.deleteTodo),
                switchMap(({ id }) => this.todoService.deleteTodo(id))
            ),
        { dispatch: false }
    );

    UpdateTodo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TodoActions.updateTodo),
                switchMap(({ todo }) =>
                    this.todoService.updateTodo(todo.id as string, {
                        ...todo.changes,
                    })
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private todoService: TodoService) {}
}
