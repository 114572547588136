import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { map, take } from "rxjs/operators";

import * as fromUser from "src/app/core/store/user";
import * as fromRoot from "src/app/reducers";
import * as RouterActions from "src/app/core/store/router/router.actions";

@Injectable({ providedIn: "root" })
export class LoginGuard implements CanActivate {
    constructor(private store: Store<fromRoot.State>) {}

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromUser.selectIsLoggedIn),
            map(isLoggedIn => {
                if (!isLoggedIn && localStorage.getItem("AuthToken") !== null) {
                    this.store.dispatch(
                        RouterActions.Go({
                            payload: {
                                path: ["boards"],
                            },
                        })
                    );
                }
                return true;
            }),
            take(1)
        );
    }
}
