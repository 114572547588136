import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IAction } from "./action.model";

export const loadActions = createAction("[Action/API] Load Actions");
export const loadActionsSuccess = createAction(
    "[Action/API] Load Actions Success",
    props<{ actions: IAction[] }>()
);

export const addAction = createAction(
    "[Action/API] Add Action",
    props<{ action: IAction }>()
);

export const upsertAction = createAction(
    "[Action/API] Upsert Action",
    props<{ action: IAction }>()
);

export const addActions = createAction(
    "[Action/API] Add Actions",
    props<{ actions: IAction[] }>()
);

export const upsertActions = createAction(
    "[Action/API] Upsert Actions",
    props<{ actions: IAction[] }>()
);

export const updateAction = createAction(
    "[Action/API] Update Action",
    props<{ action: Update<IAction> }>()
);

export const updateActions = createAction(
    "[Action/API] Update Actions",
    props<{ actions: Update<IAction>[] }>()
);

export const deleteAction = createAction(
    "[Action/API] Delete Action",
    props<{ id: string }>()
);

export const deleteActions = createAction(
    "[Action/API] Delete Actions",
    props<{ ids: string[] }>()
);

export const clearActions = createAction("[Action/API] Clear Actions");
