import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "statusColor" })
export class StatusColorPipe implements PipeTransform {
    transform(value: any): string {
        switch (value) {
            case "On Hold":
                return "red";
            case "Green Light":
                return "green";
            case "Scheduled In":
                return "blue";
            case "Waiting on Parts":
                return "orange";
            case "Other":
                return "purple";
            default:
                return "";
        }
    }
}
