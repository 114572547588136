<h2 mat-dialog-title>Move Card</h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <div class="row">
            <mat-form-field class="w-100">
                <mat-label>Choose a Board</mat-label>
                <mat-select formControlName="boardId" required>
                    <mat-option
                        [value]="board._id"
                        *ngFor="let board of boards$ | async"
                    >
                        {{ board.title | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('boardId').hasError('required')"
                    >A board is required</mat-error
                >
            </mat-form-field>
        </div>
        <div class="row" *ngIf="columns$ | async as columns">
            <mat-form-field class="w-100">
                <mat-label>Choose a Column</mat-label>
                <mat-select formControlName="columnId" required>
                    <mat-option [value]="col._id" *ngFor="let col of columns">
                        {{ col.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('columnId').hasError('required')"
                    >A column is required</mat-error
                >
            </mat-form-field>
        </div>
    </form>
</div>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="import()">Submit</button>
    <button mat-raised-button color="accent" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
