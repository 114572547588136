import { createSelector } from "@ngrx/store";
import * as fromNote from "./notification.reducer";
import { selectNotificationsState } from "src/app/reducers";

export const selectNotifications = createSelector(
    selectNotificationsState,
    fromNote.selectAll
);
export const selectAllLoaded = createSelector(
    selectNotificationsState,
    fromNote.getAllLoaded
);
export const selectTotalUnreadCount = createSelector(
    selectNotificationsState,
    fromNote.getUnreadCount
);

export const selectCurrentUnreadCount = createSelector(
    selectNotifications,
    notifications => {
        return notifications.map(n => n.unread).length;
    }
);

export const selectIsLoading = createSelector(
    selectNotificationsState,
    fromNote.getIsLoading
);
export const selectLimit = createSelector(
    selectNotificationsState,
    fromNote.getLimit
);
export const selectPage = createSelector(
    selectNotificationsState,
    fromNote.getPage
);
export const selectTotalCount = createSelector(
    selectNotificationsState,
    fromNote.getTotal
);
