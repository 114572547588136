import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IUser as User } from "./user.model";

export const loadUsers = createAction("[User/API] Load Users");
export const loadUsersSuccess = createAction(
    "[User/API] Load Users Success",
    props<{ users: User[] }>()
);

export const loginUser = createAction(
    "[User/API] Logged in User",
    props<{ id: string }>()
);
export const addUser = createAction(
    "[User/API] Add User",
    props<{ user: User }>()
);

export const upsertUser = createAction(
    "[User/API] Upsert User",
    props<{ user: User }>()
);

export const addUsers = createAction(
    "[User/API] Add Users",
    props<{ users: User[] }>()
);

export const upsertUsers = createAction(
    "[User/API] Upsert Users",
    props<{ users: User[] }>()
);

export const updateUser = createAction(
    "[User/API] Update User",
    props<{ user: Update<User> }>()
);

export const updateUsers = createAction(
    "[User/API] Update Users",
    props<{ users: Update<User>[] }>()
);

export const deleteUser = createAction(
    "[User/API] Delete User",
    props<{ id: string }>()
);

export const deleteUsers = createAction(
    "[User/API] Delete Users",
    props<{ ids: string[] }>()
);

export const clearUsers = createAction("[User/API] Clear Users");

export const createNewEmployee = createAction(
    "[User/API] Create User",
    props<{ user: Partial<User> }>()
);

export const selectUser = createAction(
    "[User] Set Selected User Id",
    props<{ id: string }>()
);
export const deSelectUser = createAction("[User] DeSelect Selected User Id");
export const changePassword = createAction(
    "[User] Changed Password",
    props<{ password: string; id: string }>()
);

export const setLoggedInUser = createAction(
    "[User/API] Set Logged In User",
    props<{ id: string; user?: User }>()
);
export const resendEmailVerification = createAction(
    "[User/API] Resend Email Verification"
);
export const resendPhoneVerification = createAction(
    "[User/API] Resend Phone Verification"
);
