export const TOASTR = "TOASTR";

export const UPDATE_BOARD = "UPDATE_BOARD";

export const CREATE_CARD = "CREATE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const ON_MOVED_CARD = "ON_MOVED_CARD";

export const DELETE_COLUMN = "DELETE_COLUMN";
export const UPDATE_COLUMN = "UPDATE_COLUMN";
export const CREATE_COLUMN = "CREATE_COLUMN";
export const UPDATE_COLUMNS = "UPDATE_COLUMNS";

export const FILE_UPLOADED = "FILE_UPLOADED";
export const FILES_REMOVED = "FILES_REMOVED";
export const FILES_UPLOADED = "FILES_UPLOADED";

export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const CUSTOMER_CREATED = "CUSTOMER_CREATED";

export const TEMPLATE_UPDATED = "TEMPLATE_UPDATED";
export const TEMPLATE_CREATED = "TEMPLATE_CREATED";
export const TEMPLATE_DELETED = "TEMPLATE_DELETED";

export const CHECKLIST_UPDATED = "CHECKLIST_UPDATED";
export const CHECKLIST_CREATED = "CHECKLIST_CREATED";
export const CHECKLIST_DELETED = "CHECKLIST_DELETED";

export const SUPPLEMENT_UPDATED = "SUPPLEMENT_UPDATED";
export const SUPPLEMENT_CREATED = "SUPPLEMENT_CREATED";
export const SUPPLEMENT_DELETED = "SUPPLEMENT_DELETED";

export const USER_UPDATED = "USER_UPDATED";
export const USER_CREATED = "USER_CREATED";

export const VEHICLE_UPDATED = "VEHICLE_UPDATED";
export const VEHICLE_CREATED = "VEHICLE_CREATED";

export const DEPARTMENT_UPDATED = "DEPARTMENT_UPDATED";

export const ON_COMMENT = "ON_COMMENT";
export const ON_COMMENT_SPECIFIED = "ON_COMMENT_SPECIFIED";
