import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { ICompFile } from "./compFile";

export const loadCompFiles = createAction("[CompFile/API] Load CompFiles");
export const loadCompFilesSuccess = createAction(
    "[CompFile/API] Load CompFiles Success",
    props<{ compFiles: ICompFile[] }>()
);

export const addCompFile = createAction(
    "[CompFile/API] Add CompFile",
    props<{ compFile: ICompFile }>()
);

export const upsertCompFile = createAction(
    "[CompFile/API] Upsert CompFile",
    props<{ compFile: ICompFile }>()
);

export const addCompFiles = createAction(
    "[CompFile/API] Add CompFiles",
    props<{ compFiles: ICompFile[] }>()
);

export const upsertCompFiles = createAction(
    "[CompFile/API] Upsert CompFiles",
    props<{ compFiles: ICompFile[] }>()
);

export const updateCompFile = createAction(
    "[CompFile/API] Update CompFile",
    props<{ compFile: Update<ICompFile> }>()
);

export const updateCompFiles = createAction(
    "[CompFile/API] Update CompFiles",
    props<{ compFiles: Update<ICompFile>[] }>()
);

export const deleteCompFile = createAction(
    "[CompFile/API] Delete CompFile",
    props<{ id: string }>()
);

export const deleteCompFiles = createAction(
    "[CompFile/API] Delete CompFiles",
    props<{ ids: string[] }>()
);

export const clearCompFiles = createAction("[CompFile/API] Clear CompFiles");
export const selectFileCategory = createAction(
    "[CompFile/API] Select Category",
    props<{ category: string }>()
);
export const deSelectFileCategory = createAction(
    "[CompFile/API] De Select Category"
);

export const addCategory = createAction(
    "[CompFile] Add Category",
    props<{ name: string }>()
);
