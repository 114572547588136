import { createSelector } from "@ngrx/store";
import * as fromChecklist from "./checklist.reducer";
import { selectChecklistsState } from "src/app/reducers";

export const { selectEntities, selectAll } = fromChecklist.adapter.getSelectors(
    selectChecklistsState
);
export const selectChecklists = selectAll;
export const selectChecklistEntities = selectEntities;
export const selectSelectedChecklist = createSelector(
    selectChecklistsState,
    fromChecklist.getSelectedChecklist
);
