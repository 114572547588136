import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ToastPackage, ToastrService, Toast } from "ngx-toastr";

@Component({
    selector: "app-toastr",
    template: `
        <div class="col-9">
            <div
                *ngIf="title"
                [class]="options.titleClass"
                [attr.aria-label]="title"
            >
                {{ title }}
            </div>
            <div
                *ngIf="message && options.enableHtml"
                role="alert"
                aria-live="polite"
                [class]="options.messageClass"
                [innerHTML]="message"
            ></div>
            <div
                *ngIf="message && !options.enableHtml"
                role="alert"
                aria-live="polite"
                [class]="options.messageClass"
                [attr.aria-label]="message"
            >
                {{ message }}
            </div>
        </div>
        <div class="col-3 text-right">
            <button
                mat-stroked-button
                color="warn"
                *ngIf="options.closeButton"
                (click)="remove()"
            >
                close
            </button>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                position: relative;
                overflow: hidden;
                margin: 0 0 6px;
                padding: 10px 10px 10px 10px;
                width: 300px;
                border-radius: 3px 3px 3px 3px;
                color: #ffffff;
                pointer-events: all;
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RprSageToastrComponent extends Toast {
    undoString = "undo";

    // constructor is only necessary when not using AoT
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.undoString = "undid";
        this.toastPackage.triggerAction();
        return false;
    }
}
