import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as TodoActions from "./todo.actions";
import * as AuthActions from "src/app/auth/store/auth.actions";
import { ITodo as Todo } from "./todo.model";

export type State = EntityState<Todo>;

export const adapter: EntityAdapter<Todo> = createEntityAdapter<Todo>({
    selectId: (todo: Todo) => todo._id,
    sortComparer: (a: Todo, b: Todo) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const todoReducer = createReducer(
    initialState,
    on(TodoActions.addTodo, (state, action) =>
        adapter.addOne(action.todo, state)
    ),
    on(TodoActions.upsertTodo, (state, action) =>
        adapter.upsertOne(action.todo, state)
    ),
    on(TodoActions.addTodos, (state, action) =>
        adapter.addMany(action.todos, state)
    ),
    on(TodoActions.upsertTodos, (state, action) =>
        adapter.upsertMany(action.todos, state)
    ),
    on(TodoActions.updateTodo, (state, action) =>
        adapter.updateOne(action.todo, state)
    ),
    on(TodoActions.updateTodos, (state, action) =>
        adapter.updateMany(action.todos, state)
    ),
    on(TodoActions.deleteTodo, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(TodoActions.deleteTodos, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(TodoActions.loadTodosSuccess, (state, action) =>
        adapter.upsertMany(action.todos, state)
    ),
    on(TodoActions.clearTodos, AuthActions.logout, state =>
        adapter.removeAll(state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return todoReducer(state, action);
}
