import { Pipe, PipeTransform } from "@angular/core";
import { IUser } from "../../store/user/user.model";

@Pipe({ name: "UserSearchPipe" })
export class UserSearchPipe implements PipeTransform {
    transform(value: IUser[], args?: string): Array<IUser> {
        if (args) {
            const searchText = new RegExp(args, "ig");
            if (value) {
                return value.filter(
                    u =>
                        u.firstName &&
                        u.lastName &&
                        (u.firstName.search(searchText) !== -1 ||
                            u.lastName.search(searchText) !== -1)
                );
            }
        }
        return value;
    }
}
