import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromBoard from "../board";
import * as fromCard from "../card";
import * as fromColumn from "../column";
import * as fromUser from "./user.reducer";

const selectUserState = createFeatureSelector<fromUser.State>("users");

const { selectEntities, selectAll, selectIds } = fromUser.adapter.getSelectors(
    selectUserState
);
export const selectUsers = selectAll;

export const selectUserEntities = selectEntities;
export const selectUserIds = selectIds;
export const selectSelectedUser = createSelector(
    selectUserState,
    fromUser.getSelectedUser
);
export const selectLoggedInUser = createSelector(
    selectUserState,
    fromUser.getLoggedInUser
);

export const selectIsLoggedIn = createSelector(
    selectUserState,
    state => state.loggedInUserId !== null
);
export const selectServiceWriter = createSelector(
    selectUserState,
    fromCard.selectSelectedCard,
    (u, c) => (c?.serviceWriter ? u.entities[c.serviceWriter] : undefined)
);
export const selectLeadTech = createSelector(
    selectUserState,
    fromCard.selectSelectedCard,
    (u, c) => (c?.leadTech ? u.entities[c.leadTech] : undefined)
);

export const selectFilteredUsers = createSelector(
    selectUserIds,
    fromBoard.selectSelectedBoard,
    fromColumn.selectColumns,
    (users, board, columns) => {
        if (board && users && columns) {
            const filteredColumns = columns
                .filter(c => c.idBoard === board._id)
                .map(c => c.extendedInfo.idUser)
                .filter(id => id !== null);
            return (users as string[]).filter(
                userId => filteredColumns.indexOf(userId) === -1
            );
        }
        return [];
    }
);

export const selectUserAndCard = createSelector(
    selectLoggedInUser,
    fromCard.selectSelectedCard,
    (user, card) => ({
        user,
        card,
    })
);

export const selectUsersTheme = createSelector(
    selectLoggedInUser,
    user => user?.prefTheme || "green-dark"
);
