import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as InsurerActions from "./insurer.actions";
import { Insurer } from "./insurer.model";

export const insurersFeatureKey = "insurers";

export interface State extends EntityState<Insurer> {
    selectedInsurerId: string | null;
}

export const adapter: EntityAdapter<Insurer> = createEntityAdapter<Insurer>({
    selectId: (ins: Insurer) => ins._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    selectedInsurerId: null,
});

const insurerReducer = createReducer(
    initialState,
    on(InsurerActions.addInsurer, (state, action) =>
        adapter.addOne(action.insurer, state)
    ),
    on(InsurerActions.upsertInsurer, (state, action) =>
        adapter.upsertOne(action.insurer, state)
    ),
    on(InsurerActions.addInsurers, (state, action) =>
        adapter.addMany(action.insurers, state)
    ),
    on(InsurerActions.upsertInsurers, (state, action) =>
        adapter.upsertMany(action.insurers, state)
    ),
    on(InsurerActions.updateInsurer, (state, action) =>
        adapter.updateOne(action.insurer, state)
    ),
    on(InsurerActions.updateInsurers, (state, action) =>
        adapter.updateMany(action.insurers, state)
    ),
    on(InsurerActions.deleteInsurerSuccess, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(InsurerActions.deleteInsurers, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(InsurerActions.loadInsurersSuccess, (state, action) =>
        adapter.upsertMany(action.insurers, state)
    ),
    on(InsurerActions.clearInsurers, state => adapter.removeAll(state)),
    on(InsurerActions.selectInsurer, (state, { id }) => ({
        ...state,
        selectedInsurerId: id,
    })),
    on(InsurerActions.deSelectInsurer, state => ({
        ...state,
        selectedInsurerId: null,
    })),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return insurerReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getSelectedInsurer = (state: State) =>
    state.selectedInsurerId
        ? state.entities[state.selectedInsurerId]
        : undefined;
