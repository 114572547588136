import {
    OnInit,
    Optional,
    Component,
    Renderer2,
    OnDestroy,
    ElementRef,
    ChangeDetectionStrategy,
} from "@angular/core";
import { Platform } from "@angular/cdk/platform";
import { NavigationExtras } from "@angular/router";
import { OverlayContainer } from "@angular/cdk/overlay";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { animate, style, transition, trigger } from "@angular/animations";

import { ToastrService } from "ngx-toastr";
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { filter, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { NotificationService } from "./core/services/notification-services/notification.service";

import * as fromUser from "./core/store/user";
import * as fromLayout from "./core/store/layout";

import * as LayoutActions from "./core/store/layout/layout.actions";
import * as RouterActions from "./core/store/router/router.actions";
import { DISABLED_WEB_SOCKETS } from "services/socket.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("inOutAnimation", [
            transition(":enter", [
                style({ opacity: 0 }),
                animate("100ms ease-out", style({ opacity: 1 })),
            ]),
            transition(":leave", [
                style({ opacity: 1 }),
                animate("100ms ease-in", style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    testHistory: boolean[] = [];
    public loadingSpinner$: Observable<boolean> = this.store.select(
        fromLayout.getIsLoading
    );

    toast$: Subscription;
    theme$ = this.store.pipe(
        select(fromUser.selectUsersTheme),
        tap(theme => this.changeTheme(theme))
    );
    isLoggedIn$ = this.store.pipe(
        select(fromUser.selectIsLoggedIn),
        filter(status => status),
        tap(() => this.subscribeToPushNotifications())
    );
    isMobile$ = this.breakpointObserver.observe(Breakpoints.Handset);
    private previousTheme: string | undefined;
    warningMessage: string | null = null;
    debuggingSocketDisableBtnText =
        localStorage.getItem(DISABLED_WEB_SOCKETS) === null
            ? "Disable Web Sockets"
            : "Enable Web Sockets";
    isProduction = environment.production;
    constructor(
        private breakpointObserver: BreakpointObserver,
        private _overlayContainer: OverlayContainer,
        private _element: ElementRef<HTMLElement>,
        private noteService: NotificationService,
        private store: Store<fromLayout.State>,
        private toastr: ToastrService,
        private _platform: Platform,
        private renderer: Renderer2,
        @Optional() private updates: SwUpdate,
        @Optional() private push: SwPush
    ) {
        this.toast$ = this.store
            .pipe(select(fromLayout.selectToast))
            .subscribe(toast => {
                if (toast) {
                    this.toastr[toast.type](
                        toast.body,
                        toast.title,
                        toast.override
                    )
                        .onTap.pipe(take(1))
                        .subscribe(() => this.toastrClickHandler(toast.meta));
                }
            });
    }

    ngOnInit(): void {
        if (!environment.production) {
            localStorage.debug = "socket.io-client:socket";
        }

        if (this._platform.WEBKIT) {
            this.warningMessage =
                "Internet Explorer is not a supported browser. Please use a supported browser";
        }

        if (this.updates) {
            this.updates.unrecoverable.subscribe(u => {
                console.error(
                    "[ERROR PWA] Update unrecoverable:",
                    u.type,
                    u.reason
                );
            });
            this.updates.available.subscribe(update => {
                console.log(
                    "[UPDATE] There is a PWA update ",
                    JSON.stringify(update, null, 2)
                );
                this.updates
                    .activateUpdate()
                    .then(() => document.location.reload());
            });
        }
        if (this.push) {
            this.push.messages.subscribe(msg =>
                console.log("push message", msg)
            );
            this.push.notificationClicks.subscribe(click =>
                console.log("notification click", click)
            );
        }

        this.store.dispatch(LayoutActions.endSpinner());
    }

    toggleWebSockets() {
        if (this.debuggingSocketDisableBtnText === "Disable Web Sockets") {
            localStorage.setItem(DISABLED_WEB_SOCKETS, "true");
            this.debuggingSocketDisableBtnText = "Enable Web Sockets";
        } else if (
            this.debuggingSocketDisableBtnText === "Enable Web Sockets"
        ) {
            localStorage.removeItem(DISABLED_WEB_SOCKETS);
            this.debuggingSocketDisableBtnText = "Disable Web Sockets";
        }
        document.location.reload();
    }

    toastrClickHandler(meta?: { cardRo: string | number; cardId: string }) {
        if (meta) {
            // close an existing card if ones already opened
            this.store.dispatch(
                RouterActions.Go({ payload: { path: [{ outlets: null }] } })
            );
            const extras: NavigationExtras = {
                queryParams: {
                    cardId: meta.cardId,
                    tab: "comments",
                },
            };
            this.store.dispatch(
                RouterActions.Go({
                    payload: {
                        path: [{ outlets: { card: [meta.cardRo || ""] } }],
                        extras,
                    },
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.toast$.unsubscribe();
    }

    subscribeToPushNotifications() {
        if (this.push && this.push.isEnabled) {
            this.push
                .requestSubscription({
                    serverPublicKey: environment.VAPID_PUBLIC,
                })
                .then(subscription => {
                    this.noteService.addPushSubscriber(subscription);
                })
                .catch(err =>
                    console.error("Something went wrong when subscribing", err)
                );
        }
    }

    changeTheme(themeClass: string) {
        if (this.previousTheme) {
            this._element.nativeElement.classList.remove(this.previousTheme);
            this._overlayContainer
                .getContainerElement()
                .classList.remove(this.previousTheme);
            this.renderer.removeClass(document.body, this.previousTheme);
        }
        this.renderer.addClass(document.body, themeClass);
        this._overlayContainer.getContainerElement().classList.add(themeClass);
        this._element.nativeElement.classList.add(themeClass);
        this.previousTheme = themeClass;
    }
}
