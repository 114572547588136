import { Router } from "@angular/router";
import { Component } from "@angular/core";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
})
export class ErrorComponent  {
    constructor(public router: Router) {}

    goHome(): void {
        this.router.navigate(["/"]);
    }
}
