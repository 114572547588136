<div class="mat-dialog-header">
    <button mat-icon-button class="close-btn" mat-dialog-close color="warn">
        <mat-icon inline>close</mat-icon>
    </button>
</div>

<ngx-extended-pdf-viewer
    fxFlex
    [src]="data"
    [useBrowserLocale]="true"
    [showSidebarButton]="false"
    [showBookmarkButton]="false"
    [showDownloadButton]="false"
    [showPrintButton]="true"
    [showOpenFileButton]="false"
    [showPropertiesButton]="false"
    [showPresentationModeButton]="false"
    [showUnverifiedSignatures]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [printResolution]="300"
    [showBorders]="true"
    [showFindButton]="false"
    [textLayer]="true"
    [mobileFriendlyZoom]="true"
    [showHandToolButton]="false"
    enablePinchOnMobile="true"
></ngx-extended-pdf-viewer>
