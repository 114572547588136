import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { Move } from "../../models/types";
import { IBoard } from "./board.model";
import { IColumn } from "../column/column.model";
import { ICard } from "store/card/card.model";

export const loadBoards = createAction("[Board/API] Load Boards");
export const loadBoardsSuccess = createAction(
    "[Board/API] Load Boards Success",
    props<{ boards: IBoard[] }>()
);
export const loadAllBoardsForCompany = createAction(
    "[Board/API] Load All Boards For Company"
);
export const loadBoardsFailure = createAction(
    "[Board/API] Load Boards Failure",
    props<{ error: string }>()
);

export const addBoard = createAction(
    "[Board/API] Add Board",
    props<{ board: IBoard }>()
);

export const upsertBoard = createAction(
    "[Board/API] Upsert Board",
    props<{ board: IBoard }>()
);

export const addBoards = createAction(
    "[Board/API] Add Boards",
    props<{ boards: IBoard[] }>()
);

export const upsertBoards = createAction(
    "[Board/API] Upsert Boards",
    props<{ boards: IBoard[] }>()
);

export const updateBoardEntity = createAction(
    "[Board/API] Update Board",
    props<{ board: Update<IBoard> }>()
);

export const updateBoards = createAction(
    "[Board/API] Update Boards",
    props<{ boards: Update<IBoard>[] }>()
);

export const deleteBoard = createAction(
    "[Board/API] Delete Board",
    props<{ id: string }>()
);
export const deleteBoardSuccess = createAction(
    "[Board/API] Delete Board Success",
    props<{ id: string }>()
);
export const deleteBoardFailed = createAction(
    "[Board/API] Delete Board Failed",
    props<{ error: any }>()
);
export const deleteBoards = createAction(
    "[Board/API] Delete Boards",
    props<{ ids: string[] }>()
);

export const clearBoards = createAction("[Board/API] Clear Boards");

export const loadBoard = createAction(
    "[Board] Load Board by id",
    props<{ boardId: string }>()
);

export const updateLiveBoard = createAction(
    "[Board] Update Live Board",
    props<{ boardId: string }>()
);

export const selectBoard = createAction(
    "[Board] Select Board",
    props<{ boardId: string }>()
);

export const loadBoardSuccess = createAction(
    "[Board/API] Load Board Success",
    props<{ board: IBoard }>()
);

export const loadBoardFailure = createAction(
    "[Board/API] Load Board Failure",
    props<{ error: string }>()
);

export const deselectBoard = createAction("[Board] Deselect Board");
export const createBoard = createAction(
    "[Board/API] Create a board",
    props<{
        payload: Pick<IBoard, "title" | "desc" | "coverImage" | "type">;
    }>()
);

export const createBoardFailed = createAction(
    "[Board] CREATE_BOARD_FAILED",
    props<{ error: string }>()
);
export const moveCard = createAction("[Board] Move Card", props<Move>());
export const moveCardSuccess = createAction(
    "[Board] Move Card Success",
    props<Move>()
);
export const moveCardFailed = createAction(
    "[Board] Move Card Failed",
    props<{
        oldState: { card: Update<ICard>; columns: Update<IColumn>[] };
        error: any;
    }>()
);
export const updateBoard = createAction(
    "[Board/API] updateBoard",
    props<{ board: Update<IBoard> }>()
);
export const updateBoardSuccess = createAction(
    "[Board/API] updateBoard Success",
    props<{ board: Update<IBoard> }>()
);
export const updateBoardFailed = createAction(
    "[Board/API] updateBoard Failed",
    props<{ error: any }>()
);

export const updateColumnOrder = createAction(
    "[Board/API] Update order of columns",
    props<{ board: Update<IBoard>; oldState: Update<IBoard> }>()
);
export const updateColumnOrderSuccess = createAction(
    "[Board/API] Update order of columns succeeded",
    props<{ board: Update<IBoard> }>()
);
export const updateColumnOrderFailed = createAction(
    "[Board/API] Update order of columns failed",
    props<{ oldState: Update<IBoard>; err: string }>()
);

export const archiveAllCardsInColumn = createAction(
    "[Board/API] Confirm archive all cards in column",
    props<{ column: IColumn }>()
);

export const confirmedArchive = createAction(
    "[Board/API] Archive all cards in column",
    props<{ column: IColumn }>()
);

export const dismissArchiveAllCards = createAction(
    "[Board/API] Dismiss archive"
);
