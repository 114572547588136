import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as NotificationActions from "./notification.actions";
import { INotification } from "./notification.model";
export const notificationsFeatureKey = "notifications";

export interface State extends EntityState<INotification> {
    loadedAll: boolean;
    totalUnread: number;
    total: number;
    loading: boolean;
    page: number;
    limit: number;
}

export const adapter: EntityAdapter<INotification> =
    createEntityAdapter<INotification>({
        selectId: (note: INotification) => note._id,
        sortComparer: (a: INotification, b: INotification) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    });

export const initialState: State = adapter.getInitialState({
    loadedAll: false,
    total: 0,
    loading: false,
    totalUnread: 0,
    page: 0,
    limit: 50,
});

const notificationReducer = createReducer(
    initialState,
    on(NotificationActions.addNotification, (state, action) =>
        adapter.addOne(action.notification, state)
    ),
    on(NotificationActions.upsertNotification, (state, action) =>
        adapter.upsertOne(action.notification, state)
    ),
    on(NotificationActions.addNotifications, (state, action) =>
        adapter.addMany(action.notifications, state)
    ),
    on(NotificationActions.upsertNotifications, (state, action) =>
        adapter.upsertMany(action.notifications, state)
    ),
    on(NotificationActions.updateNotification, (state, action) =>
        adapter.updateOne(action.notification, state)
    ),
    on(NotificationActions.updateNotifications, (state, action) =>
        adapter.updateMany(action.notifications, state)
    ),
    on(NotificationActions.deleteNotification, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(NotificationActions.deleteNotifications, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(NotificationActions.loadUnreadNotificationsSuccess, (state, action) => {
        const newPage = state.page + 1;
        return adapter.addMany(action.notifications, {
            ...state,
            totalUnread: action.total,
            page: newPage,
            loading: false,
        });
    }),
    on(NotificationActions.loadUnreadNotifications, state => ({
        ...state,
        loading: true,
    })),
    on(NotificationActions.clearNotifications, state =>
        adapter.removeAll(state)
    ),
    on(NotificationActions.markAsRead, (state, { _id }) =>
        adapter.updateOne({ id: _id, changes: { unread: false } }, state)
    ),
    on(NotificationActions.loadAllNotificationsSuccess, (state, action) =>
        adapter.upsertMany(action.notifications, {
            ...state,
            loadedAll: true,
            loading: false,
            total: action.total,
        })
    ),
    on(NotificationActions.markAllAsRead, (state, action) =>
        adapter.updateMany(
            action.ids.map(id => ({ id, changes: { unread: false } })),
            state
        )
    ),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return notificationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();

export const getPage = (state: State) => state.page;
export const getTotal = (state: State) => state.total;
export const getLimit = (state: State) => state.limit;
export const getIsLoading = (state: State) => state.loading;
export const getAllLoaded = (state: State) => state.loadedAll;
export const getUnreadCount = (state: State) => state.totalUnread;
