import { Pipe, PipeTransform } from "@angular/core";
import { ISupplement } from "../../store/supplement/supplement.model";

@Pipe({ name: "supplementStatusColor" })
export class SupplementStatusColorPipe implements PipeTransform {
    transform(supp: ISupplement): string {
        switch (supp.status) {
            case "processed":
                return "green";
            case "pending":
                return "deeppink";
            default:
                return "inherit";
        }
    }
}
