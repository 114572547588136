import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, throwError } from "rxjs";
import { catchError, concatMap, map, switchMap, tap } from "rxjs/operators";
import * as AuthActions from "src/app/auth/store/auth.actions";
import { environment } from "src/environments/environment";
import { SocketService } from "src/app/core/services/socket.service";
import * as LayoutActions from "../layout/layout.actions";
import * as UserActions from "./user.actions";
import { IUser } from "./user.model";

@Injectable()
export class UserEffects {
    LoadAllUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserActions.loadUsers,
                AuthActions.loginSuccess,
                AuthActions.registerCompanySuccess
            ),
            switchMap(() =>
                this.http.get<IUser[]>(`${environment.api}/users/all`).pipe(
                    map(users => UserActions.loadUsersSuccess({ users })),
                    catchError(err => throwError(err))
                )
            )
        )
    );

    LoadUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            map(({ user }) =>
                UserActions.setLoggedInUser({
                    id: user._id,
                    user,
                })
            )
        )
    );

    UpdateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateUser),
            concatMap(({ user }) =>
                this.http
                    .put(`${environment.api}/users/${user.id}`, {
                        ...user.changes,
                        _id: user.id,
                    })
                    .pipe(
                        tap(() => this.socket.onUserUpdatedSuccess({ user })),
                        map(() =>
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Your settings have been updated",
                                    title: "",
                                    type: "success",
                                },
                            })
                        )
                    )
            )
        )
    );

    ChangePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.changePassword),
            switchMap(value =>
                this.http
                    .patch(`${environment.api}/users/${value.id}/pw`, value)
                    .pipe(
                        map(() =>
                            LayoutActions.showToastr({
                                payload: {
                                    body: "Your password has been updated",
                                    title: "",
                                    type: "success",
                                },
                            })
                        )
                    )
            )
        )
    );

    CreateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.createNewEmployee),
            concatMap(({ user }) =>
                this.http
                    .post<IUser>(`${environment.api}/users/new`, user)
                    .pipe(
                        map(newUser => {
                            this.socket.onUserCreatedSuccess(newUser);
                            return UserActions.addUser({ user: newUser });
                        })
                    )
            )
        )
    );

    DeleteUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.deleteUser),
                concatMap(({ id }) =>
                    this.http.delete(`${environment.api}/users/delete/${id}`)
                )
            ),
        { dispatch: false }
    );

    ResendEmailVerification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.resendEmailVerification),
                concatMap(() =>
                    this.http
                        .post(
                            `${environment.api}/users/re-send-email-verification`,
                            null
                        )
                        .pipe(
                            catchError(err => {
                                console.log(
                                    "Something went wrong when sending verification codes",
                                    err
                                );
                                return of(null);
                            })
                        )
                )
            ),
        { dispatch: false }
    );
    ResendPhoneVerification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.resendPhoneVerification),
                concatMap(() =>
                    this.http
                        .post(
                            `${environment.api}/users/re-send-phone-verification`,
                            null
                        )
                        .pipe(
                            catchError(err => {
                                console.log(
                                    "Something went wrong when sending verification codes",
                                    err
                                );
                                return of(null);
                            })
                        )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private socket: SocketService
    ) {}
}
