import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import {
    map,
    concatMap,
    exhaustMap,
    catchError,
    withLatestFrom,
} from "rxjs/operators";

import { environment } from "src/environments/environment";
import { INotification } from "./notification.model";

import * as fromRoot from "src/app/reducers";
import * as fromNote from "./index";
import * as NotificationActions from "./notification.actions";

@Injectable()
export class NotificationEffects {
    onLoadUnreadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.loadUnreadNotifications),
            withLatestFrom(
                this.store.select(fromNote.selectPage),
                this.store.select(fromNote.selectLimit)
            ),
            exhaustMap(([_, page, limit]) =>
                this.http
                    .get<{ notifications: INotification[]; total: number }>(
                        `${environment.api}/noti/unread?page=${page}&limit=${limit}`
                    )
                    .pipe(
                        map(data =>
                            NotificationActions.loadUnreadNotificationsSuccess(
                                data
                            )
                        ),
                        catchError(err => {
                            console.log(
                                "Unread notifications turned up empty. ",
                                err
                            );
                            return EMPTY;
                        })
                    )
            )
        )
    );

    onLoadAllNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.loadAllNotifications),
            concatMap(() =>
                this.http
                    .get<{ notifications: INotification[]; total: number }>(
                        `${environment.api}/noti/all`
                    )
                    .pipe(
                        map(data =>
                            NotificationActions.loadAllNotificationsSuccess(
                                data
                            )
                        ),
                        catchError(err =>
                            of(
                                NotificationActions.loadAllNotificationsFailure(
                                    { error: err.toString() }
                                )
                            )
                        )
                    )
            )
        )
    );
    onMarkAllAsUnread$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.markAllAsUnread),
            concatMap(() =>
                this.http
                    .get<{ notifications: INotification[]; total: number }>(
                        `${environment.api}/noti/all/markallasunread`
                    )
                    .pipe(
                        map(data =>
                            NotificationActions.markAllAsUnreadSuccess(data)
                        ),
                        catchError(() => EMPTY)
                    )
            )
        )
    );

    onMarkAsRead$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationActions.markAsRead),
                concatMap(({ _id }) =>
                    this.http.patch(`${environment.api}/noti/markasread`, {
                        _id,
                    })
                )
            ),
        { dispatch: false }
    );

    onMarkAllAsRead$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationActions.markAllAsRead),
                concatMap(({ ids }) =>
                    this.http.put(`${environment.api}/noti/markallasread`, {
                        ids,
                    })
                )
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {}
}
