<ng-container *ngIf="user$ | async">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="start center">
        <div>
            <img class="user_avatar" [src]="avatar" alt="User avatar" />
        </div>

        <div>
            {{ user.get("firstName").value }} {{ user.get("lastName").value }}
        </div>
    </div>
    <mat-dialog-content>
        <form
            class="form"
            [formGroup]="user"
            fxLayout="column"
            fxLayoutGap="20px"
            fxLayoutAlign="center center"
        >
            <mat-form-field fxFlex class="w-100" color="primary">
                <mat-label>First Name</mat-label>
                <input
                    matInput
                    placeholder="First Name"
                    formControlName="firstName"
                    required
                />
                <mat-error *ngIf="user.get('firstName').hasError('required')">
                    First name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex class="w-100" color="primary">
                <mat-label>Last Name</mat-label>
                <input
                    matInput
                    placeholder="Last Name"
                    formControlName="lastName"
                    required
                />
                <mat-error *ngIf="user.get('lastName').hasError('required')">
                    Last name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex class="w-100" color="primary">
                <mat-label> Email </mat-label>
                <input
                    matInput
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    required
                />
                <mat-error *ngIf="user.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="user.get('email').hasError('isTaken')">
                    Email already exists
                </mat-error>
                <mat-error *ngIf="user.get('email').hasError('pattern')">
                    Please enter a valid email address
                </mat-error>
                <mat-hint
                    *ngIf="loggedInUser.emailVerificationStatus === 'pending'"
                    class="warn-text"
                >
                    Email verification pending. Didn't get it? Click
                    <a href="" (click)="reSendEmailVerification($event)">
                        Here
                    </a>
                    to resend.
                </mat-hint>
                <mat-hint
                    *ngIf="loggedInUser.emailVerificationStatus === 'verified'"
                    style="color: green"
                >
                    Verified
                </mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex class="w-100" color="primary">
                <mat-label>Phone Number</mat-label>
                <input
                    matInput
                    [value]="phone.value | phone"
                    type="tel"
                    placeholder="Phone"
                    formControlName="phone"
                />
                <mat-error *ngIf="user.get('phone').hasError('required')">
                    Phone is required
                </mat-error>
                <mat-error *ngIf="user.get('phone').hasError('pattern')">
                    Invalid phone format
                </mat-error>
                <mat-hint
                    *ngIf="loggedInUser.phoneVerificationStatus === 'pending'"
                    class="warn-text"
                >
                    Verification pending. Didn't get it? Click
                    <span
                        style="color: lightblue; text-decoration: underline"
                        (click)="reSendPhoneVerification($event)"
                    >
                        Here
                    </span>
                    to resend.
                </mat-hint>
                <mat-hint
                    *ngIf="loggedInUser.phoneVerificationStatus === 'verified'"
                    style="color: green"
                >
                    Verified
                </mat-hint>
            </mat-form-field>

            <ng-container *ngIf="changePassword">
                <mat-form-field fxFlex class="w-100" color="primary">
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        autocomplete="current-password"
                        placeholder="Enter a new password"
                        [type]="hide ? 'password' : 'text'"
                        formControlName="password"
                        required
                    />
                    <mat-error *ngIf="password.hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-icon matSuffix class="passwd" (click)="hide = !hide">
                        {{ hide ? "visibility_off" : "visibility" }}
                    </mat-icon>
                </mat-form-field>
                <mat-form-field fxFlex class="w-100" color="primary">
                    <mat-label>Confirm Password</mat-label>
                    <input
                        matInput
                        autocomplete="current-password"
                        placeholder="Confirm password"
                        [type]="hide ? 'password' : 'text'"
                        formControlName="confirm"
                        required
                    />
                    <mat-error *ngIf="confirm.hasError('mustMatch')">
                        Passwords do not match
                    </mat-error>
                    <mat-error *ngIf="confirm.hasError('required')">
                        Confirm is required
                    </mat-error>
                    <mat-icon matSuffix class="passwd" (click)="hide = !hide">
                        {{ hide ? "visibility_off" : "visibility" }}
                    </mat-icon>
                </mat-form-field>
            </ng-container>

            <h1 class="mat-h2" fxFlex="100">Notifications</h1>
            <div
                fxFlex="100"
                style="width: 100%"
                fxLayout="row"
                fxLayoutAlign="space-around center"
                formGroupName="prefs"
            >
                <mat-checkbox formControlName="email">Email</mat-checkbox>
                <mat-checkbox formControlName="push">Push</mat-checkbox>
                <mat-checkbox formControlName="sms">SMS</mat-checkbox>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="actions">
        <button
            mat-flat-button
            [color]="changePassword ? 'warn' : 'primary'"
            (click)="togglePasswordForm()"
        >
            {{ changePassword ? "Nevermind" : "Change Password" }}
        </button>
        <button
            mat-flat-button
            [color]="user.dirty ? 'primary' : 'accent'"
            [disabled]="user.invalid"
            class="mx-3"
            (click)="save()"
        >
            {{ user.dirty ? "Save" : "Close" }}
        </button>
    </mat-dialog-actions>
</ng-container>
