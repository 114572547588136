import * as fromRoot from "src/app/reducers";
import * as fromRouterStore from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RouterStateUrl } from "src/app/router.serializer";

const selectRouter = createFeatureSelector<
    fromRoot.State,
    fromRouterStore.RouterReducerState<RouterStateUrl>
>("router");

export const selectRouteParams = createSelector(selectRouter, router =>
    router ? router.state.params : {}
);

export const selectRouteParam = (param: string) =>
    createSelector(selectRouter, router =>
        router ? router.state.params[param] : undefined
    );

export const selectParams = createSelector(selectRouter, router => ({
    params: router.state.params,
    queryParams: router.state.queryParams,
    navNum: router.navigationId,
    url: router.state.url,
}));
