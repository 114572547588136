import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "app-content-header",
    templateUrl: "./content-header.component.html",
    styleUrls: ["./content-header.component.scss"],
})
export class ContentHeaderComponent {
    @Input() icon: string | undefined;

    @Input() Iicon: string | undefined;

    @Input() title: string | undefined;

    @Input() desc: string | undefined;

    @Input() class: string | undefined;

    @Input() hasBgImage = false;

    @Input() showSearch = false;

    @Input() menuIcons?: string[];

    @Input() hideBreadcrumb = false;

    @Input() searchInput: FormControl | undefined;

    @Output() readonly menuIconClicked = new EventEmitter<number>();

    constructor() {}
}
