import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class VerifyEmailGuard implements CanActivate {
    constructor(private http: HttpClient) {}

    canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (state.url.startsWith("/api")) {
            return this.http.get(state.url).pipe(
                map(() => true),
                catchError(() => of(true))
            );
        } else return of(true);
    }
}
