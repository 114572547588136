import { Injectable } from "@angular/core";
import { IVehicle } from "./vehicle.model";
import { map, concatMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as VehicleActions from "./vehicle.actions";

@Injectable()
export class VehicleEffects {
    LoadVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VehicleActions.loadVehicles),
            concatMap(() =>
                this.http
                    .get<IVehicle[]>(`${environment.api}/vehicles/all`)
                    .pipe(
                        map(vehicles =>
                            VehicleActions.loadVehiclesSuccess({ vehicles })
                        )
                    )
            )
        )
    );

    UpdateVehicle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VehicleActions.updateVehicle),
                concatMap(({ vehicle }) =>
                    this.http.put(`${environment.api}/vehicles/${vehicle.id}`, {
                        ...vehicle.changes,
                    })
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private http: HttpClient) {}
}
