import { Pipe, PipeTransform } from "@angular/core";
import { IField } from "../../store/cardTemplate/card-template.model";

@Pipe({ name: "checklistProgress" })
export class ChecklistProgressPipe implements PipeTransform {
    transform(fields: IField[]): number {
        const values = fields.reduce(
            (prev, field) => {
                if (field.na)
                    return { ...prev, totalFields: prev.totalFields - 1 };
                if (field.completed) {
                    return { ...prev, totalComplete: prev.totalComplete + 1 };
                }
                return prev;
            },
            { totalFields: fields.length, totalComplete: 0 }
        );
        if (values.totalFields === 0) return 1;
        return values.totalComplete / values.totalFields;
    }
}
