import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { ICardTemplate as CardTemplate } from "./card-template.model";

export const loadCardTemplates = createAction(
    "[CardTemplate/API] Load CardTemplates"
);
export const loadCardTemplatesSuccess = createAction(
    "[CardTemplate/API] Load CardTemplates Success",
    props<{ cardTemplates: CardTemplate[] }>()
);
export const createTemplate = createAction(
    "[CardTemplate/API] Create CardTemplate",
    props<{ template: Partial<CardTemplate> }>()
);
export const addCardTemplate = createAction(
    "[CardTemplate/API] Add CardTemplate",
    props<{ cardTemplate: CardTemplate }>()
);

export const upsertCardTemplate = createAction(
    "[CardTemplate/API] Upsert CardTemplate",
    props<{ cardTemplate: CardTemplate }>()
);

export const addCardTemplates = createAction(
    "[CardTemplate/API] Add CardTemplates",
    props<{ cardTemplates: CardTemplate[] }>()
);

export const upsertCardTemplates = createAction(
    "[CardTemplate/API] Upsert CardTemplates",
    props<{ cardTemplates: CardTemplate[] }>()
);

export const updateCardTemplate = createAction(
    "[CardTemplate/API] Update CardTemplate",
    props<{ cardTemplate: Update<CardTemplate> }>()
);

export const updateCardTemplates = createAction(
    "[CardTemplate/API] Update CardTemplates",
    props<{ cardTemplates: Update<CardTemplate>[] }>()
);

export const deleteCardTemplate = createAction(
    "[CardTemplate/API] Delete CardTemplate",
    props<{ id: string }>()
);

export const deleteCardTemplates = createAction(
    "[CardTemplate/API] Delete CardTemplates",
    props<{ ids: string[] }>()
);

export const clearCardTemplates = createAction(
    "[CardTemplate/API] Clear CardTemplates"
);

export const selectTemplate = createAction(
    "[SELECT_TEMPLATE]",
    props<{ id: string }>()
);

export const deSelectTemplate = createAction("[CardTemplate] De Select");
export const updateTemplateSuccess = createAction(
    "[TEMPLATE_UPDATE_SUCCESS]",
    props<{ payload: CardTemplate }>()
);
export const updateTemplateFailed = createAction(
    "[TEMPLATE_UPDATE_FAILED]",
    props<{ error: string }>()
);
export const createTemplateSuccess = createAction(
    "[TEMPLATE_CREATE_SUCCESS]",
    props<{ payload: CardTemplate }>()
);
export const createTemplateFailed = createAction(
    "[TEMPLATE_CREATE_FAILED]",
    props<{ error: string }>()
);

// import { createAction, props } from "@ngrx/store";
// import { IChecklist, IField, ICardTemplate } from "./card-template.model";

// export const selectTemplate = createAction(
//   "[SELECT_TEMPLATE]",
//   props<{ payload: ICardTemplate }>()
// );
// export const selectChecklist = createAction(
//   "[SELECT_CHECKLIST]",
//   props<{ payload: IChecklist }>()
// );
// export const deSelectChecklist = createAction("[DESELECT_CHECKLIST]");
// export const deSelectTemplate = createAction("[DESELECT_TEMPLATE]");

// export const createChecklist = createAction(
//   "[LIST_CREATE_CHECKLIST]",
//   props<{ payload: IChecklist }>()
// );
// export const createChecklistSuccess = createAction(
//   "[LIST_CREATE_CHECKLIST_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const createChecklistFailed = createAction(
//   "[LIST_CREATE_CHECKLIST_FAILED]",
//   props<{ error: string }>()
// );
// export const updateChecklist = createAction(
//   "[LIST_UPDATE_CHECKLIST]",
//   props<{ payload: IChecklist }>()
// );
// export const updateChecklistSuccess = createAction(
//   "[LIST_UPDATE_CHECKLIST_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const updateChecklistFailed = createAction(
//   "[LIST_UPDATE_CHECKLIST_FAILED]",
//   props<{ error: string }>()
// );
// export const deleteChecklist = createAction(
//   "[LIST_DELETE_CHECKLIST]",
//   props<{ payload: IChecklist }>()
// );
// export const deleteChecklistSuccess = createAction(
//   "[LIST_DELETE_CHECKLIST_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const deleteChecklistFailed = createAction(
//   "[LIST_DELETE_CHECKLIST_FAILED]",
//   props<{ error: string }>()
// );

// export const deleteField = createAction(
//   "[LIST_DELETE_FIELD]",
//   props<{ payload: IChecklist }>()
// );
// export const deleteFieldSuccess = createAction(
//   "[LIST_DELETE_FIELD_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const deleteFieldFailed = createAction(
//   "[LIST_DELETE_FIELD_FAILED]",
//   props<{ error: string }>()
// );
// export const moveFields = createAction(
//   "[LIST_UPDATE_FIELD_ORDER]",
//   props<{
//     payload: {
//       checklist: IChecklist;
//       previousIndex: number;
//       currentIndex: number;
//     };
//   }>()
// );
// export const moveFieldsSuccess = createAction(
//   "[LIST_UPDATE_FIELD]",
//   props<{ payload: IChecklist }>()
// );
// export const moveFieldsFailed = createAction(
//   "[LIST_UPDATE_FIELD_ORDER_SUCCESS]",
//   props<{ error: string }>()
// );
// export const updateFields = createAction(
//   "[LIST_UPDATE_FIELD_ORDER_FAILED]",
//   props<{ payload: IChecklist }>()
// );
// export const updateFieldsSuccess = createAction(
//   "[LIST_UPDATE_FIELD_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const updateFieldsFailed = createAction(
//   "[LIST_UPDATE_FIELD_FAILED]",
//   props<{ error: string }>()
// );
// export const createField = createAction(
//   "[LIST_CREATE_FIELD]",
//   props<{ payload: { checklist: IChecklist; field: IField } }>()
// );

// export const createFieldSuccess = createAction(
//   "[LIST_CREATE_FIELD_SUCCESS]",
//   props<{ payload: IChecklist }>()
// );
// export const createFieldFailed = createAction(
//   "[LIST_CREATE_FIELD_FAILED]",
//   props<{ error: string }>()
// );

// export const deleteTemplate = createAction(
//   "[TEMPLATE_DELETE]",
//   props<{ payload: ICardTemplate }>()
// );
// export const deleteTemplateSuccess = createAction(
//   "[TEMPLATE_DELETE_SUCCESS]",
//   props<{ payload: ICardTemplate }>()
// );
// export const deleteTemplateFailed = createAction(
//   "[TEMPLATE_DELETE_FAILED]",
//   props<{ error: string }>()
// );
// export const updateTemplate = createAction(
//   "[TEMPLATE_UPDATE]",
//   props<{ payload: ICardTemplate }>()
// );
// export const updateTemplateSuccess = createAction(
//   "[TEMPLATE_UPDATE_SUCCESS]",
//   props<{ payload: ICardTemplate }>()
// );
// export const updateTemplateFailed = createAction(
//   "[TEMPLATE_UPDATE_FAILED]",
//   props<{ error: string }>()
// );
// export const createTemplate = createAction(
//   "[TEMPLATE_CREATE]",
//   props<{ payload: ICardTemplate }>()
// );
// export const createTemplateSuccess = createAction(
//   "[TEMPLATE_CREATE_SUCCESS]",
//   props<{ payload: ICardTemplate }>()
// );
// export const createTemplateFailed = createAction(
//   "[TEMPLATE_CREATE_FAILED]",
//   props<{ error: string }>()
// );
