import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Socket } from "ngx-socket-io";
import { Store } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { Socket as IOSocket } from "socket.io-client";
import { environment } from "../../../environments/environment";

import { Move } from "../models/types";
import { Toast, ToastType } from "../models/toast";

import {
    IChecklist,
    ICardTemplate,
} from "store/cardTemplate/card-template.model";
import { ICard } from "store/card/card.model";
import { IUser } from "store/user/user.model";
import { IBoard } from "store/board/board.model";
import { ICompFile } from "store/files/compFile";
import { IColumn } from "store/column/column.model";
import { IComment } from "store/comment/comment.model";
import { IVehicle } from "store/vehicle/vehicle.model";
import { ICustomer } from "store/customer/customer.types";
import { ISupplement } from "store/supplement/supplement.model";
import { IDepartment } from "store/department/department.model";

import * as fromRoot from "src/app/reducers";
import * as SockEvents from "../store/company/socket.events";
import * as LayoutActions from "../store/layout/layout.actions";

export const DISABLED_WEB_SOCKETS = "disableWebSockets";

@Injectable({ providedIn: "root" })
export class SocketService extends Socket {
    constructor(
        @Inject(DOCUMENT) doc: Document,
        private store: Store<fromRoot.State>
    ) {
        super({
            url: `${doc.location.protocol}//${doc.location.host}/`,
            options: {
                autoConnect: true,
                transports:
                    "WebSocket" in window &&
                    localStorage.getItem(DISABLED_WEB_SOCKETS) === null
                        ? ["websocket", "polling"]
                        : ["polling"],

                withCredentials: true,
                auth: (cb: (data: { token: string }) => void) => {
                    cb({ token: localStorage.getItem("AuthToken") });
                },
            },
        });

        if (!environment.production) {
            this.fromEvent("reconnect_failed").subscribe(() => {
                this.updateSocketConnectedStatus(false);
                this.processToast("reconnect_failed", "error");
            });
            this.fromEvent("reconnect").subscribe(() => {
                this.updateSocketConnectedStatus(false);
                this.processToast("reconnected", "warning");
            });
            this.fromEvent("reconnecting").subscribe(() => {
                this.updateSocketConnectedStatus(false);
                this.processToast("reconnecting", "warning");
            });
            this.fromEvent("connect_error").subscribe((error: Error) => {
                this.updateSocketConnectedStatus(false);
                this.processToast(error.message, "error");
            });
            this.fromEvent("connecting").subscribe(() => {
                this.updateSocketConnectedStatus(false);
                this.processToast("Socket attempting connection", "warning");
            });

            this.fromEvent("socket data").subscribe((data: any) => {
                if (!environment.production) console.log("Socket data", data);
            });
            this.fromEvent("room update").subscribe((data: any) => {
                if (!environment.production) console.log("Room Update", data);
            });
            this.fromEvent("send test toast").subscribe((message: string) => {
                this.processToast(message, "info");
            });
        }
        this.fromEvent("connect").subscribe(() => {
            if (!environment.production) {
                this.updateSocketConnectedStatus(true);
                this.processToast("Socket connected!", "success");
            }
            (this.ioSocket as IOSocket).sendBuffer = [];
        });
        this.fromEvent("disconnect").subscribe((reason: string) => {
            if (!environment.production) {
                this.updateSocketConnectedStatus(false);
                this.processToast("Socket disconnected!: " + reason, "warning");
            }
            if (reason === "io server disconnect") {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.connect();
            }
            // else the socket will automatically try to reconnect
        });
    }

    toastr$ = this.fromEvent<Toast>(SockEvents.TOASTR);

    onColDelete$ = this.fromEvent<{ id: string }>(SockEvents.DELETE_COLUMN);

    onColumnsUpdated$ = this.fromEvent<{ columns: Update<IColumn>[] }>(
        SockEvents.UPDATE_COLUMNS
    );

    onCardMove$ = this.fromEvent<Move>(SockEvents.ON_MOVED_CARD);

    onColumnCreation$ = this.fromEvent<{ column: IColumn }>(
        SockEvents.CREATE_COLUMN
    );

    onColumnUpdate$ = this.fromEvent<{ column: Update<IColumn> }>(
        SockEvents.UPDATE_COLUMN
    );

    onCardUpdated$ = this.fromEvent<{ card: Update<ICard> }>(
        SockEvents.UPDATE_CARD
    );

    onCardCreation$ = this.fromEvent<{
        card: ICard;
        vehicle: IVehicle;
        customer: ICustomer;
    }>(SockEvents.CREATE_CARD);

    // #endregion

    // ! USERS/EMPLOYEES
    // #region USERS/EMPLOYEES
    onUserUPDATED$ = this.fromEvent<{ user: Update<IUser> }>(
        SockEvents.USER_UPDATED
    );

    onUserCREATED$ = this.fromEvent<{ user: IUser }>(SockEvents.USER_CREATED);
    // #endregion

    // ! CUSTOMER
    // #region CUSTOMER
    onCustomerUPDATED$ = this.fromEvent<{ customer: Update<ICustomer> }>(
        SockEvents.CUSTOMER_UPDATED
    );

    onCustomerCREATED$ = this.fromEvent<{ customer: ICustomer }>(
        SockEvents.CUSTOMER_CREATED
    );

    // #endregion
    // ! CHECKLISTS
    // #region CHECKLISTS
    onChecklistUPDATED$ = this.fromEvent<{ checklist: Update<IChecklist> }>(
        SockEvents.CHECKLIST_UPDATED
    );

    onChecklistCREATED$ = this.fromEvent<{ checklist: IChecklist }>(
        SockEvents.CHECKLIST_CREATED
    );

    onChecklistDELETED$ = this.fromEvent<{ id: string }>(
        SockEvents.CHECKLIST_DELETED
    );
    // #endregion

    // ! DEPARTMENTS
    // #region DEPARTMENTS
    onDepartmentUPDATED$ = this.fromEvent<{ department: Update<IDepartment> }>(
        SockEvents.DEPARTMENT_UPDATED
    );

    // #endregion
    // ! TEMPLATES
    // #region TEMPLATES
    onTemplateUPDATED$ = this.fromEvent<{
        cardTemplate: Update<ICardTemplate>;
    }>(SockEvents.TEMPLATE_UPDATED);

    onTemplateCREATED$ = this.fromEvent<{ cardTemplate: ICardTemplate }>(
        SockEvents.TEMPLATE_CREATED
    );

    onTemplateDELETED$ = this.fromEvent<{ id: string }>(
        SockEvents.TEMPLATE_DELETED
    );

    // #endregion
    onBoardUpdated$ = this.fromEvent<{ board: Update<IBoard> }>(
        SockEvents.UPDATE_BOARD
    );

    onComment$ = this.fromEvent<{ comment: IComment }>(SockEvents.ON_COMMENT);

    onMentionedInComment$ = this.fromEvent<{ comment: IComment }>(
        SockEvents.ON_COMMENT_SPECIFIED
    );

    public get connected(): boolean {
        return this.ioSocket.connected;
    }

    public getSocketData() {
        this.emit("send socket data");
    }

    public joinBoardRoom(boardId: string) {
        this.emit("join board room", { id: boardId });
    }

    public createCard(data: {
        card: ICard;
        vehicle: IVehicle;
        customer: ICustomer;
    }) {
        if (this.connected) {
            this.emit("onNewCard", { card: data.card });
            this.emit("onNewCustomer", { customer: data.customer });
            this.emit("onNewVehicle", { vehicle: data.vehicle });
        }
    }

    public onComment(comment: IComment) {
        if (this.connected) {
            this.emit("send note for comment", { comment });
        }
    }

    public leaveBoardRoom() {
        if (this.connected) this.emit("leave board room");
    }

    public movedCard(data: Move) {
        if (this.connected) {
            this.emit("onCardMoved", data);
        }
    }

    public onCardUpdate(data: { card: Update<ICard> }) {
        if (this.connected) this.emit("onCardUpdated", data);
    }

    public updateBoardSuccess(data: { board: Update<IBoard> }) {
        if (this.connected) {
            this.emit("onUpdateBoard", data);
        }
    }

    public createColumnSuccess(column: IColumn) {
        if (this.connected) this.emit("onNewColumn", { column });
    }

    public deleteColumnSuccess(columnId: string) {
        if (this.connected) {
            this.emit("onColumnDelete", { id: columnId });
        }
    }

    public updateColumnSuccess(data: { column: Update<IColumn> }) {
        if (this.connected) this.emit("onColumnUpdated", data);
    }

    public updateColumnsSuccess(data: { columns: Update<IColumn>[] }) {
        if (this.connected) this.emit("onColumnsUpdated", data);
    }

    public onUploadFilesSuccess(files: ICompFile[]) {
        if (this.connected) this.emit("onUploadedFiles", files);
    }

    public onUserUpdatedSuccess(data: { user: Update<IUser> }) {
        if (this.connected) this.emit("onUserUpdated", data);
    }

    public onUserCreatedSuccess(user: IUser) {
        if (this.connected) this.emit("onUserCreated", { user });
    }

    public onChecklistUpdatedSuccess(data: { checklist: Update<IChecklist> }) {
        if (this.connected) this.emit("onChecklistUpdated", data);
    }

    public onChecklistCreatedSuccess(checklist: IChecklist) {
        if (this.connected) {
            this.emit("onChecklistCreated", { checklist });
        }
    }

    public onDepartmentUpdatedSuccess(data: {
        department: Update<IDepartment>;
    }) {
        if (this.connected) this.emit("onDepartmentUpdated", data);
    }

    public onTemplateDeleteSuccess(templateId: string) {
        if (this.connected) {
            this.emit("onTemplateDelete", { id: templateId });
        }
    }

    public onCustomerUpdatedSuccess(data: { customer: Update<ICustomer> }) {
        if (this.connected) this.emit("onCustomerUpdated", data);
    }

    public onSupplementCreatedSuccess(supplement: ISupplement) {
        if (this.connected) {
            this.emit("onSupplementCreated", { supplement });
        }
    }

    public onFileUploadedSuccess(data: ICompFile) {
        if (this.connected) this.emit("onFileUploaded", data);
    }

    public printRooms() {
        this.emit("print my rooms");
    }

    getAllUsersInRoom() {
        this.emit("get all users in room");
    }

    updateSocketConnectedStatus(isConnected: boolean) {
        this.store.dispatch(
            LayoutActions.changeSocketConnectionStatus({
                isConnected,
            })
        );
    }

    processToast(body: string, type: ToastType) {
        if (!environment.production) {
            this.store.dispatch(
                LayoutActions.showToastr({
                    payload: {
                        title: "Socket Service",
                        body,
                        type,
                    },
                })
            );
        }
    }
}
