import {
    AsyncValidator,
    AbstractControl,
    ValidationErrors,
} from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class CompanyValidator implements AsyncValidator {
    constructor(private http: HttpClient) {}

    validate(
        ctrl: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return timer(0).pipe(
            switchMap(() =>
                this.http
                    .get(`${environment.api}/company/existing/${ctrl.value}`)
                    .pipe(catchError(() => of(null)))
            )
        );
    }
}
