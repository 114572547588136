import { Update } from "@ngrx/entity";
import { ICard } from "../store/card/card.model";
import { IColumn } from "../store/column/column.model";

export const CardTypes = ["Basic Repair", "Forensic", "Quicky", "Chop"];

export const Payers = ["First Party", "Third Party", "Customer Pay"];
export const PreferredContactMethods = ["Call", "Text", "Email"];
export const QaNums = [1, 2, 3, 4];

export const Roles = [
    "Service Writer",
    "Admin",
    "Tech",
    "Manager",
    // "Office",
    // "Customer",
    // "Third Party",
    // "Guest"
];
export const Avatars: string[] = [
    "assets/img/avatars/avatar-1.png",
    "assets/img/avatars/avatar-2.png",
    "assets/img/avatars/avatar-3.png",
    "assets/img/avatars/avatar-4.png",
    "assets/img/avatars/avatar-5.png",
    "assets/img/avatars/avatar-6.png",
    "assets/img/avatars/avatar-7.png",
    "assets/img/avatars/avatar-8.png",
    "assets/img/avatars/avatar-9.png",
    "assets/img/avatars/avatar-10.png",
];

export const AddressTypesAry = ["Home", "Work", "Primary"];
export const PhoneTypesAry = ["Home", "Work", "Mobile"];

export const Colors = [
    {
        value: "gradient-purple",
        viewValue: "Purple",
    },
    {
        value: "gradient-indigo",
        viewValue: "Indigo",
    },
    {
        value: "gradient-teal",
        viewValue: "Teal",
    },
    {
        value: "gradient-blue",
        viewValue: "Blue",
    },
    {
        value: "gradient-orange",
        viewValue: "Orange",
    },
    {
        value: "gradient-green",
        viewValue: "Green",
    },
    {
        value: "gradient-pink",
        viewValue: "Pink",
    },
    {
        value: "gradient-red",
        viewValue: "Red",
    },
    {
        value: "gradient-amber",
        viewValue: "Amber",
    },
    {
        value: "gradient-gray",
        viewValue: "Gray",
    },
    {
        value: "gradient-brown",
        viewValue: "Brown",
    },
    {
        value: "gradient-lime",
        viewValue: "Lime",
    },
];

export const ERoles = {
    SW: "Service Writer",
    AD: "Admin",
    OF: "Office",
    TE: "Tech",
    CU: "Customer",
    TP: "Third Party",
    GU: "Guest",
};

export enum ImageCategories {
    INTAKE = "Intake",
    UPD = "Unrepairable Prior Damage",
    TEARDOWN = "Teardown",
    PAINT = "Paint",
    DURING_REPAIR = "During Repair",
    POST_REPAIR = "Post-Repair",
}

export enum NotificationPreferences {
    EMAIL = "Email",
    PUSH = "Push",
    NONE = "None",
}

export type FileCategory =
    | "INTAKE"
    | "PRIOR_DAMAGE"
    | "TEARDOWN"
    | "REPAIR_PROCEDURE"
    | "IN_PROGRESS"
    | "PAINT"
    | "POST_REPAIR"
    | "DETAIL"
    | "SUPPLEMENT_1"
    | "SUPPLEMENT_2"
    | "SUPPLEMENT_3"
    | "SUPPLEMENT_4"
    | "SUPPLEMENT_5"
    | "PART_ORDERS"
    | "INSURANCE_DOCS"
    | "CORRESPONDENCE"
    | "OTHER"
    | "WORK_ORDER";

export enum FileCategories {
    WORK_ORDER = "Work Orders",
    INTAKE = "Intake",
    PRIOR_DAMAGE = "Unrelated Prior Damage",
    TEARDOWN = "Teardown",
    REPAIR_PROCEDURE = "Repair Procedures",
    PAINT = "Paint",
    DETAIL = "Detail",
    SUPPLEMENT_1 = "Supplement 1",
    SUPPLEMENT_2 = "Supplement 2",
    SUPPLEMENT_3 = "Supplement 3",
    SUPPLEMENT_4 = "Supplement 4",
    SUPPLEMENT_5 = "Supplement 5",
    IN_PROGRESS = "In Progress",
    PART_ORDERS = "Part Orders",
    CORRESPONDENCE = "Correspondence",
    INSURANCE_DOCS = "Insurance Documents",
    POST_REPAIR = "Repair Completion",
    OTHER = "Other Documents",
}

export const FileCategoriesAry = [
    "Work Orders",
    "Intake",
    "Unrelated Prior Damage",
    "Teardown",
    "Repair Procedures",
    "Paint",
    "Detail",
    "Supplement 1",
    "Supplement 2",
    "Supplement 3",
    "Supplement 4",
    "Supplement 5",
    "In Progress",
    "Part Orders",
    "Correspondence",
    "Insurance Documents",
    "Repair Completion",
    "Other Documents",
];

export enum ColumnTypes {
    TASK = "Task",
    EMPLOYEE = "Employee",
    DEPARTMENT = "Department",
    OTHER = "Other",
}

export enum NotificationCategory {
    FC, // "FROM_COLUMN",
    TC, // "TO_COLUMN",
    ME, // "MENTIONED",
    AS, // "ASSIGNED",
    UN, // "UNASSIGNED",
    SS, // "SUBMIT_SUPPLEMENT",
    PS, // "PROCESSED_SUPPLEMENT"
}

// clearly Notification types and Action types are the same.
// TODO: reduce code here by just using notification categories
// instead of types. Notifications are based on the 3 preferred
// options that the user chooses. So the noti types are almost pointless
export enum NotificationTypes {
    ADD_ADMIN_TO_BOARD = "Add Admin To Board",
    ADDED_ATTACHMENT = "Added Attachment To Card",
    ASSIGNED = "Added Member To Card",
    ADDED_TO_BOARD = "Added To Board",
    ASSIGNED_TO_CARD = "Added To Card",
    CARD_DUE_SOON = "Card Due Soon",
    CARD_UPDATED = "Change Card",
    CLOSED_BOARD = "Close Board",
    COMMENTED = "Commented on a Card",
    CREATE_CARD = "Created Card",
    MADE_ADMIN = "MakeAdmin Of Board",
    MOVE_CARD = "Moved Card to Employee Column",
    MENTIONED = "Mentioned On Card",
    REMOVED_FROM_BOARD = "Removed From Board",
    REMOVED_FROM_CARD = "Removed From Card",
    CHANGED_CHECK_BOX_STATUS = "Update Check Item State On Card",
}

export enum ActionType {
    UN_ARCHIVE_CARD = "Un-archived Card",
    CUSTOMER_COMM = "Customer Communication",
    ASSIGN_LEAD_TECH = "Assigned a lead tech to card",
    ASSIGN_SERVICE_WRITER = "Assigned a service writer to card",
    ADD_ADMIN_TO_BOARD = "Add Admin To Board",
    ADD_ATTACHMENT = "Add Attachment To Card",
    ADD_MEMBER_TO_BOARD = "Add Member To Board",
    ADD_MEMBER_TO_CARD = "Add Member To Card",
    ADD_OR_REMOVE_MEMBERS = "Added or Removed Member(s) from card",
    ADJUST_INTAKE_DATE = "Adjusted the intake date",
    ADJUST_TARGET_DATE = "Adjusted the target date",
    ADJUST_QA_NUMBER = "Adjusted the qa number",
    ADJUST_INSURER = "Adjusted the  insurer",
    ADJUST_PAYER = "Adjusted the payer",
    ADJUST_CLAIM_NUMBER = "Adjusted the claim number",
    ADD_NOTES = "Added notes",
    ADD_DELAY = "Add Card Delay",
    ARCHIVED_CARD = "Archived Card",
    CREATE_COMMENT = "Comment Card",
    CREATE_BOARD = "Create Board",
    CREATE_CARD = "Create Card",
    CREATE_COLUMN = "Create Column",
    DELETE_ATTACHMENT = "Delete Attachment From Card",
    DELETE_CARD = "Delete Card",
    DELETE_COLUMN = "Delete Column",
    DELETE_BOARD = "Delete Board",
    EMAIL_CARD = "Email Card",
    MOVE_CARD = "Move Card",
    MOVE_COLUMN = "Move Column",
    REMOVE_MEMBER_FROM_BOARD = "Remove Member From Board",
    REMOVE_MEMBER_FROM_CARD = "Remove Member From Card",
    UPDATE_BOARD = "Update Board",
    UPDATED_WORK_ORDER = "Update Work Order",
    UPDATE_CARD = "Update Card",
    UPDATE_CARD_STATUS = "Updated Card Status",
    UPDATE_CUSTOMER_INFO = "Update Customer Info Card",
    UPDATE_CHECKLIST = "Update Checklist field(s) State On Card",
    UPDATE_COLUMN = "Update Column",
    UPDATE_MEMBER = "Update Member",
}

export const States: Array<{ name: string; abbreviation: string }> = [
    {
        name: "Alabama",
        abbreviation: "AL",
    },
    {
        name: "Alaska",
        abbreviation: "AK",
    },
    {
        name: "Arizona",
        abbreviation: "AZ",
    },
    {
        name: "Arkansas",
        abbreviation: "AR",
    },
    {
        name: "California",
        abbreviation: "CA",
    },
    {
        name: "Colorado",
        abbreviation: "CO",
    },
    {
        name: "Connecticut",
        abbreviation: "CT",
    },
    {
        name: "Delaware",
        abbreviation: "DE",
    },
    {
        name: "Florida",
        abbreviation: "FL",
    },
    {
        name: "Georgia",
        abbreviation: "GA",
    },
    {
        name: "Hawaii",
        abbreviation: "HI",
    },
    {
        name: "Idaho",
        abbreviation: "ID",
    },
    {
        name: "Illinois",
        abbreviation: "IL",
    },
    {
        name: "Indiana",
        abbreviation: "IN",
    },
    {
        name: "Iowa",
        abbreviation: "IA",
    },
    {
        name: "Kansas",
        abbreviation: "KS",
    },
    {
        name: "Kentucky",
        abbreviation: "KY",
    },
    {
        name: "Louisiana",
        abbreviation: "LA",
    },
    {
        name: "Maine",
        abbreviation: "ME",
    },
    {
        name: "Maryland",
        abbreviation: "MD",
    },
    {
        name: "Massachusetts",
        abbreviation: "MA",
    },
    {
        name: "Michigan",
        abbreviation: "MI",
    },
    {
        name: "Minnesota",
        abbreviation: "MN",
    },
    {
        name: "Mississippi",
        abbreviation: "MS",
    },
    {
        name: "Missouri",
        abbreviation: "MO",
    },
    {
        name: "Montana",
        abbreviation: "MT",
    },
    {
        name: "Nebraska",
        abbreviation: "NE",
    },
    {
        name: "Nevada",
        abbreviation: "NV",
    },
    {
        name: "New Hampshire",
        abbreviation: "NH",
    },
    {
        name: "New Jersey",
        abbreviation: "NJ",
    },
    {
        name: "New Mexico",
        abbreviation: "NM",
    },
    {
        name: "New York",
        abbreviation: "NY",
    },
    {
        name: "North Carolina",
        abbreviation: "NC",
    },
    {
        name: "North Dakota",
        abbreviation: "ND",
    },
    {
        name: "Ohio",
        abbreviation: "OH",
    },
    {
        name: "Oklahoma",
        abbreviation: "OK",
    },
    {
        name: "Oregon",
        abbreviation: "OR",
    },
    {
        name: "Pennsylvania",
        abbreviation: "PA",
    },
    {
        name: "Rhode Island",
        abbreviation: "RI",
    },
    {
        name: "South Carolina",
        abbreviation: "SC",
    },
    {
        name: "South Dakota",
        abbreviation: "SD",
    },
    {
        name: "Tennessee",
        abbreviation: "TN",
    },
    {
        name: "Texas",
        abbreviation: "TX",
    },
    {
        name: "Utah",
        abbreviation: "UT",
    },
    {
        name: "Vermont",
        abbreviation: "VT",
    },
    {
        name: "Virginia",
        abbreviation: "VA",
    },
    {
        name: "Washington",
        abbreviation: "WA",
    },
    {
        name: "West Virginia",
        abbreviation: "WV",
    },
    {
        name: "Wisconsin",
        abbreviation: "WI",
    },
    {
        name: "Wyoming",
        abbreviation: "WY",
    },
];

export const StatesArray = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];
export type CredentialErr = "email" | "password" | "error";
export type ValidationError<T extends string = "error"> = {
    [K in T]: string;
};

export type Move = {
    card: Update<ICard>;
    columns: Update<IColumn>[];
    oldState: {
        card: Update<ICard>;
        columns: Update<IColumn>[];
    };
};
