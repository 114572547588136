import { Pipe, PipeTransform } from "@angular/core";
import { IVehicle } from "../../store/vehicle/vehicle.model";

@Pipe({ name: "splitString" })
export class SplitStringPipe implements PipeTransform {
    transform(str: string): keyof IVehicle {
        return str
            .split("_")
            .join("")
            .replace(/([A-Z])/g, " $1")
            .trim() as keyof IVehicle;
    }
}
