import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as UserActions from "./user.actions";
import { IUser } from "./user.model";

export const usersFeatureKey = "users";

export interface State extends EntityState<IUser> {
    // additional entities state properties
    selectedUserId: string | null;
    loggedInUserId: string | null;
}

export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({
    selectId: (user: IUser) => user._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    selectedUserId: null,
    loggedInUserId: null,
    // additional entity state properties
});

const userReducer = createReducer(
    initialState,
    on(UserActions.addUser, SockActions.addUser, (state, action) =>
        adapter.addOne(action.user, state)
    ),
    on(UserActions.upsertUser, (state, action) =>
        adapter.upsertOne(action.user, state)
    ),
    on(UserActions.addUsers, (state, action) =>
        adapter.addMany(action.users, state)
    ),
    on(UserActions.upsertUsers, (state, action) =>
        adapter.upsertMany(action.users, state)
    ),
    on(UserActions.updateUser, SockActions.updateUser, (state, action) =>
        adapter.updateOne(action.user, state)
    ),
    on(UserActions.updateUsers, (state, action) =>
        adapter.updateMany(action.users, state)
    ),
    on(UserActions.deleteUser, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(UserActions.deleteUsers, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(UserActions.loadUsersSuccess, (state, action) =>
        adapter.upsertMany(action.users, state)
    ),
    on(AuthActions.logout, state =>
        adapter.removeAll({
            ...state,
            loggedInUserId: null,
            selectedUserId: null,
        })
    ),
    on(UserActions.clearUsers, state => adapter.removeAll(state)),
    on(UserActions.loginUser, (state, { id }) => ({
        ...state,
        loggedInUserId: id,
    })),
    on(UserActions.setLoggedInUser, (state, { id, user }) =>
        user
            ? adapter.upsertOne(user, {
                  ...state,
                  loggedInUserId: id,
              })
            : {
                  ...state,
                  loggedInUserId: id,
              }
    ),
    on(UserActions.selectUser, (state, { id }) => ({
        ...state,
        selectedUserId: id,
    })),
    on(UserActions.deSelectUser, state => ({
        ...state,
        selectedUserId: null,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return userReducer(state, action);
}

export const getSelectedUser = (state: State) =>
    state.selectedUserId ? state.entities[state.selectedUserId] : undefined;

export const getLoggedInUser = (state: State) =>
    state.loggedInUserId ? state.entities[state.loggedInUserId] : undefined;
