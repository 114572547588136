import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as DepartmentActions from "./department.actions";
import { IDepartment } from "./department.model";

export interface State extends EntityState<IDepartment> {
    selectedDepartmentId: string | null;
}

export const adapter: EntityAdapter<IDepartment> = createEntityAdapter<IDepartment>(
    {
        selectId: (dep: IDepartment) => dep._id,
        sortComparer: false,
    }
);

export const initialState: State = adapter.getInitialState({
    selectedDepartmentId: null,
});

const departmentReducer = createReducer(
    initialState,
    on(DepartmentActions.addDepartment, (state, action) =>
        adapter.addOne(action.department, state)
    ),
    on(DepartmentActions.upsertDepartment, (state, action) =>
        adapter.upsertOne(action.department, state)
    ),
    on(DepartmentActions.addDepartments, (state, action) =>
        adapter.addMany(action.departments, state)
    ),
    on(DepartmentActions.upsertDepartments, (state, action) =>
        adapter.upsertMany(action.departments, state)
    ),
    on(
        DepartmentActions.updateDepartment,
        SockActions.updateDepartment,
        (state, action) => adapter.updateOne(action.department, state)
    ),
    on(DepartmentActions.updateDepartments, (state, action) =>
        adapter.updateMany(action.departments, state)
    ),
    on(DepartmentActions.deleteDepartment, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(DepartmentActions.deleteDepartments, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(DepartmentActions.loadDepartmentsSuccess, (state, action) =>
        adapter.upsertMany(action.departments, state)
    ),
    on(DepartmentActions.clearDepartments, state => adapter.removeAll(state)),

    on(DepartmentActions.selectDepartment, (state, { id }) => ({
        ...state,
        selectedDepartmentId: id,
    })),
    on(DepartmentActions.deSelectDepartment, state => ({
        ...state,
        selectedDepartmentId: null,
    })),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return departmentReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getSelectedDepartment = (state: State) =>
    state.selectedDepartmentId
        ? state.entities[state.selectedDepartmentId]
        : undefined;
