import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as ActionActions from "./action.actions";
import * as AuthActions from "src/app/auth/store/auth.actions";
import { IAction } from "./action.model";

export const actionsFeatureKey = "actions";

export type State = EntityState<IAction>;

export const adapter: EntityAdapter<IAction> = createEntityAdapter<IAction>({
    selectId: (action: IAction) => action._id,
    sortComparer: (a: IAction, b: IAction) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const actionReducer = createReducer(
    initialState,
    on(ActionActions.addAction, (state, action) =>
        adapter.addOne(action.action, state)
    ),
    on(ActionActions.upsertAction, (state, action) =>
        adapter.upsertOne(action.action, state)
    ),
    on(ActionActions.addActions, (state, action) =>
        adapter.addMany(action.actions, state)
    ),
    on(ActionActions.upsertActions, (state, action) =>
        adapter.upsertMany(action.actions, state)
    ),
    on(ActionActions.updateAction, (state, action) =>
        adapter.updateOne(action.action, state)
    ),
    on(ActionActions.updateActions, (state, action) =>
        adapter.updateMany(action.actions, state)
    ),
    on(ActionActions.deleteAction, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(ActionActions.deleteActions, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(ActionActions.loadActionsSuccess, (state, action) =>
        adapter.upsertMany(action.actions, state)
    ),
    on(ActionActions.clearActions, AuthActions.logout, state =>
        adapter.removeAll(state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return actionReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
