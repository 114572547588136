import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as SupplementActions from "./supplement.actions";
import * as AuthActions from "src/app/auth/store/auth.actions";

import { ISupplement as Supplement } from "./supplement.model";

export const supplementsFeatureKey = "supplements";

export interface State extends EntityState<Supplement> {
    // additional entities state properties
    selectedSupplementId: string | null;
    error: string | null;
}

export const adapter: EntityAdapter<Supplement> = createEntityAdapter<Supplement>(
    {
        selectId: (supp: Supplement) => supp._id,
        sortComparer: (a: Supplement, b: Supplement) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    }
);

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    selectedSupplementId: null,
    error: null,
});

const supplementReducer = createReducer(
    initialState,
    on(SupplementActions.addSupplement, (state, action) =>
        adapter.addOne(action.supplement, state)
    ),
    on(SupplementActions.upsertSupplement, (state, action) =>
        adapter.upsertOne(action.supplement, state)
    ),
    on(SupplementActions.addSupplements, (state, action) =>
        adapter.addMany(action.supplements, state)
    ),
    on(SupplementActions.upsertSupplements, (state, action) =>
        adapter.upsertMany(action.supplements, state)
    ),
    on(SupplementActions.updateSupplementSuccess, (state, action) =>
        adapter.upsertOne(action.supplement, state)
    ),
    on(SupplementActions.updateSupplementFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),
    on(SupplementActions.updateSupplements, (state, action) =>
        adapter.updateMany(action.supplements, state)
    ),
    on(SupplementActions.deleteSupplement, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(SupplementActions.deleteSupplements, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(SupplementActions.clearSupplements, AuthActions.logout, state =>
        adapter.removeAll(state)
    ),
    on(SupplementActions.selectSupplements, (state, { id }) => ({
        ...state,
        selectedSupplementId: id,
    })),
    on(SupplementActions.deSelectSupplements, state => ({
        ...state,
        selectedSupplementId: null,
    })),
    on(
        SupplementActions.loadPendingSupplementsFailed,
        SupplementActions.loadAllSupplementsFailed,
        (state, action) => ({
            ...state,
            error: action.error,
        })
    ),
    on(
        SupplementActions.loadAllSupplementsSuccess,
        SupplementActions.loadPendingSupplementsSuccess,
        (state, action) => adapter.upsertMany(action.supplements, state)
    ),
    on(SupplementActions.clearError, state => ({
        ...state,
        error: null,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return supplementReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getSelectedSupplement = (state: State) =>
    state.selectedSupplementId
        ? state.entities[state.selectedSupplementId]
        : undefined;
