import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "remaining" })
export class RemainingPipe implements PipeTransform {
    transform(hours: {
        totalValue: number;
        completion: number;
        department: string;
    }): string {
        return `${hours.completion} / ${hours.totalValue} Total`;
    }
}
