import {
    map,
    tap,
    concatMap,
    switchMap,
    catchError,
    exhaustMap,
    withLatestFrom,
} from "rxjs/operators";
import { EMPTY, Observable, of } from "rxjs";
import { Update } from "@ngrx/entity";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Action, select, Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";

import { IBoard } from "./board.model";
import { ICard } from "../card/card.model";
import { IUser } from "../user/user.model";
import { IColumn } from "../column/column.model";
import { IDepartment } from "../department/department.model";
import { environment } from "src/environments/environment";

import { SocketService } from "src/app/core/services/socket.service";
import { WarningDialogComponent } from "../../../universal-dialogs/warning-dialog/warning-dialog.component";

import * as fromBoard from ".";
import * as fromUser from "../user";

import * as fromRoot from "src/app/reducers";

import * as BoardActions from "./board.actions";
import * as UserActions from "../user/user.actions";
import * as CardActions from "../card/card.actions";
import * as SockActions from "../company/socket.actions";
import * as ColumnActions from "../column/column.actions";
import * as LayoutActions from "../layout/layout.actions";
import * as CommentActions from "../comment/comment.actions";
import * as DepoActions from "../department/department.actions";
import * as NotificationActions from "../notification/notification.actions";

const api = environment.api;

@Injectable()
export class BoardEffects {
    GetListOfAllBoards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.loadBoards),
            switchMap(() =>
                this.http.get<{ boards: IBoard[] }>(`${api}/boards/dash`).pipe(
                    map(({ boards }) =>
                        BoardActions.loadBoardsSuccess({ boards })
                    ),
                    catchError(error =>
                        of(BoardActions.loadBoardsFailure({ error }))
                    )
                )
            )
        )
    );
    LoadAllBoardsForCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.loadAllBoardsForCompany),
            switchMap(() =>
                this.http.get<{ boards: IBoard[] }>(`${api}/boards/comp`).pipe(
                    map(({ boards }) =>
                        BoardActions.loadBoardsSuccess({ boards })
                    ),
                    catchError(error =>
                        of(BoardActions.loadBoardsFailure({ error }))
                    )
                )
            )
        )
    );

    LoadBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.selectBoard),
            switchMap(({ boardId }) =>
                this.http
                    .get<{
                        board: IBoard;
                        columns: IColumn[];
                        cards: ICard[];
                        users: IUser[];
                        departments: IDepartment[];
                    }>(`${api}/boards/${boardId}`)
                    .pipe(
                        concatMap(
                            ({ board, cards, columns, users, departments }) => [
                                ColumnActions.loadColumns({ columns }),
                                CardActions.loadCardsSuccess({ cards }),
                                UserActions.loadUsersSuccess({ users }),
                                DepoActions.loadDepartmentsSuccess({
                                    departments,
                                }),
                                BoardActions.loadBoardSuccess({ board }),
                            ]
                        ),
                        catchError(error =>
                            of(BoardActions.loadBoardFailure({ error }))
                        )
                    )
            )
        )
    );

    GetColumnsOfBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ColumnActions.getColumnsOfBoard),
            concatMap(({ boardId }) =>
                this.http
                    .get<{ columns: IColumn[] }>(
                        `${environment.api}/columns/b/${boardId}`
                    )
                    .pipe(map(data => ColumnActions.upsertColumns(data)))
            )
        )
    );

    DeselectBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.deselectBoard),
            tap(() => this.socket.leaveBoardRoom()),
            switchMap(() => [
                CardActions.clearCards(),
                ColumnActions.clearColumns(),
            ])
        )
    );

    CreateColumn$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ColumnActions.createColumn),
            withLatestFrom(
                this.store.pipe(select(fromBoard.selectSelectedBoard))
            ),
            concatMap(([{ column }, board]) =>
                this.http.post<IColumn>(`${api}/columns/new`, column).pipe(
                    tap(result => this.socket.createColumnSuccess(result)),
                    switchMap(result => {
                        if (board) {
                            return [
                                ColumnActions.addColumn({ column: result }),
                                BoardActions.updateBoardEntity({
                                    board: {
                                        changes: {
                                            columns: [
                                                ...board.columns,
                                                result._id,
                                            ],
                                        },
                                        id: board._id,
                                    },
                                }),
                            ];
                        } else {
                            return [];
                        }
                    }),
                    catchError(err =>
                        of(
                            LayoutActions.showToastr({
                                payload: {
                                    body: err.message,
                                    type: "error",
                                    title: "Failed to create column",
                                },
                            })
                        )
                    )
                )
            )
        )
    );

    UpdateBoardColumns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.updateColumnOrder),
            concatMap(({ board, oldState }) =>
                this.chooseUpdateBoardMethod(board).pipe(
                    map(() => {
                        this.socket.updateBoardSuccess({ board });
                        return BoardActions.updateColumnOrderSuccess({ board });
                    }),
                    catchError(err =>
                        of(
                            BoardActions.updateColumnOrderFailed({
                                oldState,
                                err,
                            })
                        )
                    )
                )
            )
        )
    );

    UpdateColumn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ColumnActions.updateColumn),
                concatMap(({ column }) =>
                    this.http
                        .put(`${api}/columns/${column.id}`, {
                            ...column.changes,
                        })
                        .pipe(
                            tap(() =>
                                this.socket.updateColumnSuccess({
                                    column,
                                })
                            ),
                            catchError(err => {
                                console.log("An ERROR OCCURRED", err);
                                return EMPTY;
                            })
                        )
                )
            ),
        { dispatch: false }
    );

    UpdateColumns$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ColumnActions.updateColumns),
                concatMap(({ columns }) =>
                    this.http
                        .put(
                            `${api}/columns/order`,
                            columns.map(c => ({
                                ...c.changes,
                                _id: c.id,
                            }))
                        )
                        .pipe(
                            tap(() =>
                                this.socket.updateColumnsSuccess({
                                    columns,
                                })
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    DeleteColumn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ColumnActions.deleteColumn),
                switchMap(({ id }) =>
                    this.http
                        .delete(`${api}/columns/${id}`)
                        .pipe(tap(() => this.socket.deleteColumnSuccess(id)))
                )
            ),
        { dispatch: false }
    );
    CreateBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.createBoard),
            withLatestFrom(this.store.select(fromUser.selectLoggedInUser)),
            switchMap(([{ payload }, user]) => {
                const { desc, title, type, coverImage } = payload;
                const boardData = new FormData();
                boardData.append("title", title);
                boardData.append("type", type);
                boardData.append("desc", desc || "");
                if (coverImage) boardData.append("file", coverImage);
                return this.http
                    .post<IBoard>(`${api}/boards/new`, boardData)
                    .pipe(
                        concatMap(board => [
                            BoardActions.addBoard({ board }),
                            UserActions.updateUser({
                                user: {
                                    id: user._id,
                                    changes: {
                                        idBoards: [...user.idBoards, board._id],
                                    },
                                },
                            }),
                        ]),
                        catchError(err =>
                            of(
                                BoardActions.createBoardFailed({
                                    error: err.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    UpdateBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.updateBoard),
            concatMap(({ board }) => {
                const boardData = new FormData();
                boardData.append("_id", `${board.id}`);
                if (board.changes.coverImage) {
                    boardData.append("file", board.changes.coverImage);
                }
                if (board.changes.title) {
                    boardData.append("title", board.changes.title);
                }
                if (board.changes.desc) {
                    boardData.append("desc", board.changes.desc);
                }
                return this.http
                    .put<IBoard>(`${api}/boards/${board.id}/update`, boardData)
                    .pipe(
                        map(updatedBoard =>
                            BoardActions.updateBoardSuccess({
                                board: {
                                    id: updatedBoard._id,
                                    changes: updatedBoard,
                                },
                            })
                        ),
                        catchError(err =>
                            of(BoardActions.updateBoardFailed({ error: err }))
                        )
                    );
            })
        )
    );

    DeleteBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.deleteBoard),
            concatMap(({ id }) =>
                this.http.delete(`${api}/boards/${id}`).pipe(
                    map(() => BoardActions.deleteBoardSuccess({ id })),
                    catchError(err =>
                        of(BoardActions.deleteBoardFailed({ error: err }))
                    )
                )
            )
        )
    );

    MoveCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.moveCard),
            concatMap(({ type, ...data }) => {
                return this.http
                    .put<{ columns: IColumn[]; card: ICard }>(
                        `${api}/boards/movecard`,
                        data
                    )
                    .pipe(
                        map(() => {
                            this.socket.movedCard(data);
                            return BoardActions.moveCardSuccess(data);
                        }),
                        catchError(err =>
                            of(
                                BoardActions.moveCardFailed({
                                    oldState: data.oldState,
                                    error: err,
                                })
                            )
                        )
                    );
            })
        )
    );

    ArchiveConfirmation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.confirmedArchive),
            concatMap(({ column }) => {
                return this.http
                    .put<{ cards: ICard[]; column: IColumn }>(
                        `${api}/boards/archiveBatch`,
                        column
                    )
                    .pipe(
                        concatMap(data => [
                            ColumnActions.updateColumn({
                                column: {
                                    changes: data.column,
                                    id: data.column._id,
                                },
                            }),
                            CardActions.updateCards({
                                cards: data.cards.map(c => ({
                                    changes: c,
                                    id: c._id,
                                })),
                            }),
                            LayoutActions.showToastr({
                                payload: {
                                    title: "Archive Success",
                                    body:
                                        "archived all cards in " +
                                        column.name +
                                        ".",
                                    type: "success",
                                },
                            }),
                        ]),
                        catchError(err =>
                            of(
                                LayoutActions.showToastr({
                                    payload: {
                                        type: "error",
                                        body:
                                            "Something went wrong when archiving cards in " +
                                            column.name +
                                            ".\n" +
                                            err.message,
                                        title: "Error archiving cards",
                                    },
                                })
                            )
                        )
                    );
            })
        )
    );

    ArchiveAllCardsInColumn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardActions.archiveAllCardsInColumn),
            exhaustMap(({ column }) => {
                const buttonText = "No";
                const optionalButtonText = "Yes";
                const message =
                    "Are you sure you want to archive all cards in " +
                    column.name +
                    "?";
                const dialogRef = this.dialog.open(WarningDialogComponent, {
                    data: {
                        message,
                        buttonText,
                        optionalButtonText,
                    },
                });
                return dialogRef
                    .afterClosed()
                    .pipe(
                        map((confirmed: boolean) => (confirmed ? column : null))
                    );
            }),
            map(column =>
                column
                    ? BoardActions.confirmedArchive({ column })
                    : BoardActions.dismissArchiveAllCards()
            )
        )
    );

    @Effect() onColDelete$ = this.socket.onColDelete$.pipe(
        concatMap(data => [
            SockActions.deleteColumn(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() CardCreated$ = this.socket.onCardCreation$.pipe(
        concatMap(data => [
            SockActions.addCard(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() Toastr$ = this.socket.toastr$.pipe(
        concatMap(data => [
            NotificationActions.loadUnreadNotifications({}),
            LayoutActions.showToastr({
                payload: {
                    body: data.body,
                    title: data.title,
                    type: data.type,
                    meta: data.meta,
                },
            }),
        ])
    );

    @Effect() CardUpdated$ = this.socket.onCardUpdated$.pipe(
        concatMap(data => [
            SockActions.updateCard(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ColumnCreation$ = this.socket.onColumnCreation$.pipe(
        concatMap(data => [
            SockActions.addColumn(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ColumnUpdate$ = this.socket.onColumnUpdate$.pipe(
        concatMap(data => [
            SockActions.updateColumn(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ColumnsUpdate$ = this.socket.onColumnsUpdated$.pipe(
        concatMap(data => [
            SockActions.updateColumns(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() UserUPDATED$ = this.socket.onUserUPDATED$.pipe(
        concatMap(data => [
            SockActions.updateUser(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() UserCREATED$ = this.socket.onUserCREATED$.pipe(
        concatMap(data => [
            SockActions.addUser(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ChecklistUPDATED$ = this.socket.onChecklistUPDATED$.pipe(
        concatMap(data => [
            SockActions.updateChecklist(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ChecklistCREATED$ = this.socket.onChecklistCREATED$.pipe(
        concatMap(data => [
            SockActions.addChecklist(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() ChecklistDELETED$ = this.socket.onChecklistDELETED$.pipe(
        concatMap(data => [
            SockActions.deleteChecklist(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() CustomerCREATED$ = this.socket.onCustomerCREATED$.pipe(
        concatMap(data => [
            SockActions.addCustomer(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() CustomerUPDATED$ = this.socket.onCustomerUPDATED$.pipe(
        concatMap(data => [
            SockActions.updateCustomer(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect()
    DepartmentUPDATED$ = this.socket.onDepartmentUPDATED$.pipe(
        concatMap(data => [
            SockActions.updateDepartment(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() TemplateUPDATED$ = this.socket.onTemplateUPDATED$.pipe(
        concatMap(data => [
            SockActions.updateCardTemplate(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() TemplateCREATED$ = this.socket.onTemplateCREATED$.pipe(
        concatMap(data => [
            SockActions.addCardTemplate(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() TemplateDELETED$ = this.socket.onTemplateDELETED$.pipe(
        concatMap(data => [
            SockActions.deleteCardTemplate(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() CardMoved$ = this.socket.onCardMove$.pipe(
        concatMap(data => [
            SockActions.onCardMove(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() BoardUpdated$ = this.socket.onBoardUpdated$.pipe(
        concatMap(data => [
            SockActions.updateBoardSuccess(data),
            NotificationActions.loadUnreadNotifications({}),
        ])
    );

    @Effect() Commented$ = this.socket.onComment$.pipe(
        withLatestFrom(this.store.select(s => s.app.cards.selectedCardId)),
        concatMap(([{ comment }, cardId]) => {
            if (cardId === comment.idCard) {
                return [
                    CommentActions.addComment({ comment }),
                    NotificationActions.loadUnreadNotifications({}),
                ];
            }
            return [NotificationActions.loadUnreadNotifications({})];
        })
    );

    @Effect() CommentedTo$ = this.socket.onMentionedInComment$.pipe(
        tap(data => {
            this.store.dispatch(
                LayoutActions.showToastr({
                    payload: {
                        body: data.comment.content,
                        title: `${data.comment.commenter.firstName} ${data.comment.commenter.lastName} mentioned you in a comment`,
                        type: "warning",
                        meta: {
                            cardId: data.comment.idCard,
                            cardRo: "",
                        },
                        override: {
                            positionClass: "toast-top-right",
                            timeOut: 10000,
                            toastClass: "ngx-toastr mentioned-notification",
                            enableHtml: true,
                        },
                    },
                })
            );
        }),
        map(() => NotificationActions.loadUnreadNotifications({}))
    );

    constructor(
        private http: HttpClient,
        private actions$: Actions,
        private dialog: MatDialog,
        private store: Store<fromRoot.State>,
        private socket: SocketService
    ) {}

    chooseUpdateBoardMethod(board: Update<IBoard>): Observable<any> {
        if ("columns" in board.changes) {
            return this.http.patch(`${api}/boards/${board.id}`, {
                ...board.changes,
            });
        }
        return this.http.put(`${api}/boards/${board.id}`, {
            ...board.changes,
        });
    }
}
