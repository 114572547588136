import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { Insurer } from "./insurer.model";

export const createNewInsurer = createAction(
    "[CREATE_INSURER_ACTION]",
    props<{ ins: Partial<Insurer> }>()
);

export const loadInsurers = createAction("[Insurer/API] Load Insurers");
export const loadInsurersSuccess = createAction(
    "[Insurer/API] Load Insurers Success",
    props<{ insurers: Insurer[] }>()
);

export const createInsurer = createAction(
    "[Insurer/API] Create Insurer",
    props<{ insurer: Partial<Insurer> }>()
);
export const addInsurer = createAction(
    "[Insurer/API] Add Insurer",
    props<{ insurer: Insurer }>()
);

export const upsertInsurer = createAction(
    "[Insurer/API] Upsert Insurer",
    props<{ insurer: Insurer }>()
);

export const addInsurers = createAction(
    "[Insurer/API] Add Insurers",
    props<{ insurers: Insurer[] }>()
);

export const upsertInsurers = createAction(
    "[Insurer/API] Upsert Insurers",
    props<{ insurers: Insurer[] }>()
);

export const updateInsurer = createAction(
    "[Insurer/API] Update Insurer",
    props<{ insurer: Update<Insurer> }>()
);

export const updateInsurers = createAction(
    "[Insurer/API] Update Insurers",
    props<{ insurers: Update<Insurer>[] }>()
);

export const confirmDeleteInsurer = createAction(
    "[Insurer] Confirm Deletion",
    props<{ id: string }>()
);

export const confirmDeleteInsurerDismiss = createAction(
    "[Insurer] Confirm Deletion Dismiss"
);
export const deleteInsurer = createAction(
    "[Insurer/API] Delete Insurer",
    props<{ id: string }>()
);

export const deleteInsurerSuccess = createAction(
    "[Insurer] Delete Insurer Success",
    props<{ id: string }>()
);
export const deleteInsurerFailure = createAction(
    "[Insurer] Delete Insurer Failure",
    props<{ error: any }>()
);

export const deleteInsurers = createAction(
    "[Insurer/API] Delete Insurers",
    props<{ ids: string[] }>()
);

export const clearInsurers = createAction("[Insurer/API] Clear Insurers");
export const selectInsurer = createAction(
    "[Insurer] Select Insurer Id",
    props<{ id: string }>()
);
export const deSelectInsurer = createAction("[Insurer] DeSelect Insurer Id");
