// tslint:disable:directive-selector
import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, switchMap } from "rxjs/operators";
import { UserService } from "../../services/user-services/user.service";

@Injectable({ providedIn: "root" })
export class UserValidator implements AsyncValidator {
    constructor(private userService: UserService) {}

    validate(
        ctrl: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        // adding a timer with switchMap makes it only call 1 time vs 3.
        return timer(0).pipe(
            switchMap(() =>
                this.userService
                    .checkExistingUsersEmail(ctrl.value, ctrl.root.value._id)
                    .pipe(catchError(() => of(null)))
            )
        );
    }

    validate2(
        ctrl: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return timer(0).pipe(
            switchMap(() =>
                this.userService
                    .checkExistingUsersEmail(ctrl.value.email)
                    .pipe(catchError(() => of(null)))
            )
        );
    }
}
