import { Pipe, PipeTransform } from "@angular/core";
import { Insurer } from "../../store/insurer/insurer.model";

@Pipe({ name: "InsurerSearchPipe" })
export class InsurerSearchPipe implements PipeTransform {
    transform(insurers: Insurer[], args?: string): Array<any> {
        const searchText = new RegExp(args || "", "ig");
        if (insurers) {
            return insurers.filter(
                ins =>
                    ins.name.search(searchText) !== -1 ||
                    ins.insCoName.search(searchText) !== -1
            );
        } else {
            return [];
        }
    }
}
