import { createAction, props } from "@ngrx/store";
import type { CredentialErr, ValidationError } from "src/app/core/models/types";
import { Credentials, IUser } from "src/app/core/store/user/user.model";
import { Registration } from "./register.model";

export const logout = createAction("[Auth] Logout");
export const clearError = createAction("[Auth] Clear Error");

export const loginStarted = createAction(
    "[Auth/Api] Login Started",
    props<Credentials>()
);

export const loginSuccess = createAction(
    "[Auth/API] Login Successful",
    props<{ user: IUser }>()
);

export const autoAuthFailed = createAction("[Auth/API] Auto Auth failed");

export const loginFailed = createAction(
    "[Auth/API] Login Failed",
    props<{ error: ValidationError<CredentialErr> }>()
);

export const checkForUpdate = createAction("[Auth] Check for update");
export const loadEverything = createAction("[Auth/API] Load everything");

export const loadDashboard = createAction(
    "[Auth/API] Load Dashboard",
    props<{ redirectUrl: string }>()
);

export const registerCompany = createAction(
    "[Auth/API] Register new Company",
    props<{ payload: Registration }>()
);
export const registerCompanyFailed = createAction(
    "[Auth/API] Register Company Failure",
    props<{ error: any }>()
);
export const registerCompanySuccess = createAction(
    "[Auth/API] Register Company Success"
);
