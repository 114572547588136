import { IndividualConfig } from "ngx-toastr";

export type ToastType = "info" | "warning" | "success" | "error";
export type ToastMeta = {
    cardRo: string | number;
    cardId: string;
};
export enum ToastrTypes {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    SUCCESS = "success",
}
export interface IToast {
    body: string;
    title: string;
    type: ToastType;
    meta?: ToastMeta;
    override?: Partial<IndividualConfig>;
}
export class Toast implements IToast {
    constructor(
        public body: string,
        public title: string,
        public type: ToastType,
        public meta?: ToastMeta,
        public override?: Partial<IndividualConfig>
    ) {
        this.meta = meta;
        this.body = body;
        this.type = type;
        this.title = title;
        this.override = override || {};
    }
}

export const ToastrMsgs = {
    ADD_ATTACHMENT: (count?: number) =>
        count ? `Uploaded ${count} files` : "Upload successful",
    CREATE_COMMENT: () => "Comment successful",
    UPDATE_HOURS: () => "Updated the hours",
    ADJUST_PAYER: () => "Updated the payer",
    ADJUST_INSURER: () => "Updated the  insurer",
    DELETE_ATTACHMENT: () => "Removed an attachment",
    ADJUST_QA_NUMBER: () => "Updated the qa number",
    UPDATED_WORK_ORDER: () => "Updated the work order",
    UPDATED_DEFAULT_IMAGE: () => "Updated the default image",
    UPDATE_CUSTOMER_INFO: () => "Updated customer info",
    ADJUST_INTAKE_DATE: () => "Updated the intake date",
    ADJUST_TARGET_DATE: () => "Updated the target date",
    ADJUST_CLAIM_NUMBER: () => "Updated the claim number",
    UPDATE_CHECKLIST: () => "Successfully updated the checklist field(s)",
    CREATE_CARD: (cardRo: string) => `Created ${cardRo}`,
    ARCHIVED_CARD: (cardRo: string) => `Archived ${cardRo}`,
    ADD_NOTES: (cardRo: string) => `Modified the notes on ${cardRo}`,
    INITIAL_HOURS: (cardRo: string) => `Added initial hours on ${cardRo}`,
    MARKED_AS_DELIVERED: (cardRo: string) => `Marked ${cardRo} as delivered`,
    ADD_MEMBER_TO_CARD: (memberName: string, cardRo: string) =>
        `Added ${memberName} as a member to ${cardRo}`,
    REMOVE_MEMBER_FROM_CARD: (memberName: string, cardRo: string) =>
        `Removed ${memberName} as a member from ${cardRo}`,
    UPDATE_CARD_STATUS: (newStatus: string) =>
        `Updated the status to ${newStatus}`,
    ASSIGN_LEAD_TECH: (leadTechName: string) =>
        `Assigned ${leadTechName} as the lead tech`,
    ASSIGN_SERVICE_WRITER: (serviceWriterName: string) =>
        `Assigned ${serviceWriterName} as the service writer`,
    MOVE_CARD: (
        cardRo: string,
        originalColumnName: string,
        newColumnName: string
    ) => `Moved ${cardRo} from ${originalColumnName} to ${newColumnName}`,
};
