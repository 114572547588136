import { ICard } from "src/app/core/store/card/card.model";
import { Pipe, PipeTransform } from "@angular/core";
import { IDepartment } from "src/app/core/store/department/department.model";

@Pipe({ name: "load" })
export class CalculateLoadPipe implements PipeTransform {
    transform(department: IDepartment, colCards: ICard[]): number {
        if (!colCards.length) return 0;
        let total = 0;

        colCards.forEach(card => {
            if (card.calculate && card.hours) {
                const dep =
                    card.hours[
                        department.name.toLowerCase() as keyof ICard["hours"]
                    ];
                if (dep) {
                    total += dep.totalValue - dep.completion;
                }
            }
        });
        return +total.toFixed(2);
    }
}
