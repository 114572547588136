import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "cardStatusColor" })
export class CardStatusColorPipe implements PipeTransform {
    transform(status: string): string {
        switch (status) {
            case "On Hold":
                return "redBg";
            case "Green Light":
                return "greenBg";
            case "Scheduled In":
                return "blueBg";
            case "Waiting on Parts":
                return "orangeBg";
            case "Other":
                return "purpleBg";
            default:
                return "";
        }
    }
}
