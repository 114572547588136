import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    Update,
} from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as BoardActions from "../board/board.actions";
import * as SockActions from "../company/socket.actions";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as CardActions from "./card.actions";
import { CardStatus, ICard } from "./card.model";

export interface State extends EntityState<ICard> {
    status: CardStatus;
    error: string | null;
    selectedCardId: string | null;
}

export const adapter: EntityAdapter<ICard> = createEntityAdapter<ICard>({
    selectId: (card: ICard | Update<ICard>) =>
        "_id" in card ? card._id : (card.id as string),
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    error: null,
    status: "idle",
    selectedCardId: null,
});

const cardReducer = createReducer<State>(
    initialState,
    on(CardActions.addCard, SockActions.addCard, (state, action) =>
        adapter.addOne(action.card, state)
    ),
    on(CardActions.upsertCard, (state, action) =>
        adapter.upsertOne(action.card, state)
    ),
    on(CardActions.addCards, (state, action) =>
        adapter.addMany(action.cards, state)
    ),
    on(CardActions.upsertCards, (state, action) =>
        adapter.upsertMany(action.cards, state)
    ),
    on(
        CardActions.updateCardEntity,
        SockActions.updateCard,
        CardActions.updateCardWithoutAPI,
        (state, action) => adapter.updateOne(action.card, state)
    ),
    on(CardActions.updateCards, (state, action) =>
        adapter.updateMany(action.cards, state)
    ),
    on(CardActions.deleteCardEntity, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(CardActions.deleteCards, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(CardActions.loadCardsSuccess, (state, action) =>
        adapter.upsertMany(action.cards, state)
    ),
    on(CardActions.clearCards, AuthActions.logout, state =>
        adapter.removeAll({
            ...state,
            loaded: false,
            isLoading: false,
        })
    ),
    on(CardActions.archiveCardSuccess, (state, { cardId }) =>
        adapter.removeOne(cardId, state)
    ),
    on(CardActions.getFullCard, (state, action) => ({
        ...state,
        selectedCardId: action.cardId,
        status: "loading",
    })),
    on(CardActions.loadComplete, state => ({
        ...state,
        status: "loaded",
    })),
    on(CardActions.updateCardStatus, (state, { status }) => ({
        ...state,
        status,
    })),
    on(BoardActions.moveCard, SockActions.onCardMove, (state, { card }) =>
        adapter.updateOne(card, state)
    ),
    on(BoardActions.moveCardFailed, (state, { oldState: { card } }) =>
        adapter.updateOne(card, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return cardReducer(state, action);
}

export const getSelectedCard = (state: State) =>
    state.selectedCardId ? state.entities[state.selectedCardId] : undefined;

export const getStatus = (state: State) => state.status;
export const getError = (state: State) => state.error;
