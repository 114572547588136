import { Pipe, PipeTransform } from "@angular/core";
import { IChecklist } from "../../store/cardTemplate/card-template.model";

@Pipe({ name: "totalProgress" })
export class TotalChecklistProgressPipe implements PipeTransform {
    transform(checklists: IChecklist[]): number {
        const values = checklists.reduce((totalValue, currentValue) => {
            const fields = currentValue.fields.reduce(
                (prev, field) => {
                    if (field.na)
                        return { ...prev, totalFields: prev.totalFields - 1 };
                    if (field.completed) {
                        return {
                            ...prev,
                            totalComplete: prev.totalComplete + 1,
                        };
                    }
                    return prev;
                },
                { totalFields: currentValue.fields.length, totalComplete: 0 }
            );
            if (fields.totalFields === 0) {
                return totalValue + 100;
            }
            const finalValue =
                (fields.totalComplete / fields.totalFields) * 100;
            return totalValue + finalValue;
        }, 0);
        const totalValue = values / checklists.length / 100;
        return totalValue;
    }
}
