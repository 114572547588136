import { combineReducers, Action, createFeatureSelector } from "@ngrx/store";
import * as fromRoot from "src/app/reducers";
import * as fromTodo from "src/app/core/store/todo/todo.reducer";
import * as fromCard from "src/app/core/store/card/card.reducer";
import * as fromTour from "src/app/core/store/tour/tour.reducer";
import * as fromBoard from "src/app/core/store/board/board.reducer";
import * as fromColumn from "src/app/core/store/column/column.reducer";
import * as fromAction from "src/app/core/store/action/action.reducer";
import * as fromFile from "src/app/core/store/files/comp-file.reducer";
import * as fromInsurer from "src/app/core/store/insurer/insurer.reducer";
import * as fromComment from "src/app/core/store/comment/comment.reducer";
import * as fromVehicle from "src/app/core/store/vehicle/vehicle.reducer";
import * as fromDep from "src/app/core/store/department/department.reducer";
import * as fromCustomer from "src/app/core/store/customer/customer.reducer";
import * as fromSupp from "src/app/core/store/supplement/supplement.reducer";
import * as fromChecklist from "src/app/core/store/checklist/checklist.reducer";
import * as fromNote from "src/app/core/store/notification/notification.reducer";
import * as fromTemplate from "src/app/core/store/cardTemplate/card-template.reducer";

export interface State {
    tour: fromTour.State;
    cards: fromCard.State;
    files: fromFile.State;
    todos: fromTodo.State;
    boards: fromBoard.State;
    columns: fromColumn.State;
    actions: fromAction.State;
    departments: fromDep.State;
    comments: fromComment.State;
    vehicles: fromVehicle.State;
    insurers: fromInsurer.State;
    supplements: fromSupp.State;
    customers: fromCustomer.State;
    notifications: fromNote.State;
    templates: fromTemplate.State;
    checklists: fromChecklist.State;
}

export function reducers(state: State | undefined, action: Action) {
    return combineReducers({
        tour: fromTour.reducer,
        todos: fromTodo.reducer,
        cards: fromCard.reducer,
        files: fromFile.reducer,
        boards: fromBoard.reducer,
        columns: fromColumn.reducer,
        actions: fromAction.reducer,
        departments: fromDep.reducer,
        supplements: fromSupp.reducer,
        insurers: fromInsurer.reducer,
        vehicles: fromVehicle.reducer,
        comments: fromComment.reducer,
        notifications: fromNote.reducer,
        customers: fromCustomer.reducer,
        templates: fromTemplate.reducer,
        checklists: fromChecklist.reducer,
    })(state, action);
}

export const selectMainBoardState = createFeatureSelector<
    fromRoot.State,
    State
>("app");
