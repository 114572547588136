import { Menu } from "./menu.model";

export const verticalMenuItems = [
    new Menu(1, "Dashboard", "/admin", null, "dashboard", null, false, 0),
    new Menu(
        2,
        "Users",
        "/admin/users",
        null,
        "supervisor_account",
        null,
        false,
        0
    ),
    new Menu(
        3,
        "Insurers",
        "/admin/groups/insurers",
        null,
        "group",
        "insurers",
        false,
        0
    ),
    new Menu(
        4,
        "Departments",
        "/admin/departments",
        null,
        "store",
        "departments",
        false,
        0
    ),

    new Menu(
        5,
        "Checklists",
        "/admin/lists/checklists",
        null,
        "list",
        null,
        false,
        0
    ),
    new Menu(
        6,
        "Templates",
        "/admin/lists/templates",
        null,
        "list_alt",
        null,
        false,
        0
    ),
    // new Menu(11, "EMS", "/admin/ems", null, "list", null, false, 0)
    // new Menu(5, 'Customers', '/admin/groups/customers', null, 'group', 'customers', false, 3),
    // new Menu(17, 'Chat', '/admin/chat', null, 'chat', null, false, 0),
];
