import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as SockActions from "../company/socket.actions";
import * as VehicleActions from "./vehicle.actions";
import { IVehicle } from "./vehicle.model";
export type State = EntityState<IVehicle>;

export const adapter: EntityAdapter<IVehicle> = createEntityAdapter<IVehicle>({
    selectId: (veh: IVehicle) => veh._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

const vehicleReducer = createReducer(
    initialState,
    on(VehicleActions.addVehicle, SockActions.addVehicle, (state, action) =>
        adapter.addOne(action.vehicle, state)
    ),
    on(VehicleActions.upsertVehicle, (state, action) =>
        adapter.upsertOne(action.vehicle, state)
    ),
    on(VehicleActions.addVehicles, (state, action) =>
        adapter.addMany(action.vehicles, state)
    ),
    on(VehicleActions.upsertVehicles, (state, action) =>
        adapter.upsertMany(action.vehicles, state)
    ),
    on(
        VehicleActions.updateVehicle,
        SockActions.updateVehicle,
        (state, action) => adapter.updateOne(action.vehicle, state)
    ),
    on(VehicleActions.updateVehicles, (state, action) =>
        adapter.updateMany(action.vehicles, state)
    ),
    on(VehicleActions.deleteVehicle, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(VehicleActions.deleteVehicles, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(VehicleActions.loadVehiclesSuccess, (state, action) =>
        adapter.upsertMany(action.vehicles, state)
    ),
    on(VehicleActions.clearVehicles, state => adapter.removeAll(state)),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return vehicleReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
