import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap, concatMap, switchMap } from "rxjs/operators";
import * as ChecklistActions from "./checklist.actions";
import { IChecklist } from "./checklist.model";
import { SocketService } from "src/app/core/services/socket.service";

@Injectable()
export class ChecklistEffects {
    LoadChecklists$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChecklistActions.loadChecklists),
            switchMap(() =>
                this.http
                    .get<IChecklist[]>(`${environment.api}/cklst/all`)
                    .pipe(
                        map(checklists =>
                            ChecklistActions.loadChecklistsSuccess({
                                checklists,
                            })
                        )
                    )
            )
        )
    );

    CreateChecklist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChecklistActions.createChecklist),
            concatMap(data =>
                this.http
                    .post<IChecklist>(
                        `${environment.api}/temp/cklst/new`,
                        data.payload
                    )
                    .pipe(
                        tap(cklst =>
                            this.socket.onChecklistCreatedSuccess(cklst)
                        ),
                        map(newChecklist =>
                            ChecklistActions.addChecklist({
                                checklist: newChecklist,
                            })
                        )
                    )
            )
        )
    );

    UpdateChecklist$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChecklistActions.updateChecklist),
                concatMap(({ checklist }) =>
                    this.http
                        .put<IChecklist>(
                            `${environment.api}/cklst/${checklist.id}`,
                            {
                                ...checklist.changes,
                                _id: checklist.id,
                            }
                        )
                        .pipe(
                            tap(() =>
                                this.socket.onChecklistUpdatedSuccess({
                                    checklist,
                                })
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    DeleteChecklist$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChecklistActions.deleteChecklist),
                concatMap(({ id }) =>
                    this.http.delete(`${environment.api}/cklst/${id}`)
                )
            ),
        { dispatch: false }
    );

    // MoveFields$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(ChecklistActions.moveFields),
    //     switchMap(({ payload }) => {
    //       const { checklist, currentIndex, previousIndex } = payload;
    //       const newFields = [...checklist.fields];
    //       moveItemInArray(newFields, previousIndex, currentIndex);

    //       return this.http
    //         .put(`${environment.api}/cklst/${checklist._id}`, {
    //           ...checklist,
    //           fields: newFields
    //         })
    //         .pipe(
    //           tap(() =>
    //             this.socket.onChecklistUpdatedSuccess({
    //               ...checklist,
    //               fields: newFields
    //             })
    //           ),
    //           map(() =>
    //             ChecklistActions.moveFieldsSuccess({
    //               payload: { ...checklist, fields: newFields }
    //             })
    //           ),
    //           catchError(err =>
    //             of(ChecklistActions.moveFieldsFailed({ error: err.toString() }))
    //           )
    //         );
    //     })
    //   )
    // );

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private socket: SocketService
    ) {}
}
