<h1 mat-dialog-title class="text-center red-text">
    <mat-icon>warning</mat-icon>
</h1>
<mat-dialog-content class="mb-4">
    <h3 class="text-center">{{ data.message }}</h3>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!invertButtons" align="center">
    <button
        mat-raised-button
        class="mx-2"
        *ngIf="data.optionalButtonText"
        color="warn"
        [mat-dialog-close]="true"
    >
        {{ data.optionalButtonText }}
    </button>
    <button mat-raised-button class="mx-2" color="primary" mat-dialog-close>
        {{ data.buttonText }}
    </button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="invertButtons" align="center">
    <button
        mat-raised-button
        class="mx-2"
        *ngIf="data.optionalButtonText"
        color="warn"
        mat-dialog-close
    >
        {{ data.optionalButtonText }}
    </button>
    <button
        mat-raised-button
        class="mx-2"
        color="primary"
        [mat-dialog-close]="true"
    >
        {{ data.buttonText }}
    </button>
</mat-dialog-actions>
