import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IVehicle as Vehicle } from "./vehicle.model";

export const loadVehicles = createAction("[Vehicle/API] Load Vehicles");
export const loadVehiclesSuccess = createAction(
    "[Vehicle/API] Load Vehicles Success",
    props<{ vehicles: Vehicle[] }>()
);

export const addVehicle = createAction(
    "[Vehicle/API] Add Vehicle",
    props<{ vehicle: Vehicle }>()
);

export const upsertVehicle = createAction(
    "[Vehicle/API] Upsert Vehicle",
    props<{ vehicle: Vehicle }>()
);

export const addVehicles = createAction(
    "[Vehicle/API] Add Vehicles",
    props<{ vehicles: Vehicle[] }>()
);

export const upsertVehicles = createAction(
    "[Vehicle/API] Upsert Vehicles",
    props<{ vehicles: Vehicle[] }>()
);

export const updateVehicle = createAction(
    "[Vehicle/API] Update Vehicle",
    props<{ vehicle: Update<Vehicle> }>()
);

export const updateVehicles = createAction(
    "[Vehicle/API] Update Vehicles",
    props<{ vehicles: Update<Vehicle>[] }>()
);

export const deleteVehicle = createAction(
    "[Vehicle/API] Delete Vehicle",
    props<{ id: string }>()
);

export const deleteVehicles = createAction(
    "[Vehicle/API] Delete Vehicles",
    props<{ ids: string[] }>()
);

export const clearVehicles = createAction("[Vehicle/API] Clear Vehicles");
