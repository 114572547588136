<div
    class="content-header bg-p"
    [class.bg-image]="hasBgImage"
    [ngClass]="class"
>
    <app-breadcrumb [hidden]="hideBreadcrumb"></app-breadcrumb>
    <img
        *ngIf="!!Iicon"
        [src]="Iicon"
        class="menu-icon-xlg Iicon"
        alt="menu icon"
    />
    <mat-icon *ngIf="icon && !Iicon" class="mat-icon-xlg">{{ icon }}</mat-icon>
    <h1 *ngIf="!!title">{{ title }}</h1>
    <h4 *ngIf="!!desc" class="p-2">{{ desc }}</h4>
    <div fxLayout="row" class="menuIcons">
        <ng-container *ngFor="let mIcon of menuIcons; index as i">
            <button
                mat-icon-button
                class="menu-icon"
                (click)="menuIconClicked.emit(i)"
            >
                <mat-icon> {{ mIcon }} </mat-icon>
            </button>
            <form
                class="user-search"
                *ngIf="mIcon === 'search'"
                [class.show]="showSearch"
            >
                <mat-form-field class="user-search-input">
                    <input
                        matInput
                        placeholder="Search insurer by name..."
                        formControl="searchInput"
                        name="search"
                    />
                </mat-form-field>
            </form>
        </ng-container>
    </div>
</div>
