import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "src/app/auth/store/auth.actions";
import * as ColumnActions from "../column/column.actions";
import * as SockActions from "../company/socket.actions";
import * as BoardActions from "./board.actions";
import { IBoard } from "./board.model";

export interface State extends EntityState<IBoard> {
    isLoading: boolean;
    loaded: boolean;
    error: string | null;
    selectedBoardId: string | null;
}

export const adapter: EntityAdapter<IBoard> = createEntityAdapter<IBoard>({
    selectId: (board: IBoard) => board._id,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    isLoading: false,
    loaded: false,
    error: null,
    boardLoaded: false,
    selectedBoardId: null,
});

const boardReducer = createReducer<State>(
    initialState,
    on(BoardActions.addBoard, (state, action) =>
        adapter.addOne(action.board, state)
    ),
    on(BoardActions.upsertBoard, (state, action) =>
        adapter.upsertOne(action.board, state)
    ),
    on(BoardActions.addBoards, (state, action) =>
        adapter.addMany(action.boards, state)
    ),
    on(BoardActions.upsertBoards, (state, action) =>
        adapter.upsertMany(action.boards, state)
    ),
    on(
        SockActions.updateBoardSuccess,
        BoardActions.updateBoardEntity,
        (state, action) => adapter.updateOne(action.board, state)
    ),
    on(AuthActions.logout, state =>
        adapter.removeAll({ ...state, ...initialState })
    ),
    on(
        ColumnActions.deleteColumn,
        SockActions.deleteColumn,
        (state, { id }) => {
            const board = getSelectedBoard(state);
            if (board) {
                const { columns } = board;
                return adapter.updateOne(
                    {
                        id: board._id,
                        changes: {
                            columns: columns.filter(colId => colId !== id),
                        },
                    },
                    state
                );
            }
            return state;
        }
    ),
    on(BoardActions.updateBoardSuccess, (state, { board }) =>
        adapter.updateOne(board, state)
    ),
    on(BoardActions.updateBoards, (state, action) =>
        adapter.updateMany(action.boards, state)
    ),
    on(BoardActions.deleteBoardSuccess, (state, action) =>
        adapter.removeOne(action.id, state)
    ),
    on(BoardActions.deleteBoards, (state, action) =>
        adapter.removeMany(action.ids, state)
    ),
    on(BoardActions.loadBoards, state => ({
        ...state,
        isLoading: true,
    })),
    on(BoardActions.loadBoardsSuccess, (state, action) =>
        adapter.upsertMany(action.boards, {
            ...state,
            isLoading: false,
            loaded: true,
        })
    ),
    on(BoardActions.selectBoard, (state, action) => ({
        ...state,
        isLoading: true,
        loaded: false,
        selectedBoardId: action.boardId,
    })),
    on(BoardActions.loadBoardSuccess, (state, action) =>
        adapter.upsertOne(action.board, {
            ...state,
            isLoading: false,
            loaded: true,
        })
    ),
    on(BoardActions.deselectBoard, state => ({
        ...state,
        selectedBoardId: null,
        loaded: !!state.entities,
    })),
    on(BoardActions.loadBoardFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        loaded: false,
        error,
    })),
    on(BoardActions.clearBoards, state => adapter.removeAll(state)),
    on(BoardActions.updateColumnOrderFailed, (state, { oldState }) =>
        adapter.updateOne(oldState, state)
    ),
    on(BoardActions.updateColumnOrder, (state, { board }) =>
        adapter.updateOne(board, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return boardReducer(state, action);
}

export const getSelectedBoard = (state: State) =>
    state.selectedBoardId ? state.entities[state.selectedBoardId] : undefined;

export const getIsLoading = (state: State) => state.isLoading;

export const getError = (state: State) => state.error;
export const getHasLoaded = (state: State) => state.loaded;
export const getSelectedBoardIsLoading = (state: State) =>
    state.selectedBoardId && state.isLoading && !state.loaded;
export const getSelectedBoardHasLoaded = (state: State) =>
    state.selectedBoardId && state.loaded && !state.isLoading;
export const getSelectedBoardFromRouter = (
    state: State,
    boardId: string
): IBoard | undefined => (boardId ? state.entities[boardId] : undefined);

export const getSelectedBoardDetails = (state: State) => ({
    isLoading: state.isLoading,
    selectedBoardId: state.selectedBoardId,
    loaded: state.loaded,
});
