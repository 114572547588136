import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IComment } from "./comment.model";

export const loadComments = createAction("[Comment/API] Load Comments");
export const loadCommentsSuccess = createAction(
    "[Comment/API] Load Comments Success",
    props<{ comments: IComment[] }>()
);

export const createComment = createAction(
    "[Comment/API] Create a new Comment",
    props<{ comment: Partial<IComment> }>()
);

export const addComment = createAction(
    "[Comment/API] Add Comment",
    props<{ comment: IComment }>()
);

export const upsertComment = createAction(
    "[Comment/API] Upsert Comment",
    props<{ comment: IComment }>()
);

export const addComments = createAction(
    "[Comment/API] Add Comments",
    props<{ comments: IComment[] }>()
);

export const upsertComments = createAction(
    "[Comment/API] Upsert Comments",
    props<{ comments: IComment[] }>()
);

export const updateComment = createAction(
    "[Comment/API] Update Comment",
    props<{ comment: Update<IComment> }>()
);

export const updateComments = createAction(
    "[Comment/API] Update Comments",
    props<{ comments: Update<IComment>[] }>()
);

export const deleteComment = createAction(
    "[Comment/API] Delete Comment",
    props<{ id: string }>()
);

export const deleteCommentSuccess = createAction(
    "[Comment/API] Delete Comment Success",
    props<{ id: string }>()
);

export const deleteComments = createAction(
    "[Comment/API] Delete Comments",
    props<{ ids: string[] }>()
);

export const clearComments = createAction("[Comment/API] Clear Comments");
