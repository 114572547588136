import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: "root" })
export class TokenService {
    jwt: JwtHelperService;

    constructor() {
        this.jwt = new JwtHelperService();
    }

    signOut() {
        localStorage.removeItem("AuthToken");
    }

    public saveToken(token: string) {
        if (!token) return;
        localStorage.setItem("AuthToken", token);
    }

    public get AccessToken(): string | null {
        return localStorage.getItem("AuthToken");
    }

    public get RefreshToken(): string | null {
        return localStorage.getItem("RefreshToken");
    }
}
