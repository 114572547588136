import { Params, RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
    url: string;
    breadcrumb: string;
    params: Params;
    queryParams: Params;
}

@Injectable()
export class RsRouterSerializer
    implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;
        const {
            params,
            data: { breadcrumb },
        } = route;

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return {
            url,
            params,
            queryParams,
            breadcrumb,
        };
    }
}
