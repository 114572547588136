import { Pipe, PipeTransform } from "@angular/core";
import { ICard } from "../../store/card/card.model";

@Pipe({ name: "completion" })
export class CompletionPipe implements PipeTransform {
    transform(hours: ICard["hours"]): number {
        if (hours) {
            let b = 0;
            let a = 0;
            Object.values(hours).forEach(h => {
                b += h.totalValue;
                a += h.totalValue - h.completion;
            });
            const result = (a / b) * 100;
            return 100 - Math.round(result);
        }
        return 0;
    }
}
