<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">
    <div fxFlex="80" fxFlex.gt-sm="40" fxFlex.sm="60" class="mat-elevation-z6">
        <app-content-header
            [icon]="'error'"
            [title]="'404'"
            [hideBreadcrumb]="true"
            [hasBgImage]="true"
            [class]="'pt-4 pb-3'"
        ></app-content-header>
        <div class="p-4 text-center">
            <p class="pt-2 mb-2">
                Oops, it seems that this page does not exist.
            </p>

            <button
                mat-raised-button
                color="primary"
                class="mat-elevation-z6"
                type="button"
                (click)="goHome()"
            >
                GO HOME
            </button>
        </div>
    </div>
</div>
