import { createSelector } from "@ngrx/store";
import * as fromCard from "./card.reducer";
import { selectCardsState } from "src/app/reducers";

const { selectEntities, selectAll } = fromCard.adapter.getSelectors(
    selectCardsState
);

export const selectCards = selectAll;

export const selectCardEntities = selectEntities;
export const selectError = createSelector(selectCardsState, fromCard.getError);
export const selectSelectedCard = createSelector(
    selectCardsState,
    fromCard.getSelectedCard
);
export const selectStatus = createSelector(
    selectCardsState,
    fromCard.getStatus
);
export const selectTotalChecklistCompletion = createSelector(
    selectCardsState,
    selectSelectedCard,
    (_state, card) => {
        const checklists: { [key: string]: number } = {};
        if (card) {
            card.checklists.forEach(checklist => {
                let completedFields = 0;
                let naFields = 0;
                checklist.fields.forEach(field => {
                    if (field.completed) completedFields++;
                    if (field.na) naFields++;
                });
                if (naFields === checklist.fields.length) {
                    checklists[checklist._id] = 100;
                } else {
                    checklists[checklist._id] =
                        (completedFields / checklist.fields.length) * 100;
                }
            });
        }
        return checklists;
    }
);
